import React from 'react';
import PropTypes from 'prop-types';
import {Divider} from 'semantic-ui-react'
import {VelocityTransitionGroup} from 'velocity-react';
;

export default class Separator extends React.Component {

    static propTypes = {
        color: PropTypes.string,
        animation: PropTypes.string
    };

    static defaultProps = {
        color: "#000",
        animation: "fadeIn, 500"
    };

    render() {
        
        let animation = this.props.animation.split(",");
        return (
            <VelocityTransitionGroup enter={{animation:animation[0],duration:animation[1],delay:animation[2]}}
                                     leave={{animation:'fadeOut',duration:'0'}}
                                     runOnMount >
                <div style={{padding: '5px 0'}}>
                    <Divider style={{backgroundColor: this.props.color}}/>
                </div>
            </VelocityTransitionGroup>

        );
    }
}
