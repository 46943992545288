import React from "react";
import _ from "lodash";
import Text from "../contentBlocks/Text.react";
import PropTypes from "prop-types";
import { resolveImage } from "config/app.helpers";
import { VelocityTransitionGroup } from "velocity-react";
import { ComponentInputs } from "schema/FormSchema.react";
class ConversationGroup extends React.Component {
    static propTypes = {
        data: PropTypes.array.isRequired,
        colors: PropTypes.shape({
            primary: PropTypes.string,
            secondary: PropTypes.string,
            primaryText: PropTypes.string,
            secondaryText: PropTypes.string,
        }).isRequired,
        animation: PropTypes.string,
        imageLibrary: PropTypes.array,
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let data = this.props.data;
        let animation = this.props.animation.split(",");

        let conversationBox = {
            width: "100%",
            height: "100%",
            position: "relative",
            padding: isPortrait ? "0" : "30px",
        };
        let leftTriangle = {
            position: "absolute",
            top: "0.5em",
            right: "0",
            width: "0",
            height: "0",
            borderTop: "0.8em solid transparent",
            borderBottom: "0em solid transparent",
            borderRightWidth: "1.2em",
            borderRightStyle: "solid",
            borderRightColor: this.props.colors.secondary,
        };

        let rightTriangle = {
            position: "absolute",
            bottom: "0.5em",
            left: "0",
            width: "0",
            height: "0",
            borderTop: "0.8em solid transparent",
            borderBottom: "0em solid transparent",
            borderLeftWidth: "1.2em",
            borderLeftStyle: "solid",
            borderLeftColor: this.props.colors.primary,
        };

        let getListItems = _.map(data, (item, index) => {
            let conversationSnippet = {
                padding: isPortrait ? "5px 0" : "15px 0",
                height: isPortrait ? "85px" : "96px",
                marginBottom: "5px",
                fontSize: "0.9em",
            };
            let conversationImageBlock = {
                width: isPortrait ? "85px" : "96px",
                float: index % 2 ? "right" : "left",
                height: isPortrait ? "85px" : "96px",
                position: "relative",
                overflow: "hidden",
            };
            let conversationImage = {
                position: "absolute",
                width: "100%",
                height: "100%",
                left: "0",
                top: "0",
                verticalAlign: "middle",
            };
            let conversationText = {
                width: isPortrait ? "calc(100% - 85px)" : "calc(100% - 96px)",
                lineHeight: "1",
                fontSize:
                    item.text.length > ComponentInputs.conversation.idealLength
                        ? isPortrait
                            ? "0.65em"
                            : "0.75em"
                        : "1.25em",
                height: isPortrait ? "85px" : "96px",
                textAlign: "left",
                padding: "5px",
                float: index % 2 ? "left" : "right",
                background:
                    index % 2
                        ? this.props.colors.primary
                        : this.props.colors.secondary,
            };
            return (
                <div style={conversationSnippet} key={"conversation-" + index}>
                    <div style={conversationImageBlock}>
                        <img
                            src={resolveImage(
                                item.image.id,
                                this.props.imageLibrary
                            )}
                            style={conversationImage}
                            alt="conversation-snippet"
                        />
                        <div style={index % 2 ? rightTriangle : leftTriangle} />
                    </div>
                    <div style={conversationText}>
                        <Text
                            keyName={"speech-bubble-" + index}
                            text={item.text}
                            color={
                                index % 2
                                    ? this.props.colors.primaryText
                                    : this.props.colors.secondaryText
                            }
                            fontSize={"normal"}
                            animation="fadeIn,100"
                        />
                    </div>
                </div>
            );
        });

        return (
            <div style={conversationBox}>
                <VelocityTransitionGroup
                    enter={{
                        animation: animation[0],
                        duration: animation[1],
                        stagger: animation[2],
                    }}
                    leave={{ animation: "fadeOut", duration: "0" }}
                    runOnMount
                >
                    {getListItems}
                </VelocityTransitionGroup>
            </div>
        );
    }
}

export default ConversationGroup;
