// Import from NPM
// -------------------------------------
import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

// Import Reducer Routes
// -------------------------------------
import { AppConfigReducer } from "appRedux/AppConfig.reducer.react";
import { AuthenticationReducer } from "appRedux/Authentication.reducer.react";
import { LibraryReducer } from "appRedux/Library.reducer.react";
import { CoursesReducer } from "appRedux/Courses.reducer.react";
import { TopicsReducer } from "appRedux/Topics.reducer.react";
import { ProgramsReducer } from "appRedux/Programs.reducer.react";
import { DecksReducer } from "appRedux/Decks.reducer.react";
import { DeckAttemptReducer } from "appRedux/DeckAttempt.reducer.react";
import { ArticleAttemptReducer } from "appRedux/ArticleAttempt.reducer.react";

import { BlogReducer } from "appRedux/Blog.reducer.react";
import { FeedReducer } from "appRedux/Feed.reducer.react";
import { CommentsReducer } from "appRedux/Comments.reducer.react";
import { OfflineReducer } from "appRedux/Offline.reducer.react";
import { NotificationReducer } from "appRedux/Notification.reducer";
import { HelpDeskReducer } from "appRedux/HelpDesk.reducer.react";
import { SessionReducer } from "appRedux/Session.reducer.react";
import { TrackerReducer } from "../../tracker/redux/Tracker.reducer.react";

let RootReducer = combineReducers({
    appConfig: AppConfigReducer,
    auth: AuthenticationReducer,
    routing: routerReducer,
    library: LibraryReducer,
    programs: ProgramsReducer,
    courses: CoursesReducer,
    topics: TopicsReducer,
    decks: DecksReducer,
    deckAttempt: DeckAttemptReducer,
    articleAttempt: ArticleAttemptReducer,

    blog: BlogReducer,
    feed: FeedReducer,
    comments: CommentsReducer,
    offline: OfflineReducer,
    notification: NotificationReducer,
    helpDesk: HelpDeskReducer,
    session: SessionReducer,
    trackers: TrackerReducer
});

export { RootReducer };
