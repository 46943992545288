// Import from NPM
// -------------------------------------
import React from "react";
import PropTypes from "prop-types";
import { Image } from "semantic-ui-react";
import Text from "./Text.react";
import { resolveImage } from "config/app.helpers";

export default class SlideHeaderSidebar extends React.Component {
    static propTypes = {
        title: PropTypes.string,
        subtitle: PropTypes.string,
        height: PropTypes.string,
        fontSize: PropTypes.string,
    };

    static defaultProps = {
        title: "",
        subtitle: "",
        height: undefined,
        fontSize: "large",
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let hasImage =
            this.props.data.image !== undefined &&
            !this.props.data.image.id.includes("default");
        let hasPresenter =
            this.props.data.presenter !== undefined &&
            this.props.data.presenter !== "default43";
        let hasTitle = this.props.title.length > 0 && this.props.title !== " ";
        let hasSubtitle =
            this.props.subtitle.length > 0 && this.props.subtext !== " ";

        let headerContainerStyle = {
            width: isPortrait ? "50%" : "100%",
            height: isPortrait ? "100%" : "",
            float: "left",
        };

        let headerStyle = {
            height: isPortrait ? "100%" : "auto",
            width: "100%",
            padding: isPortrait || hasImage ? "30px 0" : "21% 0",
            backgroundColor: this.props.colors.primary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
        };

        let headerContentStyle = {
            width: "80%",
            textAlign: "left",
            color: this.props.colors.primaryText,
        };

        return (
            <div style={headerContainerStyle}>
                {hasImage && !isPortrait && (
                    <Image
                        src={resolveImage(
                            this.props.data.image.id,
                            this.props.imageLibrary
                        )}
                        alt="Title Image"
                        style={{ width: "100%" }}
                    />
                )}
                <div style={headerStyle}>
                    <div style={headerContentStyle}>
                        <Text
                            keyName={"header-" + this.props.slideId}
                            text={hasTitle ? this.props.title : ""}
                            color={this.props.colors.primaryText}
                            animation="transition.expandIn,250,250"
                            fontSize={"ultra"}
                            isHeader={true}
                        />
                        <br />
                        <br />
                        <Text
                            keyName={"subheader-" + this.props.slideId}
                            text={hasSubtitle ? this.props.subtitle : ""}
                            color={this.props.colors.primaryText}
                            animation="transition.expandIn,250,250"
                            fontSize="large"
                        />
                    </div>
                    {hasPresenter && (
                        <img
                            src={resolveImage(
                                this.props.data.presenter.id,
                                this.props.imageLibrary
                            )}
                            alt="Presenter"
                            style={{
                                position: "absolute",
                                bottom: "20px",
                                right: "0",
                                width: "65%",
                                zIndex: "998",
                                transform: isPortrait
                                    ? "translate(0, 0)"
                                    : "translate(150%, 0)",
                            }}
                        />
                    )}
                </div>
            </div>
        );
    }
}
