import React from "react";
import PropTypes from "prop-types";
import { Image, List } from "semantic-ui-react";
import _ from "lodash";
import { VelocityTransitionGroup } from "velocity-react";

import { getAppConfig } from "config/client.config";
import { resolveImage } from "config/app.helpers";
import Text from "./Text.react";

export default class IconBullets extends React.Component {
    static propTypes = {
        list: PropTypes.array.isRequired,
        color: PropTypes.string,
        animation: PropTypes.string,
        imageLibrary: PropTypes.array
    };

    static defaultProps = {
        color: "#000",
        animation: "fadeIn, 500"
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let listContainerStyle = {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            listStyleType: "none",
            margin: isPortrait ? "" : "10px 0 !important"
        };
        let listContentStyle = {
            width: "100%",
            listStyleType: "none",
            textAlign: "left"
        };
        let listTextStyle = {
            ...getAppConfig().textStyle
        };

        let animation = this.props.animation.split(",");

        let getListItems = _.map(this.props.list, (item, index) => {
            return (
                <List.Item
                    key={"list-item-" + index}
                    style={listContainerStyle}
                >
                    <Image
                        size="tiny"
                        src={resolveImage(item.image.id, this.props.imageLibrary)}
                    />
                    <List.Content style={listContentStyle}>
                        <List.Header style={listTextStyle}>
                            <Text
                                keyName="bullet-point"
                                text={item.text}
                                color={this.props.color}
                                animation="fadeIn,1000"
                                fontSize={"large"}
                            />
                        </List.Header>
                    </List.Content>
                </List.Item>
            );
        });

        return (
            <div>
                <VelocityTransitionGroup
                    enter={{
                        animation: animation[0],
                        duration: animation[1],
                        delay: animation[2]
                    }}
                    leave={{ animation: "fadeOut", duration: "0" }}
                    runOnMount
                >
                    <List>{getListItems}</List>
                </VelocityTransitionGroup>
            </div>
        );
    }
}
