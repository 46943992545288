import { REHYDRATE } from "redux-persist/constants";
import {
    LOGIN,
    LOGOUT,
    ACCEPT_TERMS,
    FETCH_DECK,
    COMPLETE_ATTEMPT,
    INCOMPLETE_ATTEMPT,
    UPDATE_ATTEMPT,
    PUSH_SCORM_STATE,
    COURSE_RATED,
    CREATE_SESSION,
    RESUME_SESSION,
    START_ARTICLE,
    COMPLETE_ARTICLE_VIEW,
    UPDATE_ARTICLE_VIEW,
    BADGE_EARNED,
    MEDAL_EARNED,
    REWARD_EARNED,
    PERFORMANCE_ACCESS
} from "appRedux/helpers/reduxConstants";
import { CLEAR_TRACKER, PUSH_STATE } from "./Tracker.action.react";

const initialTrackerState = {
    queue: [],
    lastPushed: null,
    lastUpdated: null,
};

const createTracker = (name, state, payload, time) => {
    if (payload != null)
        return {
            queue: [
                ...state.queue,
                {
                    name: name,
                    payload: payload,
                    time: time === undefined ? Date.now() : time,
                },
            ],
        };
    else return state;
};

const TrackerReducer = (state = initialTrackerState, action) => {
    switch (action.type) {
        /* istanbul ignore next - react-persist function */
        case REHYDRATE:
            let trackers = action.payload.trackers;
            if (trackers) {
                return { ...state, ...trackers };
            } else {
                return state;
            }
        case LOGIN:
            if (action.status === "success") {
                return createTracker("LOGIN", state, {});
            } else {
                return state;
            }
        case ACCEPT_TERMS:
            return createTracker("TERMS_ACCEPTED", state, {});
        case LOGOUT:
            let lastEvent = state.queue[state.queue.length - 1];
            if (!lastEvent || lastEvent.name !== "LOGOUT")
                return createTracker("LOGOUT", state, {});
            else return state;
        case CREATE_SESSION:
            return createTracker("SESSION_STARTED", state, action.data);
        case RESUME_SESSION:
            return createTracker("SESSION_RESUMED", state, action.data);
        case FETCH_DECK:
            if (action.status === "success") {
                return createTracker(
                    "DECK_STARTED",
                    state,
                    {
                        deck: action.data.deck.id,
                        sessionId: action.data.sessionId,
                    },
                    action.data.attemptTime
                );
            } else {
                return state;
            }
        case START_ARTICLE:
            if (action.status === "success") {
                return createTracker(
                    "ARTICLE_STARTED",
                    state,
                    {
                        article: action.data.deck.id,
                        sessionId: action.data.sessionId,
                    },
                    action.data.attemptTime
                );
            } else {
                return state;
            }
        case UPDATE_ATTEMPT:
            if (
                action.data !== undefined &&
                action.data !== null &&
                action.data.trackerPayload !== null
            )
                return {
                    queue: [
                        ...state.queue,
                        {
                            name: "QUESTION_RESPONSE",
                            payload: action.data.trackerPayload,
                        },
                    ],
                };
            else return state;

        case UPDATE_ARTICLE_VIEW:
            if (
                action.data !== undefined &&
                action.data !== null &&
                action.data.trackerPayload !== null
            )
                return {
                    queue: [
                        ...state.queue,
                        {
                            name: "QUESTION_RESPONSE",
                            payload: action.data.trackerPayload,
                        },
                    ],
                };
            else return state;
        case COMPLETE_ATTEMPT:
            return createTracker(
                "DECK_COMPLETED",
                state,
                {
                    deck: action.data.attempt.id,
                },
                action.data.eventDate
            );
        case COMPLETE_ARTICLE_VIEW:
            return createTracker(
                "ARTICLE_COMPLETED",
                state,
                action.data.attempt,
                action.data.eventDate
            );
        case INCOMPLETE_ATTEMPT:
            return createTracker(
                "DECK_ABANDONED",
                state,
                {
                    deck: action.data.attempt.id,
                },
                action.data.eventDate
            );
        case PUSH_STATE:
            if (action.data !== undefined && action.data !== null) {
                return {
                    queue: [
                        ...state.queue,
                        { name: "PUSH_STATE", payload: action.data },
                    ],
                };
            } else return state;
        case COURSE_RATED:
            if (action.data !== undefined && action.data !== null) {
                return {
                    queue: [
                        ...state.queue,
                        { name: "COURSE_RATED", payload: action.data },
                    ],
                };
            } else return state;
        case PUSH_SCORM_STATE:
            if (action.data !== undefined && action.data !== null) {
                return {
                    queue: [
                        ...state.queue,
                        { name: "PUSH_STATE", payload: action.data },
                    ],
                };
            } else return state;
        case CLEAR_TRACKER:
            if (action.status === "error") {
                return state;
            } else {
                return {
                    queue: [],
                    lastPushed: action.data.lastPushed,
                };
            }
        case BADGE_EARNED:
            return createTracker(
                "BADGE_EARNED",
                state,
                {
                    deck: action.data.id,
                    userId: action.data.userId,
                    actionId: action.data.badgeId
                },
                action.data.eventDate
            );
        case MEDAL_EARNED:
            return createTracker(
                "MEDAL_EARNED",
                state,
                {
                    deck: action.data.id,
                    userId: action.data.userId,
                    actionId: action.data.medalId
                },
                action.data.eventDate
            );
        case REWARD_EARNED:
            return createTracker(
                "REWARD_EARNED",
                state,
                {
                    deck: action.data.id,
                    userId: action.data.userId,
                    actionId: action.data.rewardId
                },
                action.data.eventDate
            );
        case PERFORMANCE_ACCESS:
            return createTracker("PERFORMANCE_ACCESS", state, {});
        default:
            return state;
    }
};

export { TrackerReducer };
