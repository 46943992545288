import { REHYDRATE } from 'redux-persist/constants';
import { FETCH_TICKET_USER } from './HelpDesk.action.react';
const initialHelpDeskState = {
    ticket: [],
    fetchCompleted: false,
    error: null,
};

const HelpDeskReducer = (state = initialHelpDeskState, action) => {
    switch (action.type) {
        case REHYDRATE:
            let helpdesk = action.payload.helpdesk;
            if (helpdesk) {
                return { ...state, ...helpdesk }
            } else {
                return state;
            }
        case FETCH_TICKET_USER:
            if (action.status === "fetching") {
                return { ...state, fetchCompleted: false, error: null };
            } else if (action.status === "success") {
                return { ticket: action.data.ticket };
            } else {
                return { ...state, error: action.err, fetchCompleted: true };
            }
        default:
            return state;
    }
};

export { HelpDeskReducer };
