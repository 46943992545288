import { REHYDRATE } from "redux-persist/constants";
import {
    FETCH_DECK,
    UPDATE_ATTEMPT,
    RESET_DECK_ATTEMPT,
    FETCH_IMAGE
} from "appRedux/helpers/reduxConstants";
import {
    initAttempt,
    updateAttempt,
} from "appRedux/helpers/aggregatorFunctions";

const initialDeckAttemptState = {};

const DeckAttemptReducer = (state = initialDeckAttemptState, action) => {
    switch (action.type) {
        /* istanbul ignore next - react-persist function */
        case REHYDRATE:
            let deckAttempt = action.payload.deckAttempt;
            if (deckAttempt) {
                return { ...state, ...deckAttempt };
            } else {
                return state;
            }
        case FETCH_DECK:
            if (action.status === "fetching") {
                return { ...state, fetchCompleted: false, error: null };
            } else if (action.status === "success") {
                return initAttempt(action.data);
            } else {
                return { ...state, error: action.err };
            }
        case UPDATE_ATTEMPT:
            return Object.assign({}, state, updateAttempt(state, action.data));
        case RESET_DECK_ATTEMPT:
            return Object.assign({}, state, { aggregated: true });
        case FETCH_IMAGE:
            if (action.status === "success") {
                let newimages = state.images;
                let imgIndex = newimages.findIndex((img => img.id === action.data.id));
                if (imgIndex >= 0) {
                    newimages[imgIndex].image = action.data.image;
                    return Object.assign({}, state, {
                        images: newimages
                    });
                } else {
                    return Object.assign({}, state, {
                        images: [...state.images, action.data]
                    });
                }
            } else {
                return { ...state, error: action.err, fetchCompleted: true };
            }
        default:
            return state;
    }
};

export { DeckAttemptReducer };
