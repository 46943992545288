import React from "react";
import { Icon } from "semantic-ui-react";
import Text from "../../contentBlocks/Text.react";
import Bullets from "../../contentBlocks/Bullets.react";
import SlideHeader from "../../contentBlocks/SlideHeader.react";
import SlideHeaderSidebar from "../../contentBlocks/SlideHeaderSidebar.react";
import PropTypes from "prop-types";
import { Grid } from "semantic-ui-react";

export default class DoDontList extends React.Component {
    static propTypes = {
        data: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            bullets: PropTypes.array,
            rebullets: PropTypes.array,
            question: PropTypes.string,
        }).isRequired,
        colors: PropTypes.shape({
            baseText: PropTypes.string,
            primary: PropTypes.string,
        }).isRequired,
        slideSeq: PropTypes.number,
    };
    constructor(props) {
        super(props);
        this.state = {
            active: "none",
            slideSeq: this.props.slideSeq,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.slideSeq !== prevState.slideSeq) {
            return {
                active: "none",
                slideSeq: nextProps.slideSeq,
            };
        }
        return null;
    }

    setActiveDo = () => {
        this.setState({ active: "do" });
    };
    setActiveDont = () => {
        console.log("check");
        this.setState({ active: "dont" });
    };
    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let defaultStyle = {
            width: "100%",
            textAlign: "center",
            overflowY: "hidden",
            marginTop: isPortrait ? "-20px" : "0",
        };
        let panelStyle = {
            width: "50%",
            display: "inline-block",
            padding: "1em",
            pointer: "cursor",
        };
        let doBtnStyle = {
            ...panelStyle,
            background: this.state.active === "do" ? "#c0c78b" : "#ccc",
            borderRight: "1px solid #000",
        };
        let dontBtnStyle = {
            ...panelStyle,
            background: this.state.active === "dont" ? "#ef9a9a" : "#ccc",
        };
        let helptextStyle = {
            marginTop: "100px",
        };
        let doListStyle = {
            width: "80%",
            margin: "0 auto",
            height: "100%",
            overflowY: "auto",
        };
        let dontListStyle = {
            width: "80%",
            margin: "0 auto",
            height: "100%",
            overflowY: "auto",
        };
        let dolist = () => {
            return (
                <div style={doListStyle}>
                    <Bullets
                        id="dos-list"
                        keyName={"bullets-1-" + this.props.slideSeq}
                        list={this.props.data.bullets}
                        color={this.props.colors.baseText}
                        animation="transition.slideDownIn,600,600"
                    />
                    <br />
                    <br />
                    <br />
                </div>
            );
        };

        let dontList = () => {
            return (
                <div style={dontListStyle}>
                    <Bullets
                        id="donts-list"
                        keyName={"bullets-2-" + this.props.slideSeq}
                        list={this.props.data.rebullets}
                        color={this.props.colors.baseText}
                        animation="transition.slideDownIn,600,600"
                    />
                    <br />
                    <br />
                    <br />
                </div>
            );
        };
        return (
            <div className="full-height">
                {isPortrait ? (
                    <div>
                        <SlideHeader
                            {...this.props}
                            title={this.props.data.title}
                            subtitle={this.props.data.subtitle}
                        />
                        <div style={defaultStyle}>
                            <div>
                                <div
                                    style={doBtnStyle}
                                    onClick={this.setActiveDo}
                                >
                                    <Icon
                                        name="thumbs up"
                                        size={isPortrait ? "small" : "big"}
                                    />
                                    <Text
                                        text="DOs"
                                        color={this.props.colors.baseText}
                                        animation="transition.slideDownIn,1000,750"
                                        fontSize={isPortrait ? "large" : "huge"}
                                    />
                                </div>
                                <div
                                    style={dontBtnStyle}
                                    onClick={this.setActiveDont}
                                >
                                    <Icon
                                        name="thumbs down"
                                        size={isPortrait ? "small" : "big"}
                                    />
                                    <Text
                                        text="DONTs"
                                        color={this.props.colors.baseText}
                                        animation="transition.slideDownIn,1000,750"
                                        fontSize={isPortrait ? "large" : "huge"}
                                    />
                                </div>
                            </div>
                            {this.state.active === "do" && dolist()}
                            {this.state.active === "dont" && dontList()}
                            {this.state.active === "none" && (
                                <div style={helptextStyle}>
                                    Click on the buttons above to view
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <Grid style={{ minHeight: "100%" }}>
                        <Grid.Row style={{ paddingBottom: "0" }}>
                            <Grid.Column
                                width={8}
                                style={{
                                    paddingRight: "0",
                                    background: this.props.colors.primary,
                                }}
                            >
                                <SlideHeaderSidebar
                                    {...this.props}
                                    title={this.props.data.title}
                                    subtitle={this.props.data.subtitle}
                                />
                            </Grid.Column>
                            <Grid.Column width={8} style={{ paddingLeft: "0" }}>
                                <div style={defaultStyle}>
                                    <div>
                                        <div
                                            style={doBtnStyle}
                                            onClick={this.setActiveDo}
                                        >
                                            <Icon
                                                name="thumbs up"
                                                size={isPortrait ? "" : "big"}
                                            />
                                            <Text
                                                text="DOs"
                                                color={
                                                    this.props.colors.baseText
                                                }
                                                animation="transition.slideDownIn,1000,750"
                                                fontSize={
                                                    isPortrait
                                                        ? "large"
                                                        : "huge"
                                                }
                                            />
                                        </div>
                                        <div
                                            style={dontBtnStyle}
                                            onClick={this.setActiveDont}
                                        >
                                            <Icon
                                                name="thumbs down"
                                                size={isPortrait ? "" : "big"}
                                            />
                                            <Text
                                                text="DONTs"
                                                color={
                                                    this.props.colors.baseText
                                                }
                                                animation="transition.slideDownIn,1000,750"
                                                fontSize={
                                                    isPortrait
                                                        ? "large"
                                                        : "huge"
                                                }
                                            />
                                        </div>
                                    </div>
                                    {this.state.active === "do" && dolist()}
                                    {this.state.active === "dont" && dontList()}
                                    {this.state.active === "none" && (
                                        <div style={helptextStyle}>
                                            Click on the buttons above to view
                                        </div>
                                    )}
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
            </div>
        );
    }
}
