// Import from NPM
// -------------------------------------
import React from "react";
import PropTypes from "prop-types";
import { Image } from "semantic-ui-react";
import Text from "./Text.react";
import DividerIcon from "./DividerIcon";
import { resolveImage } from "config/app.helpers";
import { ComponentInputs } from "schema/FormSchema.react";
export default class SlideHeader extends React.Component {
    static propTypes = {
        title: PropTypes.string,
        subtitle: PropTypes.string,
        height: PropTypes.string,
        fontSize: PropTypes.string,
    };

    static defaultProps = {
        title: "",
        subtitle: "",
        height: undefined,
        fontSize: "large",
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let hasTitle = this.props.title.length > 0 && this.props.title !== " ";
        let hasSubtitle =
            hasTitle &&
            this.props.subtitle.length > 0 &&
            this.props.subtext !== " ";
        let headerHeight = 0;
        if (this.props.height !== undefined) headerHeight = this.props.height;
        else if (hasTitle && hasSubtitle)
            headerHeight = isPortrait ? "25%" : "100%";
        else if (hasTitle) headerHeight = isPortrait ? "20%" : "";
        else headerHeight = 0;

        let headerContainerStyle = {
            width: "100%",
            height: headerHeight,
            marginBottom: "20px",
        };

        let headerStyle = {
            height: "100%",
            width: "100%",
            backgroundColor: this.props.colors.primary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            padding: hasTitle && hasSubtitle ? (isPortrait ? "5% 0" : "") : "",
        };

        let headerContentStyle = {
            width: "90%",
            fontSize: isPortrait ? "1.25em" : "1em",
            color: this.props.colors.primaryText,
            padding: isPortrait ? 0 : "30px 0",
        };
        let dividerStyle = {
            display: hasTitle ? "block" : "none",
        };

        let hasImage =
            this.props.data.image !== undefined &&
            !this.props.data.image.id.includes("default");

        let hasPresenter =
            this.props.data.presenter !== undefined &&
            this.props.data.presenter.id !== undefined &&
            this.props.data.presenter.id !== "default43";
        return (
            <div style={{ width: "100%", height: "100%" }}>
                {hasImage && isPortrait && (
                    <Image
                        src={resolveImage(
                            this.props.data.image.id,
                            this.props.imageLibrary
                        )}
                        alt="Title Image"
                        style={{ width: "100%" }}
                    />
                )}

                <div style={headerContainerStyle}>
                    {isPortrait && (
                        <div style={dividerStyle}>
                            <DividerIcon
                                {...this.props}
                                icon={
                                    (this.props.height === "50%" &&
                                        hasPresenter) ||
                                    (!isPortrait && hasPresenter) ||
                                    this.props.noIcon
                                        ? ""
                                        : this.props.data.icon
                                }
                                keyNames={[
                                    "divider-" + this.props.slideId,
                                    "icon-" + this.props.slideId,
                                ]}
                                animation="transition.bounceLeftIn,500,transition.expandIn,750"
                            />
                        </div>
                    )}
                    <div style={headerStyle}>
                        <div style={headerContentStyle}>
                            {isPortrait ? (
                                <Text
                                    keyName={"header-" + this.props.slideId}
                                    text={hasTitle ? this.props.title : ""}
                                    color={this.props.colors.primaryText}
                                    animation="transition.expandIn,250,250"
                                    fontSize={
                                        this.props.height === "50%"
                                            ? this.props.fontSize
                                            : (hasTitle &&
                                                  hasSubtitle &&
                                                  this.props.title.length >
                                                      ComponentInputs.title
                                                          .idealLength) ||
                                              (hasTitle &&
                                                  this.props.title.length > 80)
                                            ? "large"
                                            : "big"
                                    }
                                    isHeader={true}
                                    style={{
                                        marginBottom: "0.5em",
                                        padding:
                                            hasTitle && !hasSubtitle
                                                ? "15px"
                                                : "0",
                                    }}
                                />
                            ) : (
                                <Text
                                    keyName={"header-" + this.props.slideId}
                                    text={hasTitle ? this.props.title : ""}
                                    color={this.props.colors.primaryText}
                                    animation="transition.expandIn,250,250"
                                    fontSize={
                                        this.props.height === "50%"
                                            ? "ultra"
                                            : "huger"
                                    }
                                    isHeader={true}
                                    style={{
                                        marginBottom: "0.5em",
                                        textAlign: "left",
                                    }}
                                />
                            )}
                            {isPortrait ? (
                                <Text
                                    keyName={"subheader-" + this.props.slideId}
                                    text={
                                        hasSubtitle ? this.props.subtitle : ""
                                    }
                                    color={this.props.colors.primaryText}
                                    animation="transition.expandIn,250,250"
                                    fontSize={
                                        hasSubtitle &&
                                        this.props.subtitle.length >
                                            ComponentInputs.subtitle.idealLength
                                            ? "small"
                                            : "normal"
                                    }
                                    style={{
                                        textAlign: "center",
                                        width: "90%",
                                        margin: hasSubtitle
                                            ? "10px auto"
                                            : "0 auto",
                                    }}
                                />
                            ) : (
                                <Text
                                    keyName={"subheader-" + this.props.slideId}
                                    text={
                                        hasSubtitle ? this.props.subtitle : ""
                                    }
                                    color={this.props.colors.primaryText}
                                    animation="transition.expandIn,250,250"
                                    fontSize="large"
                                    style={{
                                        textAlign: "left",
                                        width: "80%",
                                        marginTop: "20px",
                                    }}
                                />
                            )}
                        </div>
                    </div>

                    {hasPresenter && isPortrait && (
                        <img
                            src={resolveImage(
                                this.props.data.presenter.id,
                                this.props.imageLibrary
                            )}
                            alt="Presenter"
                            style={{
                                position: "absolute",
                                bottom: "0",
                                right: "0",
                                width: this.props.largePresenter
                                    ? "70%"
                                    : "50%",
                                zIndex: "998",
                            }}
                        />
                    )}
                </div>
            </div>
        );
    }
}
