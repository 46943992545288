// Import from NPM
// -------------------------------------
import React from "react";
import PropTypes from "prop-types";
import { Modal, Form, Segment, Message } from "semantic-ui-react";
import { Button } from "config/initializers/styles.app";

export default class ContactUsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            phonenumber: "",
            workemail: "",
            organisation: "",
            designation: "",
            message: "",
            requestSent: false,
            loading: false
        };
    }

    static propTypes = {
        openModal: PropTypes.bool.isRequired,
        handleModalClose: PropTypes.func.isRequired,
    };

    handleChange = (e, { name, value }) => this.setState({ [name]: value });

    submitRequest = () => {
        this.setState({ loading: true });
        this.props.sendContactRequst(
            this.state.name,
            this.state.phonenumber,
            this.state.workemail,
            this.state.organisation,
            this.state.designation,
            this.state.message
        ).then(() => {
            this.setState({
                loading: false,
                requestSent: true,
                error: false,
                errorMessage: ""
            })
        }).catch((e) => {
            this.setState({
                loading: false,
                error: true,
                errorMessage: e.message
            })
        })
    }

    render() {
        return (
            <Modal
                closeIcon
                open={this.props.openModal}
                onClose={this.props.handleModalClose}
            >
                {!this.state.requestSent && (
                    <Modal.Header>
                        <h1>Contact Us</h1>
                    </Modal.Header>
                )}
                <Modal.Content>
                    {this.state.error && (
                        <Message
                            error
                            header="Oops!"
                            content={this.state.errorMessage}
                        />
                    )}
                    {this.state.requestSent && (
                        <Segment style={{ textAlign: "center" }}>
                            <h3>Thank you for Contacting Us!</h3>
                            <h4>We have booked your demo, We'll reach out to you soon!</h4>
                        </Segment>
                    )}
                    {!this.state.requestSent && (
                        <Form>
                            <Form.Group widths="equal">
                                <Form.Input
                                    fluid
                                    label="Full Name*"
                                    name="name"
                                    placeholder="Full name"
                                    onChange={this.handleChange}
                                />
                                <Form.Input
                                    fluid
                                    label="Phone Number"
                                    name="phonenumber"
                                    placeholder="Phone Number"
                                    onChange={this.handleChange}
                                />
                                <Form.Input
                                    fluid
                                    label="Work Email*"
                                    name="workemail"
                                    placeholder="Work Email"
                                    onChange={this.handleChange}
                                />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <Form.Input
                                    fluid
                                    label="Organisation*"
                                    name="organisation"
                                    placeholder="Organisation"
                                    onChange={this.handleChange}
                                />
                                <Form.Input
                                    fluid
                                    label="Designation*"
                                    name="designation"
                                    placeholder="Designation"
                                    onChange={this.handleChange}
                                />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <Form.TextArea
                                    fluid="true"
                                    label="Message"
                                    name="message"
                                    placeholder="Message"
                                    onChange={this.handleChange}
                                />
                            </Form.Group>
                        </Form>
                    )}
                </Modal.Content>
                <br />
                <br />
                {!this.state.requestSent && (
                    <Modal.Actions>
                        <Button
                            primary
                            content="Contact Us"
                            loading={this.state.loading}
                            disabled={
                                this.state.name === "" ||
                                this.state.workemail === "" ||
                                this.state.organisation === "" ||
                                this.state.designation === ""
                            }
                            onClick={this.submitRequest}
                        />
                    </Modal.Actions>
                )}
            </Modal>
        );
    }
}