import React, { Component } from "react";
import { Button } from "config/initializers/styles.app";
import { Dic } from "config/initializers/config.translations";

import _ from "lodash";
import PropTypes from "prop-types";
import { VelocityTransitionGroup } from "velocity-react";

export default class MultiSelectButtonGroup extends Component {
    static propTypes = {
        options: PropTypes.array.isRequired,
        extractFrom: PropTypes.string.isRequired,
        colors: PropTypes.object,
        animation: PropTypes.string,
        submit: PropTypes.func.isRequired,
    };
    static defaultProps = {
        colors: {
            base: "#FFFFFF",
            primary: "#cb1f37",
            primaryVariation: "#a41817",
            secondary: "#ffaa00",
            secondaryVariation: "#4db6ac",
            baseText: "#121212",
            primaryText: "#ffffff",
            secondaryText: "#000000",
        },
        animation: "fadeIn, 500",
    };

    constructor(props) {
        super(props);
        this.state = {
            selectedOptions: [],
        };
    }

    handleOptionClick = (index) => {
        let list = this.state.selectedOptions;
        var idx = _.indexOf(list, index);
        if (idx !== -1) {
            list.splice(idx, 1);
        } else {
            list.push(index);
        }
        this.setState({ selectedOptions: list });
    };
    handleOnSubmit = () => {
        this.props.submit(this.state.selectedOptions);
        let list = [];
        this.setState({ selectedOptions: list });
    };

    handleOnSubmit = () => {
        this.props.submit(this.state.selectedOptions);
        let list = [];
        this.setState({ selectedOptions: list });
    };

    render() {
        const dic = Object.assign(
            {},
            Dic[window.lang].common,
            Dic[window.lang].deck,
            Dic[window.lang].overlay
        );
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let animation = this.props.animation.split(",");
        let getOptions = _.map(this.props.options, (option, index) => {
            let buttonStyle = {
                marginBottom: "5px",
                textAlign: "left",
            };
            return (
                <Button
                    key={"button-" + index}
                    style={buttonStyle}
                    size={isPortrait ? "large" : "medium"}
                    onClick={() => this.handleOptionClick(index)}
                    content={option[this.props.extractFrom]}
                    labelPosition="left"
                    icon={
                        this.state.selectedOptions.indexOf(index) > -1
                            ? "checkmark"
                            : "square"
                    }
                />
            );
        });

        return (
            <div>
                <Button.Group vertical>
                    <VelocityTransitionGroup
                        enter={{
                            animation: animation[0],
                            duration: animation[1],
                            stagger: animation[2],
                        }}
                        leave={{ animation: "fadeOut", duration: "0" }}
                        runOnMount
                    >
                        {getOptions}
                    </VelocityTransitionGroup>
                </Button.Group>
                <br />
                <VelocityTransitionGroup
                    enter={{
                        animation: "transition.bounceUpIn",
                        duration: "500",
                        delay: this.props.options.length * 500,
                    }}
                    leave={{ animation: "fadeOut", duration: "0" }}
                    runOnMount
                >
                    <Button secondary onClick={this.handleOnSubmit}>
                        {this.props.purpose === "quiz"
                            ? dic.checkAnswer
                            : dic.submitAnswer}
                    </Button>
                </VelocityTransitionGroup>
                <br />
                <br />
            </div>
        );
    }
}
