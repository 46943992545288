import React from "react";
import ImageGroup from "../../contentBlocks/ImageGroup.react";
import Caption from "../../contentBlocks/Caption.react";
import SlideHeader from "../../contentBlocks/SlideHeader.react";
import SlideHeaderSidebar from "../../contentBlocks/SlideHeaderSidebar.react";
import PropTypes from "prop-types";
import { Grid } from "semantic-ui-react";
import { ComponentInputs } from "schema/FormSchema.react";
export default class ImageCaption extends React.Component {
    static propTypes = {
        colors: PropTypes.shape({
            base: PropTypes.string.isRequired,
            primary: PropTypes.string.isRequired,
            primaryVariation: PropTypes.string.isRequired,
            secondary: PropTypes.string.isRequired,
            baseText: PropTypes.string.isRequired,
            primaryText: PropTypes.string.isRequired,
            secondaryText: PropTypes.string.isRequired,
        }).isRequired,
        data: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            image: PropTypes.object,
            caption: PropTypes.string,
        }).isRequired,
        slideId: PropTypes.number,
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let defaultStyle = {
            margin: isPortrait ? "-20px auto" : "auto",
            width: "100%",
            textAlign: "center",
            height: "0",
            paddingBottom: "75%",
            overflow: "hidden",
            position: "relative",
        };
        let defaultLandscapeStyle = {
            margin: "auto",
            width: "100%",
            textAlign: "center",
            height: "0",
            paddingBottom: "70%",
            position: "relative",
            float: "right",
        };
        let hasPresenter =
            this.props.data.presenter !== undefined &&
            this.props.data.presenter !== "default43";
        let speechBubbleStyle = {
            position: "absolute",
            width: "100%",
            top: "-15px",
            left: "0",
            zIndex: "0",
            transform:
                hasPresenter && this.props.data.title === ""
                    ? "scaleY(-1)"
                    : "",
        };
        let captionStyle = {
            position: hasPresenter & isPortrait ? "absolute" : "relative",
            top: hasPresenter
                ? isPortrait
                    ? this.props.data.title === ""
                        ? "35%"
                        : "75%"
                    : "0"
                : "inherit",
            width: isPortrait
                ? hasPresenter
                    ? "60%"
                    : "90%"
                : hasPresenter
                    ? "100%"
                    : "90%",
            margin: isPortrait
                ? hasPresenter
                    ? this.props.data.caption &&
                        this.props.data.caption.length >
                        ComponentInputs.caption.idealLength
                        ? "50px 0 0 0"
                        : "0"
                    : "30px auto"
                : hasPresenter
                    ? "20px 0"
                    : "20px auto",
            textAlign: "left",
            fontSize: isPortrait
                ? hasPresenter
                    ? "0.65em"
                    : "0.75em"
                : hasPresenter
                    ? "0.65em"
                    : "1.0em",
            padding: isPortrait
                ? hasPresenter
                    ? "0 40px 0 15px"
                    : "0"
                : hasPresenter
                    ? "0 10px 0 10px"
                    : "0",
        };
        return (
            <div className="full-height">
                {isPortrait ? (
                    <div>
                        <SlideHeader
                            {...this.props}
                            title={this.props.data.title}
                            subtitle={this.props.data.subtitle}
                            largePresenter={this.props.data.title === ""}
                        />
                        <div style={defaultStyle}>
                            <ImageGroup
                                {...this.props}
                                keyName={"image-" + this.props.slideId}
                                size="medium"
                                images={[
                                    this.props.data.image === undefined && this.props.data.image.id === undefined
                                        ? this.props.data.images
                                        : this.props.data.image,
                                ]}
                                animation="transition.expandIn,300,100"
                                style={{
                                    height: "100%",
                                    pointerEvents: "none",
                                }}
                            />
                        </div>
                        <div style={captionStyle}>
                            {hasPresenter &&
                                this.props.data.caption &&
                                this.props.data.caption.length <=
                                ComponentInputs.caption.idealLength && (
                                    <img
                                        src="assets/images/speech_bubble2.png"
                                        alt="Speech bubble"
                                        style={speechBubbleStyle}
                                    />
                                )}
                            <Caption
                                keyName={"caption-" + this.props.slideId}
                                text={this.props.data.caption}
                                colors={this.props.colors}
                                animation="transition.bounceUpIn,400,750"
                            />
                        </div>
                    </div>
                ) : (
                    <Grid style={{ minHeight: "100%" }}>
                        <Grid.Row style={{ paddingBottom: "0" }}>
                            <Grid.Column
                                width={8}
                                style={{
                                    paddingRight: "0",
                                    background: this.props.colors.primary,
                                }}
                            >
                                <SlideHeaderSidebar
                                    {...this.props}
                                    title={this.props.data.title}
                                    subtitle={this.props.data.subtitle}
                                />
                            </Grid.Column>
                            <Grid.Column width={8} style={{ paddingLeft: "0" }}>
                                <div style={defaultLandscapeStyle}>
                                    <ImageGroup
                                        {...this.props}
                                        keyName={"image-" + this.props.slideId}
                                        size="medium"
                                        images={[
                                            this.props.data.image === undefined
                                                ? this.props.data.images
                                                : this.props.data.image,
                                        ]}
                                        animation="transition.expandIn,300,100"
                                        style={{ height: "100%" }}
                                    />
                                </div>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column
                                            width={hasPresenter ? 7 : 16}
                                        >
                                            <div style={captionStyle}>
                                                <Caption
                                                    keyName="caption"
                                                    text={
                                                        this.props.data.caption
                                                    }
                                                    colors={this.props.colors}
                                                    animation="transition.bounceUpIn,400,750"
                                                />
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
            </div>
        );
    }
}
