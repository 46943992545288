import React from "react";
import PropTypes from "prop-types";
import { Button } from "config/initializers/styles.app";
import { VelocityTransitionGroup } from "velocity-react";

export default class ChoiceGroup extends React.Component {
    static propTypes = {
        options: PropTypes.array.isRequired,
        extractFrom: PropTypes.string.isRequired,
        colors: PropTypes.object,
        animation: PropTypes.string,
        slideShow: PropTypes.bool,
    };

    static defaultProps = {
        colors: {
            base: "#ffffff",
            primary: "#cb1f37",
            primaryVariation: "#a41817",
            secondary: "#ffaa00",
            secondaryVariation: "#4db6ac",
            baseText: "#121212",
            primaryText: "#ffffff",
            secondaryText: "#000000",
        },
        animation: "fadeIn, 500",
        slideShow: false,
    };

    handleClick = (index) => {
        if (this.props.callback) this.props.callback(index);
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;

        let leftButtonStyle = {
            display: "inline",
            width: "48%",
            marginRight: "1%",
            borderRadius: "5px 0 0 5px",
        };
        let rightButtonStyle = {
            display: "inline",
            marginLeft: "1%",
            width: "48%",
            borderRadius: "0 5px 5px 0",
        };
        let orStyle = {
            position: "absolute",
            top: "50%",
            left: "49%",
            transform: "translate(-50%,-50%)",
            padding: "7px 10px",
            background: "#dddddd",
            borderRadius: "50%",
            zIndex: "9",
            fontWeight: "bold",
            boxShadow: "1px 1px 2px 1px #333333",
        };
        let animation = this.props.animation.split(",");
        let getOptions = (
            <div style={{ width: "100%", position: "relative" }}>
                <Button
                    size={isPortrait ? "medium" : "huge"}
                    style={leftButtonStyle}
                    onClick={() => this.handleClick(0)}
                >
                    {"" + this.props.options[0][this.props.extractFrom]}
                </Button>
                <div style={orStyle}>OR</div>
                <Button
                    secondary
                    size={isPortrait ? "medium" : "huge"}
                    style={rightButtonStyle}
                    onClick={() => this.handleClick(1)}
                >
                    {"" + this.props.options[1][this.props.extractFrom]}
                </Button>
            </div>
        );

        return (
            <VelocityTransitionGroup
                enter={{
                    animation: animation[0],
                    duration: animation[1],
                    stagger: animation[2],
                }}
                leave={{ animation: "fadeOut", duration: "0" }}
                runOnMount
            >
                {getOptions}
            </VelocityTransitionGroup>
        );
    }
}
