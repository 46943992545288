import { FETCH_FEED } from "appRedux/helpers/reduxConstants";
import { REHYDRATE } from "redux-persist/constants";

const initialFeedState = {
    posts: []
};

const FeedReducer = (state = initialFeedState, action) => {
    switch (action.type) {
        case REHYDRATE:
            let feed = action.payload.feed;
            if (feed) {
                return { ...state, ...feed };
            } else {
                return state;
            }
        case FETCH_FEED:
            return Object.assign({}, state, action.data);
        default:
            return state;
    }
};

export { FeedReducer };
