import React, { Component } from "react";
import { Image } from "config/initializers/styles.app";

export class MenuCourseImage extends Component {
    render() {
        return (
            <div
                style={{
                    boxShadow: "0 1px 2px 0 rgba(34,36,38,.15)",
                    borderRadius: "10px",
                    margin: "0 0 10px"
                }}
            >
                <Image
                    fluid
                    src={this.props.content.src}
                    style={{ borderRadius: "0.8em" }}
                />
            </div>
        );
    }
}
