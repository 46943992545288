import React from "react";
import PropTypes from "prop-types";
import Text from "../../contentBlocks/Text.react";
import Separator from "../../contentBlocks/Separator.react";
import ChoiceGroup from "../../contentBlocks/ChoiceGroup.react";
import SlideHeader from "../../contentBlocks/SlideHeader.react";
import SlideHeaderSidebar from "../../contentBlocks/SlideHeaderSidebar.react";
import { Grid } from "semantic-ui-react";

export default class TrueFalse extends React.Component {
    static propTypes = {
        colors: PropTypes.shape({
            base: PropTypes.string.isRequired,
            primary: PropTypes.string.isRequired,
            primaryVariation: PropTypes.string.isRequired,
            secondary: PropTypes.string.isRequired,
            baseText: PropTypes.string.isRequired,
            primaryText: PropTypes.string.isRequired,
            secondaryText: PropTypes.string.isRequired,
        }).isRequired,
        data: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            question: PropTypes.string,
            choice: PropTypes.array,
            caption: PropTypes.string,
        }).isRequired,
        slideId: PropTypes.number,
        trackInteraction: PropTypes.func.isRequired,
        mobileView: PropTypes.bool,
    };

    static defaultProps = {
        mobileView: false,
    };

    handleClick = (index) => {
        this.props.trackInteraction(index);
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let defaultStyle = {
            margin: "auto",
            width: "90%",
            textAlign: "center",
            paddingTop: window.innerWidth > 1400 ? "50px" : "25px",
        };
        let defaultLandscapeStyle = {
            margin: "60px auto",
            width: "100%",
            textAlign: "center",
            position: "relative",
            float: "right",
            padding: "30px",
        };
        let title =
            this.props.data.header !== undefined
                ? this.props.data.header
                : this.props.data.title;
        let subtitle =
            this.props.data.subheader !== undefined
                ? this.props.data.subheader
                : this.props.data.subtitle;
        return (
            <div className="full-height">
                {isPortrait ? (
                    <div>
                        <SlideHeader
                            {...this.props}
                            title={this.props.data.question}
                            subtitle={subtitle}
                        />
                        <div style={defaultStyle} className="choicy">
                            <ChoiceGroup
                                keyName={`choice-${this.props.slideId}`}
                                colors={this.props.colors}
                                options={this.props.data.choice}
                                extractFrom="text"
                                animation="transition.slideDownIn,500,500"
                                callback={this.handleClick}
                                slideShow={this.props.slideShow}
                            />
                        </div>
                    </div>
                ) : (
                    <Grid style={{ minHeight: "100%" }}>
                        <Grid.Row style={{ paddingBottom: "0" }}>
                            <Grid.Column
                                width={8}
                                style={{
                                    paddingRight: "0",
                                    background: this.props.colors.primary,
                                }}
                            >
                                <SlideHeaderSidebar
                                    {...this.props}
                                    title={title}
                                    subtitle={subtitle}
                                />
                            </Grid.Column>
                            <Grid.Column width={8} style={{ paddingLeft: "0" }}>
                                <div style={defaultLandscapeStyle}>
                                    <Text
                                        keyName={
                                            "question-" + this.props.slideId
                                        }
                                        text={this.props.data.question}
                                        color={this.props.colors.baseText}
                                        fontSize="large"
                                        animation="fadeIn,100"
                                    />
                                    <Separator
                                        keyName="separator"
                                        color={this.props.colors.primary}
                                        animation="fadeIn,100"
                                    />
                                    <ChoiceGroup
                                        keyName="choice"
                                        colors={this.props.colors}
                                        options={this.props.data.choice}
                                        extractFrom="text"
                                        animation="transition.slideDownIn,500,500"
                                        callback={this.handleClick}
                                        slideShow={this.props.slideShow}
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
            </div>
        );
    }
}
