// Import from NPM
// -------------------------------------
import React from "react";
import _ from "lodash";
import { Treebeard, decorators } from "react-treebeard";
import { Input } from "semantic-ui-react";
import { hashHistory } from "react-router";

// Constants
// -------------------------------------
decorators.Header = ({ style, node }) => {
    const headerStyle = {
        ...style.base,
        cursor: "pointer",
        color: "#767676",
    };
    const activeHeaderStyle = {
        ...style.base,
        color: "#ffffff",
        cursor: "pointer",
        width: "90%",
    };

    return (
        <div style={node.active ? activeHeaderStyle : headerStyle}>
            <div style={style.title}>{node.name}</div>
        </div>
    );
};

export class TagManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allTags: this.props.allTags,
            selectedTag: {},
            searchText: "",
        };
    }

    onFilterMouseUp = (e) => {
        if (e.key === "Enter") {
            this.startSearch(e);
        } else {
            this.setState({ searchText: e.target.value.trim() });
        }
    };

    startSearch = (e) => {
        const filter = this.state.searchText;
        if (!filter) {
            return this.setState({
                allTags: this.props.allTags,
            });
        }
        let filtered,
            filteredNodes = [];
        _.each(this.props.allTags, (tagTree) => {
            filtered = filterTree(tagTree, filter);
            filtered = expandFilteredNodes(filtered, filter);
            if (filtered.children.length > 0) filteredNodes.push(filtered);
        });

        this.setState({ allTags: filteredNodes });
    };

    onToggle = (node, toggled) => {
        const { cursor } = this.state;

        if (cursor) {
            cursor.active = false;
        }

        node.active = true;
        if (node.children) {
            node.toggled = toggled;
        }

        this.setState({ cursor: node });

        if (!node.children) {
            let currentLocation = hashHistory.getCurrentLocation();
            hashHistory.push(
                currentLocation.pathname +
                    currentLocation.search +
                    (currentLocation.search.indexOf("tags") === -1
                        ? "&tags="
                        : ",") +
                    node.name
            );
        }
    };

    render() {
        let treePanel = {
            base: {
                width: "100%",
                minHeight: "300px",
                background: "#ffffff",
                overflow: "auto",
                position: "relative",
            },
            subtext: {
                background: "#666666",
                color: "#eee",
            },
            content: {
                background: "#ffffff",
                fontSize: "1.5em",
            },
        };
        return (
            <div style={treePanel.base}>
                <div style={treePanel.subtext}>
                    <Input
                        fluid
                        action={{
                            color: "grey",
                            icon: "search",
                            onClick: this.startSearch,
                        }}
                        placeholder={this.props.subText}
                        onKeyUp={this.onFilterMouseUp}
                    />
                </div>
                <br />
                <div style={treePanel.content}>
                    <Treebeard
                        data={this.state.allTags}
                        decorators={decorators}
                        onToggle={this.onToggle}
                    />
                </div>
            </div>
        );
    }
}

// Helper functions for filtering tree
export const defaultMatcher = (filterText, node) => {
    return node.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1;
};

export const findNode = (node, filter, matcher) => {
    return (
        matcher(filter, node) || // i match
        (node.children && // or i have decendents and one of them match
            node.children.length &&
            !!node.children.find((child) => findNode(child, filter, matcher)))
    );
};

export const filterTree = (node, filter, matcher = defaultMatcher) => {
    // If im an exact match then all my children get to stay
    if (matcher(filter, node) || !node.children) {
        return node;
    }
    // If not then only keep the ones that match or have matching descendants
    const filtered = node.children
        .filter((child) => findNode(child, filter, matcher))
        .map((child) => filterTree(child, filter, matcher));
    return Object.assign({}, node, { children: filtered });
};

export const expandFilteredNodes = (node, filter, matcher = defaultMatcher) => {
    let children = node.children;
    if (!children || children.length === 0) {
        return Object.assign({}, node, { toggled: false });
    }
    const childrenWithMatches = node.children.filter((child) =>
        findNode(child, filter, matcher)
    );
    const shouldExpand = childrenWithMatches.length > 0;
    // If im going to expand, go through all the matches and see if thier children need to expand
    if (shouldExpand) {
        children = childrenWithMatches.map((child) => {
            return expandFilteredNodes(child, filter, matcher);
        });
    }
    return Object.assign({}, node, {
        children: children,
        toggled: shouldExpand,
    });
};
