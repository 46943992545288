import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Document, Page } from "react-pdf";
import { Request } from "backend/communication/Request";
import { Icon } from "semantic-ui-react";
import Loading from "mobile/appComponents/loading/Loading.react";
import { getAppConfig } from "config/client.config";

export default class PdfTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetching: true,
            blobData: "",
            numPages: null,
            pageNumber: 1,
            pageScale: 10,
            showInfo: true,
            fullScreenOpen: false,
            showControl: false,
            noPdfMessage: "loading pdf please wait..."
        };
        this.navigatePage = this.navigatePage.bind(this);
        this.messageDom = this.messageDom.bind(this);
    }

    static propTypes = {
        url: PropTypes.string.isRequired,
        editorWidth: PropTypes.bool
    };

    static defaultProps = {
        editorWidth: false
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (
            prevState.blobData !== nextProps.blobData &&
            nextProps.url.startsWith("data:application/pdf;base64")
        ) {
            let contentType = "application/pdf";
            let sliceSize = 512;

            var byteCharacters = atob(
                nextProps.url.replace("data:application/pdf;base64,", "")
            );
            var byteArrays = [];

            for (
                var offset = 0;
                offset < byteCharacters.length;
                offset += sliceSize
            ) {
                var slice = byteCharacters.slice(offset, offset + sliceSize);

                var byteNumbers = new Array(slice.length);
                for (var i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                var byteArray = new Uint8Array(byteNumbers);

                byteArrays.push(byteArray);
            }

            return {
                blobData: new Blob(byteArrays, { type: contentType }),
                fetching: false
            };
        }
        return null;
    }

    componentDidMount() {
        if (this.props.url.startsWith("data:application/pdf")) {
            this.setState({
                blobData: this.b64toBlob(
                    this.props.url.replace("data:application/pdf;base64,", ""),
                    "application/pdf"
                ),
                noPdfMessage: "Loading PDF",
                fetching: false
            });
        } else {
            let pdfData = this.props.url;
            let deckId = this.props.deckId;
            let url =
                getAppConfig().apiUrls.assetUrl.endsWith('/') ?
                    `${getAppConfig().apiUrls.assetUrl}public/qdf/documents/${deckId}/${pdfData}.pdf` :
                    `${getAppConfig().apiUrls.assetUrl}/public/qdf/documents/${deckId}/${pdfData}.pdf`;

            this.setState({
                blobData: url,
                fetching: false
            });

        }
    }

    onDocumentLoad = ({ numPages }) => {
        if (numPages === 1) this.handleNavigation(numPages, numPages);
        this.setState({ numPages });
    };

    b64toBlob = (b64Data, contentType, sliceSize) => {
        contentType = contentType || "";
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (
            var offset = 0;
            offset < byteCharacters.length;
            offset += sliceSize
        ) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    };

    fetchAsset() {
        let pdfData = this.props.url;
        if (pdfData) {
            if (pdfData.indexOf(";base64,") > -1) {
                return Promise.resolve(pdfData);
            } else {
                // This should use fetchAsset when connected to axios.
                return Request.fetch("/api/v1/fetch_assets_from_url", {
                    body: JSON.stringify({ url: pdfData }),
                    method: "POST",
                    responseType: "blob"
                })
                    .then(response => {
                        if (response.ok) {
                            return response.blob().then(blob => {
                                return blob;
                            });
                        } else {
                            return Promise.resolve(0);
                        }
                    })
                    .catch(response => {
                        return Promise.resolve(0);
                    });
            }
        } else {
            return Promise.reject(0);
        }
    }

    navigatePage(direction) {
        let currentPageNo = this.state.pageNumber,
            totalPages = this.state.numPages,
            pageNo = null;
        switch (direction) {
            case "prev":
                if (currentPageNo > 1) pageNo = currentPageNo - 1;
                break;
            case "next":
                if (currentPageNo < totalPages) pageNo = currentPageNo + 1;
                break;
            default:
                break;
        }
        if (pageNo !== null) {
            this.setState({ pageNumber: pageNo });
            this.handleNavigation(pageNo, totalPages);
        }
    }

    handleNavigation(currentPage, totalPages) {
        if (this.props.handleCompletion)
            this.props.handleCompletion(currentPage, totalPages);
    }

    messageDom(message) {
        return <div style={{ width: "100vw", color: "#fff" }}>{message}</div>;
    }

    handleRangeChange = e =>
        this.setState({ pageScale: parseInt(e.target.value, 0) });
    handleFullScreenOpen = () =>
        this.setState({
            fullScreenOpen: !this.state.fullScreenOpen,
            pageScale: 10
        });
    handleControlOpen = () =>
        this.setState({ showControl: !this.state.showControl });

    render() {
        const isPortrait = window.innerHeight > window.innerWidth;
        let wrapperStyle = {
            height: "100%",
            width: "100%",
            textAlign: "center",
            position: "relative",
            background: "#666",
            marginTop: "40px",
            overflow: "hidden"
        };
        let defaultStyle = {
            height: "100%",
            width: "100%"
        };
        let controlStyle = {
            position: "fixed",
            bottom: "0",
            height: "7vh",
            padding: "15px 10px",
            width: "100%",
            background: "#333333",
            zIndex: "1004",
            display: this.state.showControl ? "block" : "none"
        };
        let zoomSlider = {
            width: isPortrait ? "85%" : "100%",
            float: "left",
            display: !this.state.fullScreenOpen ? "block" : "none"
        };
        let fullScreenIcon = {
            float: "right",
            color: "white"
        };
        let modalStyle = {
            position: "fixed",
            top: "0",
            left: "0",
            width: "100vw",
            height: "100vh",
            overflow: "auto",
            padding: "0",
            margin: "0",
            zIndex: "1003",
            background: "#666666"
        };
        let rotated = {
            position: "absolute",
            top: "22vh",
            left: "22vh",
            width: "100vw",
            height: "100vh",
            transform: "rotate(-90deg)"
        };
        let pdfDocument = (
            <Document
                file={this.state.blobData}
                onLoadSuccess={this.onDocumentLoad}
                loading={this.messageDom("loading pdf please wait...")}
                noData={this.messageDom(this.state.noPdfMessage)}
            >
                {_.times(this.state.numPages, num => {
                    return (
                        <Page
                            key={"page-" + num}
                            pageNumber={num + 1}
                            width={
                                ((this.state.fullScreenOpen
                                    ? 450
                                    : this.props.editorWidth
                                        ? 330
                                        : 290) *
                                    this.state.pageScale) /
                                10
                            }
                            onClick={this.handleControlOpen}
                        />
                    );
                })}
            </Document>
        );
        if (this.state.fetching) return <Loading />;
        else
            return (
                <div style={wrapperStyle}>
                    {!this.state.fullScreenOpen && (
                        <div style={defaultStyle}>
                            <div
                                style={{
                                    display: "block",
                                    height: "100%",
                                    overflowY: "scroll",
                                    overflowX: isPortrait ? "scroll" : "hidden"
                                }}
                            >
                                {pdfDocument}
                            </div>
                        </div>
                    )}
                    {this.state.fullScreenOpen && (
                        <div style={modalStyle}>
                            <div style={rotated}>{pdfDocument}</div>
                        </div>
                    )}
                    {isPortrait && (
                        <div style={controlStyle}>
                            <div style={zoomSlider}>
                                <input
                                    type="range"
                                    min="5"
                                    max="25"
                                    step="5"
                                    value={this.state.pageScale}
                                    className="slider"
                                    onChange={this.handleRangeChange}
                                />
                            </div>
                            <Icon
                                name="expand"
                                size="large"
                                style={fullScreenIcon}
                                onClick={this.handleFullScreenOpen}
                            />
                        </div>
                    )}
                </div>
            );
    }
}
