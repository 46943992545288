import _ from "lodash";
import { REHYDRATE } from "redux-persist/constants";
import {
    FETCH_LIBRARY,
    COMPLETE_ATTEMPT,
    INCOMPLETE_ATTEMPT,
    BADGE_EARNED,
    MEDAL_EARNED,
    REWARD_EARNED
} from "appRedux/helpers/reduxConstants";
import {
    aggregateDeckState,
    aggregateHistory,
} from "appRedux/helpers/aggregatorFunctions";

const initialState = {};

export const defaultDeckState = {
    viewedPercentage: 0,
    viewAttempts: 0,
    timeSpent: 0,
    score: 0,
    completion: false,
    completionDate: null,
    lastPosition: 0,
    subStates: {},
    gamePoints: 0
};
export const defaultDeckHistory = {
    f: {},
    y: {},
    q: {},
    m: {},
};

const DecksReducer = (state = initialState, action) => {
    switch (action.type) {
        /* istanbul ignore next - react-persist function */
        case REHYDRATE:
            let decks = action.payload.decks;
            if (decks) {
                return { ...state, ...decks };
            } else {
                return state;
            }

        case FETCH_LIBRARY:
            return Object.assign(
                {},
                _.mapValues(action.data.decks, (deck) => {
                    return Object.assign({}, _.omit(deck, "file_size"), {
                        currentState:
                            deck.currentState === undefined
                                ? defaultDeckState
                                : deck.currentState,
                        history:
                            deck.history === undefined
                                ? defaultDeckHistory
                                : deck.history,
                    });
                })
            );
        case COMPLETE_ATTEMPT:
        case INCOMPLETE_ATTEMPT:
            const deckId = action.data.attempt.id;
            return Object.assign({}, state, {
                [deckId]: Object.assign({}, state[deckId], {
                    currentState: aggregateDeckState(
                        state[deckId].currentState,
                        action.data.attempt
                    ),
                    history: aggregateHistory(
                        state[deckId].history,
                        action.data
                    ),
                }),
            });
        case BADGE_EARNED:
        case MEDAL_EARNED:
        case REWARD_EARNED:
        default:
            return state;
    }
};

export { DecksReducer };
