import React from "react";
import PropTypes from "prop-types";
//import PdfReader from "mobile/appComponents/pdf_reader/PdfReader.react";
// import SlideHeader from "../../contentBlocks/SlideHeader.react";
import PdfScroller from "mobile/appComponents/pdf_reader/PdfTemplate.react";
export default class PdfViewer extends React.Component {
    static propTypes = {
        data: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            pdf: PropTypes.object,
        }).isRequired,
        slideId: PropTypes.number,
        mobileView: PropTypes.bool,
        handleCompletion: PropTypes.func,
    };

    handleCompletion = (currentPage, totalPages) => {
        //logic for individual pdf slide to be added
    };

    resolveDocument = (pdf, library) => {
        if (library !== undefined && library.length > 0) {
            if (pdf.id !== "") {
                let data = library.find((doc) => doc.id === pdf.id);
                if (data) {
                    return data.doc;
                } else {
                    if (typeof this.props.data.pdf === "object") {
                        return this.props.data.pdf.id.toString()
                    } else {
                        return this.props.data.pdf;
                    }
                }
            } else {
                return "";
            }
        } else {
            if (typeof this.props.data.pdf === "object") {
                return this.props.data.pdf.id.toString()
            } else {
                return this.props.data.pdf;
            }
        }
    }

    render() {
        let defaultStyle = {
            margin: "auto",
            width: "100%",
            textAlign: "center",
            paddingTop: "0",
            position: "relative",
        };
        return (
            <div className="full-height">
                <div style={defaultStyle}>
                    <PdfScroller
                        key={`doc-${this.props.slideSeq}`}
                        url={
                            this.resolveDocument(this.props.data.pdf, this.props.docLibrary)
                        }
                        content_type="pdf_reader"
                        handleCompletion={this.handleCompletion}
                        deckId={this.props.deckId}
                    />
                </div>
            </div>
        );
    }
}
