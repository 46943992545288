import React from "react";
import VideoBox from "mobile/appComponents/videobox/VideoBox.react";
import SlideHeader from "../../contentBlocks/SlideHeader.react";
import PropTypes from "prop-types";
import SlideHeaderSidebar from "../../contentBlocks/SlideHeaderSidebar.react";
import { Grid } from "semantic-ui-react";

export default class Video extends React.Component {
    static propTypes = {
        data: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            video: PropTypes.string,
        }).isRequired,
        mobileView: PropTypes.bool,
    };
    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let defaultStyle = {
            margin: "-20px auto",
            width: "100%",
            textAlign: "center",
        };
        let videoWrapperStyle = {
            height: "0",
            width: "100%",
            paddingBottom: "75%",
            position: "relative",
        };
        let videoStyle = {
            height: "100%",
            width: "100%",
            position: "absolute",
            top: "0",
            left: "0",
        };
        let defaultLandscapeStyle = {
            margin: "auto",
            width: "100%",
            textAlign: "center",
            height: "0",
            //paddingBottom: "75%",
            position: "relative",
            float: "right",
        };

        return (
            <div className="full-height">
                {isPortrait ? (
                    <div>
                        <SlideHeader
                            {...this.props}
                            title={this.props.data.title}
                            subtitle={this.props.data.subtitle}
                        />
                        <div
                            style={
                                isPortrait
                                    ? defaultStyle
                                    : defaultLandscapeStyle
                            }
                        >
                            <div style={videoWrapperStyle}>
                                <div style={videoStyle}>
                                    <VideoBox
                                        url={this.props.data.video}
                                        autoplay={true}
                                        showFullScreenBtn={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <Grid style={{ minHeight: "100%" }}>
                        <Grid.Row style={{ paddingBottom: "0" }}>
                            <Grid.Column
                                width={8}
                                style={{
                                    paddingRight: "0",
                                    backgroundColor: this.props.colors.primary,
                                }}
                            >
                                <SlideHeaderSidebar
                                    {...this.props}
                                    title={this.props.data.title}
                                    subtitle={this.props.data.subtitle}
                                />
                            </Grid.Column>
                            <Grid.Column width={8} style={{ paddingLeft: "0" }}>
                                <div
                                    style={
                                        isPortrait
                                            ? defaultStyle
                                            : defaultLandscapeStyle
                                    }
                                >
                                    <div style={videoWrapperStyle}>
                                        <div style={videoStyle}>
                                            <VideoBox
                                                url={this.props.data.video}
                                                autoplay={true}
                                                showFullScreenBtn={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
            </div>
        );
    }
}
