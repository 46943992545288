import React from "react";
import { connect } from "react-redux";
import NotificationWrapper from "./wrappers/NotificationWrapper.react";
import NotificationAction from "appRedux/Notification.action";
import ErrorPage from "globals/error/ErrorPage.react";
import Loading from "mobile/appComponents/loading/Loading.react";
import _ from "lodash";

export class NotificationContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fetched: false,
            error: false,
            errorMessage: [],
        };
    }
    componentDidMount() {
        this.props.actions
            .fetchNotification(this.props.auth.user.id)
            .then((notification) => {
                this.setState({ fetched: true });
            })
            .catch((error) => {
                this.setState({
                    fetched: true,
                    error: true,
                    errorMessage: error.errors,
                });
            });

        this.props.actions.markedReadNotifications(this.props.auth.user.id);
    }
    getErrors = () => {
        let error = "";
        if (this.state.error && this.state.errorMessage.length > 0)
            error = _.join(this.state.errorMessage, "\n");
        return error;
    };

    render() {
        if (!this.state.fetched) return <Loading showFull={true} />;
        else if (this.state.fetched && !this.state.error) {
            return (
                <NotificationWrapper
                    {...this.props}
                    course={this.props.courses[_.keys(this.props.courses)[0]]}
                    loading={!this.state.fetched}
                />
            );
        } else return <ErrorPage msg={this.getErrors()} />;
    }
}

const mapStateToProps = (state) => {
    return {
        notifications: state.notification.notifications,
        courses: state.courses,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            fetchNotification: (learnerId, count) => {
                return dispatch(
                    NotificationAction.fetchNotifications(learnerId, count)
                );
            },
            markedReadNotifications: (learnerId) => {
                return dispatch(
                    NotificationAction.markedReadNotifications(learnerId)
                );
            },
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationContainer);
