import React, { Component } from "react";
import _ from "lodash";
import { hashHistory } from "react-router";
import { Segment, Message } from "semantic-ui-react";
import { ListingMenuHeader } from "./ListingMenuHeader.react";
import { MenuTagTree } from "./listingMenuPanels/MenuTagTree.react";
import { MenuCard } from "./listingMenuPanels/MenuCard.react";
import { MenuAd } from "./listingMenuPanels/MenuAd.react";
import { MenuList } from "./listingMenuPanels/MenuList.react";
import { MenuTagList } from "./listingMenuPanels/MenuTagList.react";
import { MenuNavigator } from "./listingMenuPanels/MenuNavigator.react";
import { TrendingArticles } from "./listingMenuPanels/TrendingArticles.react";
import { SuggestedArticles } from "./listingMenuPanels/SuggestedArticles.react";
import { ProfileCard } from "./listingMenuPanels/ProfileCard.react";
import { CourseAnalytics } from "./listingMenuPanels/CourseAnalytics.react";
import { Notifications } from "./listingMenuPanels/Notifications.react";
import { getAppConfig } from "config/client.config";
import { Button } from "config/initializers/styles.app";
import { appConfig } from "config/initializers/config.app";
import { MenuCourseImage } from "./listingMenuPanels/MenuCourseImage.react";

export class ListingMenu extends Component {
    goToPage = (a) => hashHistory.push(a);
    removeTagFilter = (tag) => {
        let currentLocation = hashHistory.getCurrentLocation();
        let queryString = this.props.location.query;
        let tagList = queryString.tags.split(",");
        tagList.splice(tagList.indexOf(tag), 1);
        hashHistory.push(
            currentLocation.pathname +
                "?search=" +
                queryString.search +
                "?category=" +
                queryString.category +
                (tagList.length > 0 ? "&tags=" + tagList.join(",") : "")
        );
    };
    selectTag = (tag) => {
        let currentLocation = hashHistory.getCurrentLocation();
        let urlArray = [];
        if (this.props.location.query.category)
            urlArray.push("category=" + this.props.location.query.category);
        if (this.props.location.query.search)
            urlArray.push("search=" + this.props.location.query.search);
        urlArray.push("tags=" + tag.name);

        hashHistory.push(currentLocation.pathname + "?" + urlArray.join("&"));
    };
    selectTags = (tags) => {
        let urlArray = [];
        if (this.props.location.query.category)
            urlArray.push("category=" + this.props.location.query.category);
        if (this.props.location.query.search)
            urlArray.push("search=" + this.props.location.query.search);
        urlArray.push("tags=" + tags.join(","));

        hashHistory.push("/articles?" + urlArray.join("&"));
    };
    clearTags = () => {
        let currentLocation = hashHistory.getCurrentLocation();
        let urlArray = [];
        if (this.props.location.query.category)
            urlArray.push("category=" + this.props.location.query.category);
        if (this.props.location.query.search)
            urlArray.push("search=" + this.props.location.query.search);

        hashHistory.push(
            currentLocation.pathname +
                (urlArray.length > 0 ? "?" : "") +
                urlArray.join("&")
        );
    };
    selectArticle = (article) => {
        hashHistory.push(
            "/articles/show/" + article._id + "?category=" + article.category
        );
    };

    render() {
        let wrapper = {
            // minHeight: "calc(100vh - 80px)",
        };
        let courseId = window.location.href.split("/courses/")[1];
        let thisCourse = this.props.course || {};
        let inductorContent = {
            src:
                this.props.course &&
                this.props.course.id === courseId &&
                this.props.course.image !== ""
                    ? this.props.course.image.indexOf("https") !== -1
                        ? this.props.course.image
                        : getAppConfig().apiUrls.apiUrl +
                          this.props.course.image
                    : "assets/images/configurable/missing_course.jpg",
        };
        let segmentContent = (content) => {
            switch (content.format) {
                case "list":
                    return (
                        <MenuList
                            {...this.props}
                            content={content}
                            goToPage={this.goToPage}
                            selectTag={this.selectTag}
                            clearTags={this.clearTags}
                        />
                    );
                case "card":
                    return (
                        <MenuCard
                            {...this.props}
                            content={content}
                            goToPage={this.goToPage}
                        />
                    );
                case "ad":
                    return (
                        <MenuAd
                            {...this.props}
                            key={Math.random()}
                            content={content}
                            goToPage={this.goToPage}
                        />
                    );
                case "tagTree":
                    return (
                        <MenuTagTree
                            {...this.props}
                            removeTagFilter={this.removeTagFilter}
                        />
                    );
                case "tagList":
                    return (
                        <MenuTagList
                            {...this.props}
                            selectTag={this.selectTag}
                            clearTags={this.clearTags}
                        />
                    );
                case "navigator":
                    return (
                        <MenuNavigator
                            {...this.props}
                            selectTags={this.selectTags}
                            clearTags={this.clearTags}
                            menuNavigation={false}
                            selectTag={false}
                        />
                    );
                case "tranding":
                    return (
                        <TrendingArticles
                            {...this.props}
                            selectArticle={this.selectArticle}
                        />
                    );
                case "reading":
                    return (
                        <SuggestedArticles
                            {...this.props}
                            selectSuggestedArticle={
                                this.props.selectSuggestedArticle
                            }
                        />
                    );
                case "profile":
                    return (
                        <ProfileCard
                            {...this.props}
                            key={"profile-card"}
                            content={content}
                        />
                    );
                case "courseAnalytics":
                    return (
                        <CourseAnalytics
                            {...this.props}
                            key={"course-analytics-card"}
                            user={this.props.auth.user}
                        />
                    );
                case "notifications":
                    return (
                        <Notifications
                            {...this.props}
                            key={"notifications-card"}
                            content={content}
                            goToPage={this.goToPage}
                        />
                    );
                case "courseImage":
                    return (
                        <MenuCourseImage
                            {...this.props}
                            key={Math.random()}
                            content={inductorContent}
                            goToPage={this.goToPage}
                        />
                    );
                default:
            }
        };
        let profileSettings = getAppConfig().learningCenter.profileList
            ? getAppConfig().learningCenter.profileList
            : {};

        return (
            <div style={wrapper}>
                {_.map(this.props.structure.menu, (menuBlock, idx) => {
                    if (
                        [
                            "ad",
                            "profile",
                            "courseAnalytics",
                            "courseImage",
                        ].includes(menuBlock.format)
                    )
                        return (
                            <div
                                key={idx}
                                style={{
                                    background: getAppConfig().themedLXP
                                        ? getAppConfig().inductor
                                            ? ""
                                            : "#454545"
                                        : "#ffffff",
                                }}
                            >
                                {segmentContent(menuBlock)}
                            </div>
                        );
                    else
                        return (
                            <Segment
                                key={`lpanel-block-${idx}`}
                                basic={this.props.location.query.search != null}
                                style={{
                                    background: getAppConfig().themedLXP
                                        ? "#454545"
                                        : "#ffffff",
                                    color: getAppConfig().themedLXP
                                        ? thisCourse && thisCourse.colors
                                            ? thisCourse.colors.primaryText
                                            : "#ffffff"
                                        : "#000000",
                                }}
                            >
                                <ListingMenuHeader
                                    {...this.props}
                                    title={menuBlock.title}
                                    icon={menuBlock.icon}
                                    format={menuBlock.format}
                                />
                                {menuBlock.subtitle && (
                                    <Message content={menuBlock.subtitle} />
                                )}
                                {segmentContent(menuBlock)}
                            </Segment>
                        );
                })}
                {appConfig.inductor && profileSettings.logout && (
                    <Button
                        fluid
                        content={"LOGOUT"}
                        onClick={() => this.goToPage("/logout")}
                    />
                )}
            </div>
        );
    }
}
export default ListingMenu;
