import React, { PureComponent } from "react";
import { Segment, Icon, Dropdown } from "semantic-ui-react";
import { getAppConfig } from "config/client.config";
import PropTypes from "prop-types";
import {
    PieChart,
    Pie,
    Cell,
    BarChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Bar,
    ResponsiveContainer
} from "recharts";
import axios from "axios";
import _ from "lodash";

const filterOptions = [
    { key: "l-7", value: "weekly", text: "Last 7 Days" },
    { key: "l-15", value: "half-month", text: "Last 15 Days" },
    { key: "l-30", value: "monthly", text: "Last 30 Days" },
];

class QuoRadialProgressChart extends PureComponent {
    static propTypes = {
        width: PropTypes.number,
        height: PropTypes.number,
        color: PropTypes.string,
        value: PropTypes.number.isRequired,
        top: PropTypes.number,
        left: PropTypes.number
    };
    static defaultProps = {
        width: 200,
        height: 200,
        color: "#8884d8"
    };
    render() {
        const isPortrait = window.innerHeight > window.innerWidth;
        let data = [
            {
                name: isNaN(this.props.value)
                    ? "-"
                    : (this.props.value * 20) / 100,
                value: isNaN(this.props.value)
                    ? 0
                    : Math.round(this.props.value)
            },
            {
                name: "",
                value: isNaN(this.props.value)
                    ? 100
                    : 100 - Math.round(this.props.value)
            }
        ];
        let formatedScore = isNaN(this.props.value)
            ? "-"
            : (this.props.value * 20) / 100
        return (
            <div style={{ position: "relative", marginTop: "-10px" }}>
                <PieChart width={this.props.width} height={this.props.height}>
                    <Pie
                        data={data}
                        cx={"50%"}
                        cy={"50%"}
                        innerRadius={this.props.width / 2 - 40}
                        outerRadius={this.props.width / 2 - 20}
                        fill={this.props.color}
                        paddingAngle={5}
                        dataKey="value"
                    >
                        {data.map((entry, index) => {
                            return <Cell
                                key={`cell-${index}`}
                                fill={
                                    index === 0 ?
                                        (formatedScore > 0 && formatedScore <= 10) ? "orange" : (formatedScore > 10 && formatedScore <= 20) ? "red" : "red"

                                        : formatedScore === 0 ? "green" : "#cccccc"
                                }
                            />
                        })}
                    </Pie>
                </PieChart>
                <div
                    style={{
                        position: "absolute",
                        top: isPortrait ? this.props.top : "47%",
                        left: isPortrait ? this.props.left : "50%",
                        transform: "translate(-50%, -50%)",
                        fontSize: isPortrait ? "1.2em" : "2.5em",
                        color: "#454545"
                    }}
                >
                    {data[0].name}
                </div>
            </div>
        );
    }
}

export default class GamifixPerformance extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            selectValue: "weekly",
            showdetails: true,
            detailBtn: "HIDE DETAILS",
            historyData: null,
            aggrigateData: [],
            headers: {}
        };
    }

    componentDidMount() {
        var date = new Date();
        let previousDay = date.setDate(date.getDate() - 2);
        var weekDate = new Date();
        let fromDate = weekDate.setDate(weekDate.getDate() - 8);
        let userId = this.props.auth.user.username;
        axios({
            method: "POST",
            url: `${getAppConfig().apiUrls.gamifixURL}/api/v1/auth/sign_in`,
            data: {
                username: getAppConfig().gamifixCred.username,
                password: getAppConfig().gamifixCred.password,
            },
        })
            .then(async (response) => {
                let headers = {
                    "Content-Type": "application/json",
                    client: response.headers.client,
                    "access-token": response.headers["access-token"],
                    expiry: response.headers.expiry,
                    expires: response.headers.expires,
                    uid: response.headers.uid,
                    "token-type": "Bearer",
                };
                this.setState({ headers: headers })
                let options = {
                    method: 'GET',
                    url: `${getAppConfig().apiUrls.gamifixURL
                        }/api/v1/performances/${userId}/display/${previousDay}/show`,
                    headers: this.state.headers,
                };
                axios(options)
                    .then((response) => {
                        this.setState({ user: response.data });
                    });
                this.props.actions.dispatchPerformanceAccess().then(
                    () => this.props.actions.pushTrackerToServer()
                );
                let optionsrange = {
                    method: 'GET',
                    url: `${getAppConfig().apiUrls.gamifixURL
                        }/api/v1/performance/${userId}/fetchrange/?start=${fromDate}&end=${previousDay}`,
                    headers: this.state.headers,
                };
                axios(optionsrange)
                    .then((response) => {
                        this.setState({ historyData: response.data, aggrigateData: this.formatHistoryData(response.data) });
                    });
            })
    }
    handleShowDetails = () => {
        if (this.state.detailBtn === "HIDE DETAILS") {
            this.setState({ showdetails: false, detailBtn: "VIEW DETAILS" })
        }
        else {
            this.setState({ showdetails: true, detailBtn: "HIDE DETAILS" })
        }
    }
    handleRun = (fromDate, toDate) => {
        let userId = this.props.auth.user.username;
        let options = {
            method: 'GET',
            url: `${getAppConfig().apiUrls.gamifixURL}/api/v1/performance/${userId}/fetchrange/?start=${fromDate}&end=${toDate}`,
            headers: this.state.headers,
        };
        axios(options)
            .then((response) => {
                this.setState({ historyData: response.data, aggrigateData: this.formatHistoryData(response.data) })
            });
    }

    formatHistoryData = (data) => {
        return _.map(data.data, (d) => {
            return {
                "CXNS Score": d.score,
                "date": new Date(d.date).toLocaleDateString()
            }
        })
    }
    run = (data) => {
        var predate = new Date();
        let previousDay = predate.setDate(predate.getDate() - 2);
        var date = new Date();
        if (data === "weekly") {
            let fromDate = date.setDate(date.getDate() - 8);
            this.handleRun(fromDate, previousDay)
        }
        if (data === "half-month") {
            let fromDate = date.setDate(date.getDate() - 16);
            this.handleRun(fromDate, previousDay)
        }
        if (data === "monthly") {
            let fromDate = date.setDate(date.getDate() - 31);
            this.handleRun(fromDate, previousDay)
        }
    }
    render() {
        const isPortrait = window.innerHeight > window.innerWidth;
        let pageStyle = {
            container: {
                textAlign: "center",
                marginTop: "11%",
                overflowY: isPortrait ? "" : "scroll",
                height: isPortrait ? "auto" : "100vh"
            },
        };
        var date = new Date();
        let previousDay = date.setDate(date.getDate() - 2);
        let currentDate = new Date(previousDay);
        let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(currentDate);
        let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(currentDate);
        let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(currentDate);
        let formatDate = `${da} ${mo} ${ye}`;
        let scoreData = this.state.user ? this.state.user.score : 0;
        let score = scoreData * 100 / 20;
        let halfWidthStyle = {
            width: isPortrait ? "100%" : "70%",
            margin: "auto",
        }
        let qprofilelevelprogress = {
            top: "0",
            background: "#000",
            borderRadius: "17px 17px 0 0",
            color: "#fff",
            padding: "10px",
            width: isPortrait ? "70%" : "30%",
            margin: "17px auto 0"
        };
        let detailsBox = {
            ...halfWidthStyle,
            paddingBottom: "20px",
            background: "#F5F5F5",
            paddingTop: isPortrait ? "10px" : "26px",
            borderRadius: "10px",
        }
        let imgStyle = {
            height: isPortrait ? "13vh" : "14vh"
        }
        let levelStyle = {
            background: "#454545",
            color: "#FFFFFF",
            marginLeft: "-20px",
            padding: "9px 0",
            borderRadius: "10px",
            textTransform: "uppercase",
            width: "100px"
        };
        let dropbtn = {
            width: isPortrait ? "95%" : "67%",
            margin: "auto",
            background: this.state.showdetails ? "#E8E8E8" : getAppConfig().colors.accents.primary,
            color: this.state.showdetails ? "#000000" : getAppConfig().colors.accents.primaryText,
            padding: this.state.showdetails ? "18px" : "10px",
            borderRadius: "0 0 10px 10px"
        };
        let dropdown = {
            width: isPortrait ? "100%" : "67%"
        }
        let dropdownContainer = {
            background: "#E8E8E8",
            paddingBottom: "10px",
            width: isPortrait ? "95%" : "67%",
            margin: "auto"
        };
        let dropdownContent = {
            margin: "-10px auto 13px auto",
            background: "#fff",
            padding: "6px 12px",
            borderRadius: "6px",
            width: "92%",
            textAlign: "left"
        };
        let activeContent = {
            ...dropdownContent,
            background: "#BFD7ED"
        }
        let staticStyle = {
            ...halfWidthStyle,
            display: "flex",
            justifyContent: "center",
        };
        let staticCard = {
            width: isPortrait ? "150px" : "160px",
            height: "150px",
            background: "#D97575",
            borderRadius: "10px",
            margin: "10px 5px",
            padding: "10px 0",
            color: "#ffffff"
        };
        let scoreStyle = {
            fontSize: "2.2em",
            fontWeight: "bold"
        };
        let historySection = {
            ...halfWidthStyle,
            background: "#DDDDDD",
            paddingTop: "10px",
            paddingBottom: "15px"
        };
        let dividerStyle = {
            ...halfWidthStyle,
            margin: "1px auto",
            border: "1px solid gray"
        };
        let performanceStyle = {
            margin: isPortrait ? "5px 0 15px 0" : "5px 0"
        }

        let rankImage = scoreData === 0 ?
            "assets/images/Golden_cup.png" :
            (scoreData > 0 && scoreData <= 10) ?
                "assets/images/Silver_cup.png" :
                (scoreData > 10 && scoreData <= 20) ?
                    "assets/images/Bronze_cup.png" : "NA"
        return (
            <Segment style={pageStyle.container} >
                {
                    this.state.user !== null ?
                        <div>
                            <h3
                                style={qprofilelevelprogress}
                            >
                                Report {formatDate}
                            </h3>
                            <div style={detailsBox}>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    gap: scoreData >= 20 ? "50px" : isPortrait ? "" : "14px"
                                }}>
                                    {scoreData <= 20 ?
                                        <div>
                                            <QuoRadialProgressChart
                                                width={isPortrait ? 130 : 160}
                                                height={isPortrait ? 110 : 140}
                                                color={getAppConfig().colors.accents.primary}
                                                value={score}
                                                top={52}
                                                left={65}
                                            />
                                            <b>CXNS Score</b>
                                        </div>
                                        :
                                        <div style={{ marginTop: "20px", fontSize: "1.5em" }}>
                                            <span style={{ fontWeight: "bold" }}>CXNS Score
                                            </span>
                                            <br />
                                            {scoreData}
                                        </div>
                                    }

                                    <div>
                                        {rankImage !== "NA" && (<img src={rankImage} alt="trophy" style={imgStyle} />)}
                                        <div style={{ float: "right", marginTop: "5%" }}>
                                            <div style={{ marginLeft: "-50px" }}>RANK
                                            </div>
                                            <div style={levelStyle}>
                                                {
                                                    scoreData === 0 ?
                                                        "GOLD" :
                                                        (scoreData > 0 && scoreData <= 10) ?
                                                            "SILVER" :
                                                            (scoreData > 10 && scoreData <= 20) ?
                                                                "BRONZE" : "NA"
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.user &&
                                    <div style={{
                                        display: "flex",
                                        gap: "12px",
                                        justifyContent: "center",
                                        marginTop: "15px"
                                    }}>
                                        <span>0:Gold</span>
                                        <span>1-10: Silver</span>
                                        <span>11-20: Bronze</span>
                                        <span>{`>21: NA`}</span>
                                    </div>}

                            </div>

                            <div style={{ dropdown }}>
                                <div style={dropbtn} onClick={this.handleShowDetails}>
                                    {this.state.detailBtn}
                                    <Icon name={this.state.showdetails ? "chevron up" : "chevron down"}></Icon>
                                </div>
                                {this.state.showdetails && (
                                    <div style={dropdownContainer}>
                                        <div style={dropdownContent}>
                                            <span>Total Attempts </span>
                                            <span style={{ float: "right" }}>{this.state.user ? this.state.user.details.total_attempts : "-"}</span>
                                        </div>
                                        <div style={dropdownContent}>
                                            <span>Total Deliveries </span>
                                            <span style={{ float: "right" }}>{this.state.user ? this.state.user.details.total_deliveries : "-"}</span>
                                        </div>
                                        <div style={activeContent}>
                                            <span>Fake(%) </span>
                                            <span style={{ float: "right" }}>{this.state.user ? this.state.user.details.fake : "-"}</span>
                                        </div>
                                        <div style={activeContent}>
                                            <span>Total Negative Feedback Count </span>
                                            <span style={{ float: "right" }}>{this.state.user ? this.state.user.details.total_negative_feedback_count : "-"}</span>
                                        </div>
                                        <div style={dropdownContent}>
                                            <span>BAD count </span>
                                            <span style={{ float: "right" }}>{this.state.user ? this.state.user.details.bad_count : "-"}</span>
                                        </div>
                                        <div style={dropdownContent}>
                                            <span>UDBad count</span>
                                            <span style={{ float: "right" }}>{this.state.user ? this.state.user.details.udbad_count : "-"}</span>
                                        </div >
                                        <div style={dropdownContent}>
                                            <span>Escalations count</span>
                                            <span style={{ float: "right" }}>{this.state.user ? this.state.user.details.escalations_count : "-"}</span>
                                        </div>
                                        <div style={activeContent}>
                                            <span>CXNS Score</span>
                                            <span style={{ float: "right" }}>{this.state.user ? this.state.user.details.cxns_score : "-"}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div
                                style={{
                                    backgroundColor: "#e0e1e2",
                                    borderTopLeftRadius: "0.5em",
                                    borderTopRightRadius: "0.5em",
                                    width: isPortrait ? "100%" : "70%",
                                    margin: "auto",
                                    marginTop: isPortrait ? "2%" : "1%"
                                }}
                            >
                                <p
                                    style={{
                                        margin: "0",
                                        textAlign: "left",
                                        padding: "0.3em 0 0 1.8em",
                                        color: "#666666",
                                        fontSize: "0.8em",
                                    }}
                                >
                                    View History
                                </p>
                                <Dropdown
                                    button
                                    fluid
                                    value={this.state.selectValue}
                                    options={filterOptions}
                                    onChange={(e, data) => {
                                        this.setState({ selectValue: data.value });
                                        this.run(data.value);
                                    }}
                                />
                            </div>
                            <hr style={
                                dividerStyle
                            } />
                            {this.state.aggrigateData.length !== 0 ?
                                <div style={historySection}>
                                    <div>
                                        <ResponsiveContainer width="95%" height={300}>
                                            <BarChart width={730} height={250} data={this.state.aggrigateData}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="date" tick={false} />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend />
                                                <Bar dataKey="CXNS Score" fill="#8884d8" />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                    <div style={staticStyle}>
                                        <div style={staticCard}>
                                            <Icon name="sort amount down" size='huge' />
                                            <h3 style={performanceStyle}>LOWEST</h3>
                                            <div style={scoreStyle}>{this.state.historyData && this.state.historyData.highestScore ? this.state.historyData.highestScore : 0}</div>
                                        </div>
                                        <div style={staticCard}>
                                            <Icon name="hourglass half" size='huge' />
                                            <h3 style={performanceStyle}>AVERAGE</h3>
                                            <div style={scoreStyle}>
                                                {this.state.historyData && this.state.historyData.averageScore ? this.state.historyData.averageScore.toFixed(2) : 0}
                                            </div>
                                        </div>
                                        <div style={staticCard}>
                                            <Icon name="sort amount up" size='huge' />
                                            <h3 style={performanceStyle}>BEST</h3>
                                            <div style={scoreStyle}>
                                                {this.state.historyData && this.state.historyData.lowestScore ? this.state.historyData.lowestScore : 0}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <h2 style={{ marginTop: "58px", height: "100vh" }}>No Data Found</h2>
                            }
                        </div>
                        :
                        <h2 style={{ marginTop: "58px", height: "100vh" }}>No Data Found</h2>
                }
            </Segment>
        );
    }
}
