import _ from "lodash";
import { getAppConfig } from "config/client.config";

import {
    AGGREGATE_PROGRAM,
    AGGREGATE_SCORM,
    PUSH_SCORM_STATE,
    SET_LAST_LOCATION,
    SUBMIT_RATING,
    COURSE_RATED,
} from "appRedux/helpers/reduxConstants";

//-------------------------------------------------------
// Action Creators
//-------------------------------------------------------

function _aggregateScorm /* istanbul ignore next - action creator */(
    status,
    data,
    err
) {
    /* istanbul ignore next - action creator */
    return { type: AGGREGATE_SCORM, status, data, err };
}

function _aggregateProgram /* istanbul ignore next - action creator */(
    status,
    data,
    err
) {
    /* istanbul ignore next - action creator */
    return { type: AGGREGATE_PROGRAM, status, data, err };
}

function _pushScormState /* istanbul ignore next - action creator */(
    status,
    data,
    err
) {
    /* istanbul ignore next - action creator */
    return { type: PUSH_SCORM_STATE, status, data, err };
}

function _setLastLocation /* istanbul ignore next - action creator */(
    status,
    data,
    err
) {
    /* istanbul ignore next - action creator */
    return { type: SET_LAST_LOCATION, status, data, err };
}

function _submitRating /* istanbul ignore next - action creator */(
    status,
    data,
    err
) {
    /* istanbul ignore next - action creator */
    return { type: SUBMIT_RATING, status, data, err };
}
function _courseRated /* istanbul ignore next - action creator */(
    status,
    data,
    err
) {
    /* istanbul ignore next - action creator */
    return { type: COURSE_RATED, status, data, err };
}
//-------------------------------------------------------
// Action Definitions
//-------------------------------------------------------
function aggregateScorm /* istanbul ignore next - action dispatch function */(
    data,
    id
) {
    return (dispatch, getState) => {
        return Promise.resolve(
            dispatch(
                _aggregateScorm(
                    "Logging Aggregate Scorm",
                    {
                        courseId: id,
                        scormData: data,
                        timeSpent: data.timeSpent,
                        eventDate: Date(),
                    },
                    null
                )
            )
        ).then((response) => {
            let scormStates = [{ ...getState().courses[id] }];
            let programIds = getState().courses[id].programIds;
            return Promise.resolve(
                dispatch(
                    _aggregateProgram(
                        "Logging Aggregate Scorm Program",
                        {
                            programs: programIds,
                            courses: scormStates,
                            timeSpent: response.data.timeSpent,
                            eventDate: response.data.eventDate,
                        },
                        null
                    )
                )
            );
        });
    };
}

function pushScormState /* istanbul ignore next - action dispatch function */(
    id
) {
    return (dispatch, getState) => {
        let currentCourses = {};
        currentCourses[0] = getState().courses[id];
        let currentPrograms = _.filter(getState().programs, (program) => {
            return _.includes(
                currentCourses[0].programIds,
                parseInt(program.id, 0)
            );
        });
        return Promise.resolve(
            dispatch(
                _pushScormState(
                    "Logging Push State Scorm",
                    {
                        programs: _.map(currentPrograms, (program) => {
                            return Object.assign(
                                {},
                                _.pick(program, [
                                    "programId",
                                    "currentState",
                                    "history",
                                ]),
                                { userId: getState().auth.user.id }
                            );
                        }),
                        courses: _.map(currentCourses, (course) => {
                            return Object.assign(
                                {},
                                _.pick(course, [
                                    "courseId",
                                    "currentState",
                                    "history",
                                ]),
                                { userId: getState().auth.user.id }
                            );
                        }),
                    },
                    null
                )
            )
        );
    };
}
function getClientConfig(courseId) {
    return (dispatch, getState, Request) => {
        let clientConfigPath = getAppConfig().endpoints.getClientConfigPath.replace(
            ":id",
            courseId
        );
        return Request.fetch(clientConfigPath)
            .then((response) => {
                return response.json().then((data) => {
                    return data;
                });
            })
            .catch((err) => {
                console.log(err);
                return null;
            });
    };
}

function setLastLocation(courseId, locationIndex) {
    return (dispatch, getState, Request) => {
        return Promise.resolve(
            dispatch(
                _setLastLocation(
                    "setting last location",
                    {
                        courseId: courseId,
                        location: locationIndex,
                    },
                    null
                )
            )
        );
    };
}

function submitRating(courseId, rating) {
    return (dispatch, getState, Request) => {
        return Promise.resolve(
            dispatch(
                _submitRating(
                    "submitting rating",
                    {
                        courseId: courseId,
                        rating: rating,
                    },
                    null
                )
            )
        ).then(() => {
            Promise.resolve(
                dispatch(
                    _courseRated(
                        "pushing course rating",
                        {
                            courseId: courseId,
                            rating: rating,
                            eventDate: new Date(),
                        },
                        null
                    )
                )
            );
        });
    };
}
function downloadCourse(courseId) {
    return (dispatch, getState, Request) => {
        let downloadCoursePath = getAppConfig().endpoints.downloadCoursePath.replace(
            ":id",
            courseId
        );
        return Request.fetch(downloadCoursePath)
            .then((response) => {
                return response.json().then((data) => {
                    return data;
                });
            })
            .catch((err) => {
                console.log(err);
                return null;
            });
    };
}
function getLeaderboard(courseId) {
    return (dispatch, getState, Request) => {
        let getLeaderboardPath = getAppConfig().endpoints.contentPaths.getCourseLeaderboardPath.replace(
            ":id",
            courseId
        );
        return Request.fetch(getLeaderboardPath)
            .then((response) => {
                return response.json().then((data) => {
                    return data;
                });
            })
            .catch((err) => {
                console.log(err);
                return null;
            });
    };
}
class CoursesAction {
    static aggregateScorm = aggregateScorm;
    static pushScormState = pushScormState;
    static getClientConfig = getClientConfig;
    static setLastLocation = setLastLocation;
    static submitRating = submitRating;
    static downloadCourse = downloadCourse;
    static getLeaderboard = getLeaderboard;
}

export { CoursesAction };
