// Import from NPM
// -------------------------------------
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import { Icon } from "semantic-ui-react";

// Import from Config
// -------------------------------------
import { getAppConfig } from "config/client.config";

export default class BurgerMenuItem extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        link: PropTypes.string,
        active: PropTypes.bool,
    };

    static defaultProps = {
        name: "Default Link",
        link: "#",
        active: false,
    };

    render() {
        let menuItemStyle = {
            display: "block",
            background: "#efefef",
            padding: "5px 10px",
            fontSize: "0.9em",
            border: "1px solid #cccccc",
            color: "#000000",
            cursor: "pointer",
            pointerEvents: "auto",
            opacity: 1,
        };

        if (this.props.active) {
            menuItemStyle.background = getAppConfig().colors.primary;
            menuItemStyle.color = getAppConfig().colors.primaryText;
            menuItemStyle.cursor = "not-allowed";
            menuItemStyle.pointerEvents = "none";
        }
        if (this.props.link.indexOf("mailto:") === -1) {
            return (
                <Link to={this.props.link} style={menuItemStyle}>
                    <Icon name={"bullseye"} style={{ marginRight: "15px" }} />
                    {this.props.name}
                </Link>
            );
        }
        else
            return (
                <div
                    style={menuItemStyle}
                    onClick={() => window.open(this.props.link, '_blank')}
                >
                    <Icon name={"bullseye"}
                        style={{ marginRight: "15px" }}
                    />
                    {this.props.name}
                </div>
            );
    }
}
