import { REHYDRATE } from "redux-persist/constants";
import {
    FETCH_ARTICLE,
    UPDATE_ARTICLE_EXTRAS,
    UPDATE_ARTICLE_VIEW,
    START_ARTICLE,
} from "appRedux/helpers/reduxConstants";
import {
    initAttempt,
    updateAttempt,
} from "appRedux/helpers/aggregatorFunctions";

const initialArticleAttemptState = {};

const ArticleAttemptReducer = (state = initialArticleAttemptState, action) => {
    switch (action.type) {
        /* istanbul ignore next - react-persist function */
        case REHYDRATE:
            let articleAttempt = action.payload.articleAttempt;
            if (articleAttempt) {
                return { ...state, ...articleAttempt };
            } else {
                return state;
            }
        case START_ARTICLE:
        case FETCH_ARTICLE:
            if (action.status === "fetching") {
                return { ...state, fetchCompleted: false, error: null };
            } else if (action.status === "success") {
                return initAttempt(action.data);
            } else {
                return { ...state, error: action.err };
            }
        case UPDATE_ARTICLE_VIEW:
            return Object.assign({}, state, updateAttempt(state, action.data));
        case UPDATE_ARTICLE_EXTRAS:
            if (action.data.comments)
                return Object.assign({}, state, {
                    comments: action.data.comments,
                });
            else if (action.data.useful)
                return Object.assign({}, state, {
                    likes: action.data.useful.likes,
                    dislikes: action.data.useful.dislikes,
                });
            else return state;
        default:
            return state;
    }
};

export { ArticleAttemptReducer };
