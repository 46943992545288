import React from "react";
import PropTypes from "prop-types";
import { Grid, Header } from "semantic-ui-react";
import { Button } from "config/initializers/styles.app";
import _ from "lodash";
import Carousel from "mobile/appComponents/carousel/Carousel.react";

import { getAppConfig } from "config/client.config";
import { resolveImage } from "config/app.helpers";
import { Dic } from "config/initializers/config.translations";

export default class ImageWithCaption extends React.Component {
    static propTypes = {
        data: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            iconlist: PropTypes.array,
            options: PropTypes.array,
            question: PropTypes.string,
        }).isRequired,
        colors: PropTypes.shape({
            baseText: PropTypes.string,
            primary: PropTypes.string,
        }).isRequired,
        selectedIndex: PropTypes.number.isRequired,
        template: PropTypes.string.isRequired,
        imageLibrary: PropTypes.array.isRequired,
        closeOverlay: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            correctIndex: this.props.selectedIndex,
        };
    }

    toggleCaption = (index) => {
        this.setState(_.merge(this.state, { correctIndex: index }));
    };

    getExtractKeys = (template) => {
        let keys = ["", "", ""];
        switch (template) {
            case "diptych":
                keys = ["diptych", "image", "text", "images"];
                break;
            case "triptych":
                keys = ["triptych", "image", "text", "images"];
                break;
            case "grid":
                keys = ["grid", "image", "text", "images"];
                break;
            case "popuplist":
                keys = ["popupitems", "image", "caption", "options"];
                break;
            default:
                keys = ["", "", ""];
                break;
        }
        return keys;
    };

    render() {
        const dic = Object.assign(
            {},
            Dic[window.lang].common,
            Dic[window.lang].deck,
            Dic[window.lang].overlay
        );
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let defaultStyle = {
            width: isPortrait ? "100%" : "90%",
            height: "100%",
            margin: isPortrait ? "0 auto" : "30px auto",
            paddingTop: "0px",
            textAlign: "center",
        };
        let carouselWrapperStyle = {
            width: "100%",
            height: 0,
            paddingBottom: "75%",
            overflow: "hidden",
            textAlign: "center",
        };
        let captionWrapperStyle = {
            width: "80%",
            margin: "20px auto",
        };
        let textStyle = {
            ...getAppConfig().textStyle,
        };
        let buttonStyle = {
            margin: "0 auto",
            // display:
            //     ["diptych", "triptych", "grid", "popuplist"].indexOf(
            //         this.props.template
            //     ) !== -1
            //         ? "none"
            //         : "block",
        };

        let extractKeys = this.getExtractKeys(this.props.template);

        let images = [],
            captions = [];
        let itemCollection =
            this.props.data[extractKeys[0]] !== undefined
                ? this.props.data[extractKeys[0]]
                : this.props.data[extractKeys[3]];
        _.forEach(itemCollection, (obj) => {
            images.push(
                resolveImage(obj[extractKeys[1]].id, this.props.imageLibrary)
            );
            captions.push(obj[extractKeys[2]]);
        });
        let getCaptionList = _.map(captions, (caption, index) => {
            let visible = this.state.correctIndex === index ? "" : "hide";
            return (
                <Header
                    key={extractKeys[0] + "_" + index}
                    className={visible}
                    style={textStyle}
                >
                    {caption}
                </Header>
            );
        });
        return isPortrait ? (
            <div style={defaultStyle}>
                <div style={carouselWrapperStyle}>
                    <Carousel
                        carouselItems={images}
                        autoPlay={false}
                        arrows={true}
                        infinite={false}
                        dots={true}
                        asNavFor={".textCarousel"}
                        initialSlide={this.state.correctIndex}
                        callback={this.toggleCaption}
                        imageStyle={true}
                    />
                </div>
                <div style={captionWrapperStyle}>
                    {getCaptionList}
                    <Button
                        style={buttonStyle}
                        onClick={this.props.closeOverlay}
                    >
                        {dic.close}
                    </Button>
                </div>
            </div>
        ) : (
            <div style={defaultStyle}>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <div style={carouselWrapperStyle}>
                                <Carousel
                                    carouselItems={images}
                                    autoPlay={false}
                                    arrows={true}
                                    infinite={false}
                                    dots={true}
                                    asNavFor={".textCarousel"}
                                    initialSlide={this.state.correctIndex}
                                    callback={this.toggleCaption}
                                    imageStyle={true}
                                />
                            </div>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <div style={captionWrapperStyle}>
                                {getCaptionList}
                                <Button
                                    style={buttonStyle}
                                    onClick={this.props.closeOverlay}
                                >
                                    {dic.close}
                                </Button>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}
