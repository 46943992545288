import React from "react";
import VideoBox from "mobile/appComponents/videobox/VideoBox.react";
import Text from "../../contentBlocks/Text.react";
import SlideHeader from "../../contentBlocks/SlideHeader.react";
import PropTypes from "prop-types";
import SlideHeaderSidebar from "../../contentBlocks/SlideHeaderSidebar.react";
import { Grid } from "semantic-ui-react";
import { ComponentInputs } from "schema/FormSchema.react";

export default class VideoCaption extends React.Component {
    static propTypes = {
        data: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            video: PropTypes.string,
            paragraph: PropTypes.string,
        }).isRequired,
        colors: PropTypes.shape({}).isRequired,
        slideId: PropTypes.number,
        mobileView: PropTypes.bool,
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let videoWrapperStyle = {
            height: "0",
            width: "100%",
            paddingBottom: "56.25%",
            position: "relative",
        };
        let videoStyle = {
            height: "100%",
            width: "100%",
            position: "absolute",
            top: "0",
            left: "0",
        };

        let defaultLandscapeStyle = {
            margin: "auto",
            width: "100%",
            textAlign: "center",
            //height: "0",
            paddingBottom: isPortrait ? "56.25%" : "auto",
            position: "relative",
            float: "right",
        };
        let hasPresenter =
            this.props.data.presenter !== undefined &&
            this.props.data.presenter !== "default43";
        let speechBubbleStyle = {
            position: "absolute",
            width: "100%",
            top: "-15px",
            left: "0",
            zIndex: "0",
            transform:
                hasPresenter && this.props.data.title === ""
                    ? "scaleY(-1)"
                    : "",
        };
        let paragraphStyle = {
            position: "relative",
            margin: isPortrait ? (hasPresenter ? "0" : "0px auto") : "0 30px",
            width: isPortrait
                ? hasPresenter
                    ? "55%"
                    : "90%"
                : !isPortrait && !hasPresenter
                ? "90%"
                : "100%",
            textAlign: isPortrait && !hasPresenter ? "center" : "left",
            height: isPortrait ? "auto" : "100%",
            minHeight: isPortrait ? "0" : "190px",
            padding: isPortrait
                ? hasPresenter
                    ? "15px"
                    : "15px 0"
                : this.props.desktopView
                ? "10px 0"
                : "40px 0",
            fontSize:
                this.props.data.paragraph.length > 128
                    ? "1em"
                    : "1.5em",
        };
        let title =
            this.props.data.header !== undefined
                ? this.props.data.header
                : this.props.data.title;
        let subtitle =
            this.props.data.subheader !== undefined
                ? this.props.data.subheader
                : this.props.data.subtitle;
        return (
            <div className="full-height">
                {isPortrait ? (
                    <div>
                        <div style={videoWrapperStyle}>
                            <div style={videoStyle}>
                                <VideoBox
                                    url={this.props.data.video}
                                    autoplay={true}
                                    showFullScreenBtn={true}
                                />
                            </div>
                        </div>
                        <SlideHeader
                            {...this.props}
                            title={title}
                            subtitle={subtitle}
                            largePresenter={this.props.data.title === ""}
                        />
                        <div style={paragraphStyle}>
                            {hasPresenter &&
                                this.props.data.paragraph &&
                                this.props.data.paragraph.length <=
                                    ComponentInputs.paragraph.idealLength && (
                                    <img
                                        src="assets/images/speech_bubble2.png"
                                        alt="Speech bubble"
                                        style={speechBubbleStyle}
                                    />
                                )}
                            <Text
                                keyName={"paragraph-" + this.props.slideId}
                                text={this.props.data.paragraph}
                                colors={this.props.colors}
                                animation="transition.bounceUpIn,400,750"
                            />
                        </div>
                    </div>
                ) : (
                    <Grid style={{ minHeight: "100%" }}>
                        <Grid.Row style={{ paddingBottom: "0" }}>
                            <Grid.Column
                                width={8}
                                style={{
                                    paddingRight: "0",
                                    background: this.props.colors.primary,
                                }}
                            >
                                <SlideHeaderSidebar
                                    {...this.props}
                                    title={title}
                                    subtitle={subtitle}
                                />
                            </Grid.Column>
                            <Grid.Column width={8} style={{ paddingLeft: "0" }}>
                                <div style={defaultLandscapeStyle}>
                                    <div style={videoWrapperStyle}>
                                        <div style={videoStyle}>
                                            <VideoBox
                                                url={this.props.data.video}
                                                autoplay={true}
                                                showFullScreenBtn={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column
                                            width={hasPresenter ? 7 : 16}
                                        >
                                            <div style={paragraphStyle}>
                                                <Text
                                                    keyName={
                                                        "paragraph-" +
                                                        this.props.slideId
                                                    }
                                                    text={
                                                        this.props.data
                                                            .paragraph
                                                    }
                                                    colors={this.props.colors}
                                                    animation="transition.bounceUpIn,400,750"
                                                />
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
            </div>
        );
    }
}
