import _ from "lodash";

export const aggregateCompleteHistory = (history, action) => {
    let newHistory = _.cloneDeep(history);
    let dateObj = new Date(action.data.eventDate);

    // Select the appropriate History keys to update
    //-----------------------------------------------
    let month = dateObj.getMonth() + 1; //months from 1-12
    let quarter = Math.ceil(month / 4);
    let year = dateObj.getFullYear();
    /* istanbul ignore next - Simple branch, no need to test */
    // let finYear = quarter < getAppConfig().fyStartQtr ? year : year + 1;
    let finYear = quarter < 2 ? year : year + 1;
    //-----------------------------------------------

    if (newHistory.finYearEnding === undefined) {
        newHistory.finYearEnding = {};
    }
    if (newHistory.year === undefined) {
        newHistory.year = {};
    }
    if (newHistory.quarter === undefined) {
        newHistory.quarter = {};
    }
    if (newHistory.month === undefined) {
        newHistory.month = {};
    }
    newHistory.finYearEnding[finYear] = aggregateUnitHistory(
        newHistory.finYearEnding[finYear],
        action.data
    );
    newHistory.year[year] = aggregateUnitHistory(
        newHistory.year[year],
        action.data
    );
    newHistory.quarter[year + "Q" + quarter] = aggregateUnitHistory(
        newHistory.quarter[year + "Q" + quarter],
        action.data
    );
    newHistory.month[year + "M" + month] = aggregateUnitHistory(
        newHistory.month[year + "M" + month],
        action.data
    );

    return newHistory;
};

export const aggregateUnitHistory = (unitHistory, data) => {
    return {
        viewAttempts:
            unitHistory === undefined ? 1 : unitHistory.viewAttempts + 1,
        timeSpent:
            unitHistory === undefined
                ? data.timeSpent
                : unitHistory.timeSpent + data.timeSpent
    };
};



export const course_aggregation = (state, action) => {
    //TODO: Update on Topic addition and deletion
    let newState = Object.assign({}, state);
    _.forEach(action.data.courses, function(courseId) {
        // Get the appropriate course
        let thisCourse = Object.assign({}, state[courseId]);
        // Update the topic in the topic state
        // currentTopicsCompletion a list (object) of topic completion for current topics
        let currentTopicsCompletion = {};
        let blankTopicHash = {};
        _.mapValues(
            _.filter(thisCourse.locations, location => {
                return location.contentList.length === 0;
            }),
            topic => {
                blankTopicHash[topic.topicId] = true;
            }
        );
        _.mapValues(action.data.topics, topic => {
            currentTopicsCompletion[topic.id] = topic.currentState.completion;
        });
        // courseTopicsCompletion a list (object) of topic completion for all topics
        let courseTopicsCompletion = _.cloneDeep(
            thisCourse.currentState.topicsState
        );
        // check to find whether the course is complete or not
        let isCourseComplete = !_.includes(courseTopicsCompletion, false);
        let isCurrentTopicComplete = !_.includes(currentTopicsCompletion, true);
        /* if the course is not complete, 
     merge the currentTopicsCompletion with courseTopicsCompletion
     else keep the topics Completion as it is 
     */
        let newCourseTopicsCompletion = isCourseComplete
            ? courseTopicsCompletion
            : Object.assign(
                  {},
                  courseTopicsCompletion,
                  blankTopicHash,
                  currentTopicsCompletion
              );

        /* if the user passes the final exam 
      then all the topics are marked as complete */
        let allTopicsState = _.mapValues(
            newCourseTopicsCompletion,
            topicState => {
                return action.data.isFinalExam
                    ? isCurrentTopicComplete
                        ? topicState
                        : true
                    : topicState;
            }
        );

        let newTopicsState = _.values(allTopicsState);
        let completedTopicCount = _.filter(newTopicsState, topic => {
            return topic === true;
        }).length;

      
        /* if the course is complete then the completion remains same 
      else the completion depends on either completion of final exam
      or completion of all the topics inside the course */
        
      let completion = 
      thisCourse.currentState.completion ? true
            : (isCourseComplete ? thisCourse.currentState.completion
                : (action.data.isFinalExam ? action.data.completion
                    : completedTopicCount === newTopicsState.length));


        /* if the course is complete then the viewed percentage remains same 
      else the viewed percentage depends on either viewed percentage of final exam
      or percentage of completed topics */
        let allDecksViewedPercentage =
            newTopicsState.length > 0
                ? Math.min(
                      Math.round(
                          (completedTopicCount * 100) / newTopicsState.length
                      ),
                      100
                  )
                : 0;
        /* if the course is complete then the score remains same 
      else the score depends on either score of final exam
      or percentage of completed topics */
        let score =
            action.data.isPreAssessment || action.data.isFinalExam
                ? Math.max(action.data.score, thisCourse.currentState.score)
                : Math.max(0, thisCourse.currentState.score);

        // last location of the course --- Commented out because do not want the forward navigation as it can't be handled with empty topics
        // thisCourse.lastLocation =
        //     thisCourse.locations.length === 1 ||
        //     thisCourse.lastLocation === thisCourse.locations.length - 1
        //         ? thisCourse.lastLocation
        //         : allTopicsState[
        //               thisCourse.locations[thisCourse.lastLocation].topicId
        //           ]
        //         ? thisCourse.lastLocation + 1
        //         : thisCourse.lastLocation;

        // update the current state of the course
        thisCourse.currentState = {
            score: score,
            points:
                thisCourse.currentState.points === undefined
                    ? action.data.points
                    : thisCourse.currentState.points + action.data.points,
            completion: completion,
            viewAttempts: thisCourse.currentState.viewAttempts + 1,
            timeSpent:
                thisCourse.currentState.timeSpent + action.data.timeSpent,
            viewedPercentage: allDecksViewedPercentage,
            completionDate: completion
                ? action.data.eventDate
                : thisCourse.currentState.completionDate,
            topicsState: allTopicsState,
            lastLocation: thisCourse.lastLocation
        };
        // Update History
        thisCourse.history = aggregateCompleteHistory(
            thisCourse.history,
            action
        );
        newState[courseId] = thisCourse;
    });
    return Object.assign({}, state, newState);
};

export const scorm_aggregation = (state, action) => {
    let scormState = Object.assign({}, state);
    let courseId = action.data.courseId;
    let thisCourse = scormState[courseId];
    let completion =
        action.data.scormData.completion ||
        action.data.scormData.completion === 100
            ? true
            : false;
    let completionDate = action.data.scormData.completionDate
        ? action.data.scormData.completionDate
        : action.data.scormData.updated_at
        ? action.data.scormData.updated_at
        : action.data.eventDate;
    thisCourse.currentState = {
        score: action.data.scormData.score ? action.data.scormData.score : null,
        completion: completion,
        viewAttempts: thisCourse.currentState.viewAttempts + 1,
        timeSpent:
            thisCourse.currentState.timeSpent + action.data.scormData.timeSpent,
        viewedPercentage: action.data.scormData.completion,
        completionDate: completion
            ? completionDate
            : thisCourse.currentState.completionDate
    };
    // Update History
    thisCourse.history = aggregateCompleteHistory(thisCourse.history, action);
    scormState[courseId] = thisCourse;
    return Object.assign({}, state, scormState);
};

export const program_aggregation = (state, action) => {
    let newState = Object.assign({}, state);
    _.forEach(action.data.programs, function(programId) {
        // Get the appropriate topic
        let thisProgram = Object.assign({}, state[programId]);
        // Update the deck in the deck state
        // Creating a list (object) of topic completion for all modified topics
        let currentCoursesCompletion = {};
        _.mapValues(action.data.courses, course => {
            currentCoursesCompletion[course.id] =
                course.currentState.completion;
        });
        // Find the topic in the topic state and update the completion status
        let programCoursesCompletion = Object.assign(
            {},
            thisProgram.currentState.coursesState
        );
        let newProgramCoursesCompletion = Object.assign(
            {},
            programCoursesCompletion,
            currentCoursesCompletion
        );

        let newCoursesState = _.values(newProgramCoursesCompletion);
        let completedCoursesCount = _.filter(newCoursesState, course => {
            return course === true;
        }).length;

        let completion = completedCoursesCount === newCoursesState.length;

        let viewedPercentage =
            newCoursesState.length > 0
                ? Math.min(
                      Math.round(
                          (completedCoursesCount * 100) / newCoursesState.length
                      ),
                      100
                  )
                : 0;

        // Calculate overall state
        thisProgram.currentState = {
            score: viewedPercentage,
            completion: completion,
            viewAttempts: thisProgram.currentState.viewAttempts + 1,
            timeSpent:
                thisProgram.currentState.timeSpent + action.data.timeSpent,
            viewedPercentage: viewedPercentage,
            completionDate: completion
                ? action.data.eventDate
                : thisProgram.currentState.completionDate,
            coursesState: newProgramCoursesCompletion
        };
        // Update History
        thisProgram.history = aggregateCompleteHistory(
            thisProgram.history,
            action
        );
        newState[programId] = thisProgram;
    });
    return Object.assign({}, state, newState);
};
