import React from "react";
import PropTypes from "prop-types";
import Truncate from "react-truncate";
import { Icon } from "semantic-ui-react";
import { hashHistory } from "react-router";

class NotificationItem extends React.Component {
    static propTypes = {
        item: PropTypes.object.isRequired,
    };

    handleClickEvent = () => {
        if (this.props.item.link !== undefined && this.props.item.link !== "") {
            hashHistory.push(this.props.item.link);
            if (this.props.openModal) this.props.toggleNotification();
        }
    };

    render() {
        let feedRowStyle = {
            base: {
                background: this.props.item.read ? "#efefef" : "#cccccc",
                opacity: "0.9",
                marginBottom: "5px",
                borderRadius: "10px",
                color: "#000000",
                padding: "15px",
            },
            header: {
                fontSize: "1.1em",
                fontWeight: "bold",
                marginBottom: "3px",
            },
            date: {
                fontSize: "0.8em",
            },
            description: {
                marginTop: "10px",
            },
        };

        const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
        };
        let icon = "info";
        switch (this.props.item.type) {
            case "BROADCAST_MESSAGE":
                icon = "comment alternate";
                break;
            default:
        }
        return (
            <div style={feedRowStyle.base}>
                <div style={feedRowStyle.header}>
                    <Icon name={icon} />
                    <Truncate ellipsis="...">{this.props.item.title}</Truncate>
                </div>
                <div style={feedRowStyle.date}>
                    {new Date(
                        this.props.item.created_at
                            ? this.props.item.created_at
                            : this.props.item.createdAt
                    ).toLocaleDateString(undefined, options)}
                </div>
                <div style={feedRowStyle.description}>
                    {this.props.item.description}
                </div>
            </div>
        );
    }
}

export default NotificationItem;
