import _ from "lodash";
import { REHYDRATE } from "redux-persist/constants";
import {
    FETCH_DECK_OFFLINE,
    FETCH_ASSESSMENT_OFFLINE,
    DOWNLOAD_COURSE,
    DELETE_COURSE,
    FETCH_COURSE
} from "appRedux/helpers/reduxConstants";

const initialOfflineState = {
    error: null,
    courseList: [],
    currentCourse: {},
    currentDeck: {},
    courseDefault: {
        id: null, // Should be of type STRING if present
        downloadedAt: null,
        programId: null, // Should be of type STRING if present
        fetchCompleted: false,
        deleted: false,
        downloadedPercentage: 0,
        name: "",
        description: "",
        image: ""
    }
};

const OfflineReducer = (state = initialOfflineState, action) => {
    switch (action.type) {
        case REHYDRATE:
            let offline = action.payload.offline;
            if (offline) {
                return { ...state, ...offline };
            } else {
                return state;
            }
        case DOWNLOAD_COURSE:
            if (action.status === "fetching") {
                let courseData = {
                    ...action.data,
                    fetchCompleted: false,
                    error: null
                };
                return {
                    ...state,
                    courseList: [...state.courseList, courseData]
                };
            } else if (action.status === "progress") {
                let courseData = _.chain(state.courseList)
                    .pullAt(
                        _.findIndex(state.courseList, function(course) {
                            if (course.id === action.data.id) return course;
                        })
                    )
                    .head()
                    .value();
                courseData = {
                    ...action.data,
                    fetchCompleted: false,
                    error: null
                };
                return {
                    ...state,
                    courseList: [...state.courseList, courseData]
                };
            } else if (action.status === "success") {
                let courseData = _.chain(state.courseList)
                    .pullAt(
                        _.findIndex(state.courseList, function(course) {
                            if (course.id === action.data.id) return course;
                        })
                    )
                    .head()
                    .value();
                courseData = {
                    ...action.data,
                    fetchCompleted: true,
                    isDownloading: false,
                    downloadedPercentage: 100,
                    downloadedAt: new Date(),
                    error: null
                };
                return {
                    ...state,
                    courseList: [...state.courseList, courseData],
                    error: null
                };
            } else {
                _.chain(state.courseList)
                    .pullAt(
                        _.findIndex(state.courseList, function(course) {
                            if (course.id === action.data.id) return course;
                        })
                    )
                    .head()
                    .value();
                return { ...state, error: action.err };
            }
        case DELETE_COURSE:
            if (action.status === "deleting") {
                return state;
            } else if (action.status === "success") {
                let courseData = _.remove(state.courseList, function(course) {
                    if (course.id === action.data.id) return course;
                })
                    .head()
                    .value();
                courseData = {
                    ...action.data,
                    fetchCompleted: false,
                    downloadedPercentage: 0,
                    downloadedAt: null,
                    error: null
                };
                return {
                    ...state,
                    courseList: [...state.courseList, courseData],
                    error: null
                };
            } else if (action.status === "error") {
                _.chain(state.courseList)
                    .pullAt(
                        _.findIndex(state.courseList, function(course) {
                            if (course.id === action.data.id) return course;
                        })
                    )
                    .head()
                    .value();
                return { ...state, error: action.err };
            } else {
                _.chain(state.courseList)
                    .pullAt(
                        _.findIndex(state.courseList, function(course) {
                            if (course.id === action.data.id) return course;
                        })
                    )
                    .head()
                    .value();
                return { ...state, error: action.err };
            }
        case FETCH_COURSE:
            if (action.status === "fetching") {
                return { ...state, fetchCompleted: false, error: null };
            } else if (action.status === "success") {
                let coursesState = Object.assign({}, state);
                coursesState.currentCourse = action.data;
                return _.merge({}, coursesState, {
                    fetchCompleted: true,
                    error: null
                });
            } else {
                return { ...state, error: action.err, fetchCompleted: true };
            }
        case FETCH_DECK_OFFLINE:
            if (action.status === "fetching") {
                return { ...state, fetchCompleted: false, error: null };
            } else if (action.status === "success") {
                let decksState = Object.assign({}, state);
                decksState.currentDeck = action.data;
                return _.merge({}, decksState, {
                    fetchCompleted: true,
                    error: null
                });
            } else {
                return { ...state, error: action.err, fetchCompleted: true };
            }
        case FETCH_ASSESSMENT_OFFLINE:
            if (action.status === "fetching") {
                return { ...state, fetchCompleted: false, error: null };
            } else if (action.status === "success") {
                let assessmentState = Object.assign({}, state);
                assessmentState.currentDeck = action.data;
                return _.merge({}, assessmentState, {
                    fetchCompleted: true,
                    error: null
                });
            } else {
                return { ...state, error: action.err, fetchCompleted: true };
            }
        default:
            return state;
    }
};

export { OfflineReducer };
