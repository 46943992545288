import React from "react";
import PropTypes from "prop-types";
import Text from "../../contentBlocks/Text.react";
import SlideHeader from "../../contentBlocks/SlideHeader.react";
import { Grid } from "semantic-ui-react";
import { resolveImage } from "config/app.helpers";
import { ComponentInputs } from "schema/FormSchema.react";

export default class Blurb extends React.Component {
    static propTypes = {
        colors: PropTypes.shape({
            baseText: PropTypes.string,
        }).isRequired,
        data: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            blurb: PropTypes.string,
        }).isRequired,
        slideId: PropTypes.number,
        textAlign: PropTypes.string,
        mobileView: PropTypes.bool,
    };

    static defaultProps = {
        mobileView: false,
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let hasPresenter =
            this.props.data.presenter !== undefined &&
            this.props.data.presenter !== "default43";
        let defaultStyle = {
            position: "relative",
            margin: isPortrait
                ? hasPresenter
                    ? "auto"
                    : "30px auto"
                : hasPresenter
                ? "5%"
                : "30px auto",
            width:
                isPortrait && !hasPresenter
                    ? "80%"
                    : isPortrait && hasPresenter
                    ? "90%"
                    : !isPortrait && !hasPresenter
                    ? "90%"
                    : "100%",
            textAlign: isPortrait ? "center" : "left",
            padding: isPortrait
                ? hasPresenter
                    ? "35px 25px"
                    : "20px 0"
                : this.props.desktopView
                ? "10px 0"
                : "30px 0",
            minHeight: isPortrait ? "0" : "300px",
        };
        let speechBubbleStyle = {
            position: "absolute",
            width: this.props.data.blurb === "" ? "0" : "100%",
            top:
                window.innerHeight > 700 ||
                window.innerWidth < window.innerHeight
                    ? "-15px"
                    : "0",
            left: "0",
            zIndex: "0",
        };

        return (
            <div className="full-height">
                {isPortrait ? (
                    <div>
                        <SlideHeader
                            {...this.props}
                            title={this.props.data.title}
                            subtitle={this.props.data.subtitle}
                            largePresenter={this.props.data.title === ""}
                        />
                        <div style={defaultStyle}>
                            {hasPresenter && (
                                <img
                                    src="assets/images/speech_bubble.png"
                                    alt="Speech bubble"
                                    style={speechBubbleStyle}
                                />
                            )}
                            <br />
                            <Text
                                keyName={"blurb-" + this.props.slideId}
                                text={this.props.data.blurb}
                                color={this.props.colors.baseText}
                                animation="transition.slideDownIn,1000,750"
                                fontSize={
                                    this.props.data.blurb &&
                                    this.props.data.blurb.length >
                                        ComponentInputs.blurb.idealLength
                                        ? "normal"
                                        : "large"
                                }
                            />
                        </div>
                    </div>
                ) : (
                    <Grid>
                        <Grid.Row>
                            <SlideHeader
                                {...this.props}
                                title={this.props.data.title}
                                subtitle={this.props.data.subtitle}
                            />
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={10}>
                                <div style={defaultStyle}>
                                    <Text
                                        keyName={"blurb-" + this.props.slideId}
                                        text={this.props.data.blurb}
                                        color={this.props.colors.baseText}
                                        animation="transition.slideDownIn,1000,750"
                                        fontSize={"large"}
                                    />
                                </div>
                            </Grid.Column>
                            <Grid.Column width={6}>
                                {hasPresenter && (
                                    <img
                                        src={resolveImage(
                                            this.props.data.presenter,
                                            this.props.imageLibrary
                                        )}
                                        alt="Presenter"
                                        style={{
                                            position: "absolute",
                                            left: "10%",
                                            bottom: "0",
                                            width: "90%",
                                            zIndex: "999",
                                        }}
                                    />
                                )}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
            </div>
        );
    }
}
