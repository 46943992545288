import React, { Component } from "react";
import { Icon } from "semantic-ui-react";
import { getAppConfig } from "config/client.config";

export class ListingMenuHeader extends Component {
    render() {
        const isPortrait = window.innerHeight > window.innerWidth;
        let title = {
            position: "relative",
            margin: isPortrait ? "5px 0" : "15px 0",
            fontSize: getAppConfig().inductor ? "1.1em" : ""
        };
        let circle = {
            position: "absolute",
            width: "2em",
            height: "2em",
            top: "0.25em",
            left: "0.5em",
            background: "#ffb82f",
            borderRadius: "50%",
        };

        let badgeStyle = {
            position: "absolute",
            top: "2px",
            right: "3px",
            padding: "3px 8px",
            borderRadius: "50%",
            fontWeight: "bold",
            background: getAppConfig().colors.accents.secondary,
            color: getAppConfig().colors.accents.secondaryText,
        };

        return (
            <h3 style={title}>
                <div style={circle} />
                <div
                    style={{
                        position: "relative",
                    }}
                >
                    <Icon
                        name={
                            this.props.icon == null
                                ? "columns"
                                : this.props.icon
                        }
                        size="big"
                    />{" "}
                    {this.props.title == null ||
                        this.props.title === "NULLS" ||
                        this.props.title.length < 3
                        ? "CONTENT CATEGORIES"
                        : this.props.title === "search"
                            ? "SEARCH RESULTS"
                            : this.props.title}

                    {this.props.format === "notifications" &&
                        <div
                            style={badgeStyle}
                        >
                            {this.props.notification ? this.props.notification.unreadNotification : this.props.unreadCount}
                        </div>
                    }
                </div>
            </h3>
        );
    }
}
