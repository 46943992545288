// Import from NPM
// -------------------------------------
import React from "react";
import { Icon, Image, Dropdown } from "semantic-ui-react";
import { hashHistory } from "react-router";
import { connect } from "react-redux";
import HelpDeskAction from "appRedux/HelpDesk.action.react";

// Import config
import { getAppConfig } from "config/client.config";
import { gradient } from "config/app.helpers";
import { Dic } from "config/initializers/config.translations";

// Import from component
// -------------------------------------
import ContactUsModal from "./ContactUsModal.react";

export class InductorNavbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openContactUs: false,
        };
    }
    goBackOneLevel = /* istanbul ignore next - react-router function */ () => {
        if (this.props.backFn == null) hashHistory.goBack();
        else this.props.backFn();
    };
    handleLogout = () => {
        hashHistory.push("/logout");
    };
    goToEdit = () => {
        hashHistory.push("/editProfile");
    };
    refreshPage = () => {
        hashHistory.push("/session/home");
    };
    openNotifications = () => {
        hashHistory.push("/notifications");
    };

    handleContactsButtonClick = () =>
        this.setState({ openContactUs: !this.state.openContactUs });

    render() {
        const dic = Object.assign(
            {},
            Dic[this.props.auth.user.lang].menu,
            Dic[this.props.auth.user.lang].common
        );
        let isIFrame = window.location !== window.parent.location;
        let profileSettings = getAppConfig().learningCenter.profileList
            ? getAppConfig().learningCenter.profileList
            : {};
        let showBackButton = isIFrame
            ? !window.location.hash.includes("courses")
            : !(
                  (window.location.hash.includes("courses") &&
                      this.props.auth.user.courses.length === 1) ||
                  (window.location.hash.includes("programs") &&
                      this.props.auth.user.courses.length > 1) ||
                  window.location.hash === "#/"
              );

        let courseId =
            window.location.href.indexOf("/courses/") !== -1
                ? isIFrame
                    ? window.location.href
                          .split("/courses/")[1]
                          .split("/")[0]
                          .split("?")[0]
                    : window.location.href.split("/courses/")[1].split("/")[0]
                : window.location.href.indexOf("/decks/") !== -1
                ? isIFrame
                    ? window.location.href
                          .split("/decks/")[1]
                          .split("/")[2]
                          .split("?")[0]
                    : window.location.href.split("/decks/")[1].split("/")[2]
                : null;

        let thisCourse =
            this.props.courses &&
            this.props.auth.user.courses.length > 1 &&
            courseId
                ? this.props.courses[courseId]
                : (this.props.courses &&
                      this.props.auth.user.courses.length === 1) ||
                  isIFrame
                ? this.props.courses[this.props.auth.user.courses[0]]
                : {};

        let barStyle = {
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "50px",
            background:
                thisCourse && thisCourse.colors
                    ? gradient(thisCourse.colors.primary, "to left")
                    : "#666666",
            zIndex: "1001",
        };
        let backBtnStyle = {
            position: "absolute",
            left: "5px",
            top: "15px",
            height: "40px",
            fontSize: "1.5em",
            background: "transparent",
            color:
                thisCourse && thisCourse.colors
                    ? thisCourse.colors.primaryText
                    : "#ffffff",
            cursor: "pointer",
        };
        let headerStyle = {
            position: "absolute",
            width: `calc(100% - ${showBackButton ? "100px" : "65px"})`,
            left: showBackButton ? "100px" : "65px",
            top: "7px",
            textTransform: "uppercase",
            fontSize: "1em",
            color:
                thisCourse && thisCourse.colors
                    ? thisCourse.colors.primaryText
                    : "#ffffff",
            padding: "10px 0",
            lineHeight: "1.2",
        };
        let brandStyle = {
            position: "absolute",
            background:
                thisCourse && thisCourse.logo && thisCourse.colors
                    ? thisCourse.colors.base
                    : "#4d4d4d",
            padding: thisCourse && thisCourse.logo ? "0" : "12px",
            left: showBackButton ? "35px" : "0",
            top: "0",
            width: "50px",
            height: "50px",
            borderRadius: "0 0 0 0",
        };
        let logoStyle = {
            height: thisCourse && thisCourse.logo ? "50px" : "30px",
            width: thisCourse && thisCourse.logo ? "50px" : "27px",
        };
        if (this.props.auth.user && this.props.auth.isUserLoggedIn) {
            return (
                <div style={barStyle}>
                    {showBackButton && (
                        <Icon
                            id="navbar-backBtn"
                            name="chevron left"
                            style={backBtnStyle}
                            onClick={this.goBackOneLevel}
                        />
                    )}
                    <div style={brandStyle}>
                        <a href="#/session/home">
                            <Image
                                src={
                                    thisCourse && thisCourse.logo
                                        ? thisCourse.logo
                                        : "assets/images/defaults/default-q.png"
                                }
                                alt="Brand Logo"
                                style={logoStyle}
                            />
                        </a>
                    </div>
                    <div style={headerStyle} className="app-header">
                        <div
                            style={{
                                fontSize: "1.5em",
                                fontFamily: "Oswald",
                                marginTop: "-5px",
                                float: "left",
                            }}
                        >
                            {thisCourse && thisCourse.name
                                ? thisCourse.name.length > 20
                                    ? thisCourse.name.slice(0, 18) + "..."
                                    : thisCourse.name
                                : ""}
                        </div>
                        {!isIFrame && (
                            <Dropdown
                                icon="ellipsis vertical"
                                direction="left"
                                style={{
                                    float: "right",
                                    marginRight: "10px",
                                }}
                            >
                                <Dropdown.Menu style={{ marginTop: "12px" }}>
                                    <Dropdown.Item
                                        icon={"pencil"}
                                        text={dic.editProfile}
                                        onClick={this.goToEdit}
                                    />
                                    <Dropdown.Item
                                        icon={"sync"}
                                        text={dic.resync}
                                        onClick={this.refreshPage}
                                    />
                                    <Dropdown.Item
                                        icon={"log out"}
                                        text={dic.logout}
                                        onClick={this.handleLogout}
                                    />
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                        {!isIFrame && (
                            <Icon
                                name={"bell"}
                                style={{
                                    float: "right",
                                    marginRight: "5px",
                                    fontSize: "1.2em",
                                    cursor: "pointer",
                                }}
                                onClick={this.openNotifications}
                            />
                        )}
                        {profileSettings.enableContactUs && (
                            <Icon
                                name={"address book"}
                                style={{
                                    float: "right",
                                    marginRight: profileSettings.logout
                                        ? "10px"
                                        : "15px",
                                }}
                                onClick={this.handleContactsButtonClick}
                            />
                        )}
                    </div>
                    {this.state.openContactUs && (
                        <ContactUsModal
                            openModal={this.state.openContactUs}
                            handleModalClose={this.handleContactsButtonClick}
                            sendContactRequst={
                                this.props.actions.sendContactRequst
                            }
                        />
                    )}
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        courses: state.courses,
        routing: state.routing,
        blog: state.blog,
        auth: state.auth,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            sendContactRequst: (
                name,
                phonenumber,
                workemail,
                organisation,
                designation,
                message
            ) => {
                return dispatch(
                    HelpDeskAction.sendContactRequst(
                        name,
                        phonenumber,
                        workemail,
                        organisation,
                        designation,
                        message
                    )
                );
            },
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(InductorNavbar);
