import React, { Component } from "react";
import _ from "lodash";
import { List } from "semantic-ui-react";

export class MenuTagList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allTags: this.props.tags,
            selectedTags: this.props.tags,
        };
    }

    componentDidUpdate() {
        if (this.state.allTags !== this.props.tags) {
            this.setState({
                allTags: this.props.tags,
                selectedTags: this.props.tags,
            });
        }
    }

    toggleTag = (clickedTag) => {
        let newTags = [];
        let selectedTags = _.cloneDeep(this.state.selectedTags);
        let thisTag = _.find(selectedTags, { _id: clickedTag._id });
        if (thisTag) {
            newTags = _.filter(
                selectedTags,
                (tag) => tag._id !== clickedTag._id
            );
        } else {
            selectedTags.push(clickedTag);
            newTags = selectedTags;
        }
        this.setState({ selectedTags: newTags });
        this.props.setSelectedTags(newTags);
    };

    toggleAll = () => {
        let selectedTags;
        if (this.state.selectedTags.length < this.state.allTags.length)
            selectedTags = this.state.allTags;
        else selectedTags = [];
        this.setState({ selectedTags: selectedTags });
        this.props.setSelectedTags(selectedTags);
    };

    render() {
        let listItem = {
            lineHeight: "1.5",
            color: "#666666",
        };
        let selectedTagIds = _.map(this.state.selectedTags, "_id");

        return (
            <List divided relaxed style={{ cursor: "pointer" }}>
                <List.Item
                    key={"alti-all"}
                    onClick={this.toggleAll}
                    style={{ borderBottom: "2px solid black" }}
                >
                    <List.Content
                        style={{
                            ...listItem,
                            color: "black",
                            fontWeight: "bold",
                        }}
                    >
                        <List.Icon
                            name={
                                this.state.selectedTags.length ===
                                this.state.allTags.length
                                    ? "check square"
                                    : "square outline"
                            }
                        />{" "}
                        All
                    </List.Content>
                </List.Item>
                {_.map(this.state.allTags, (tag, idx) => {
                    return (
                        <List.Item
                            key={`alti-${idx}`}
                            onClick={() => this.toggleTag(tag)}
                        >
                            <List.Content style={listItem}>
                                <List.Icon
                                    name={
                                        _.includes(selectedTagIds, tag._id)
                                            ? "check square"
                                            : "square outline"
                                    }
                                />
                                {tag.name}
                            </List.Content>
                        </List.Item>
                    );
                })}
            </List>
        );
    }
}
