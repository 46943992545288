import React from "react";
import PropTypes from "prop-types";
import { Image, List } from "semantic-ui-react";
import _ from "lodash";
import { VelocityTransitionGroup } from "velocity-react";

import { getAppConfig } from "config/client.config";
import { resolveImage } from "config/app.helpers";
import Text from "./Text.react";

export default class IconBullets extends React.Component {
    constructor(props) {
        super(props);
        this.state =
        /* istanbul ignore next - Request and Pure passthrough */
        {
            clickState: _.map(this.props.list, (elm) => {
                return false;
            }),
        };
    }
    static propTypes = {
        list: PropTypes.array,
        color: PropTypes.string,
        animation: PropTypes.string,
        imageLibrary: PropTypes.array,
        mobileView: PropTypes.bool,
    };

    static defaultProps = {
        color: "#000",
        animation: "fadeIn, 500",
        mobileView: false,
    };

    revealText = (index) => {
        let newState = this.state.clickState;
        newState[index] = true;
        this.setState({ clickState: newState });
    };

    render() {
        let listContainerStyle = {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            listStyleType: "none",
            marginBottom: "10px",
        };
        let listContentStyle = {
            width: "90%",
            listStyleType: "none",
        };
        let listImgStyle = {
            width: "5em",
            height: "5em",
        };

        let animation = this.props.animation.split(",");

        let getListItems = _.map(this.props.list, (item, index) => {
            let listTextStyle = {
                ...getAppConfig().textStyle,
                display:
                    this.state.clickState[index] === true ? "block" : "none",
                marginLeft: "15px",
            };
            return (
                <List.Item
                    key={"list-item-" + index}
                    style={listContainerStyle}
                >
                    <Image
                        avatar
                        src={resolveImage(item.image.id, this.props.imageLibrary)}
                        style={listImgStyle}
                        onClick={() => this.revealText(index)}
                    />
                    <List.Content style={listContentStyle}>
                        <List.Header style={listTextStyle}>
                            <Text
                                keyName="bullet-point"
                                text={item.text}
                                color={this.props.color}
                                animation="fadeIn,1000"
                                fontSize={"large"}
                            />
                        </List.Header>
                    </List.Content>
                </List.Item>
            );
        });

        return (
            <div>
                <VelocityTransitionGroup
                    enter={{
                        animation: animation[0],
                        duration: animation[1],
                        delay: animation[2],
                    }}
                    leave={{ animation: "fadeOut", duration: "0" }}
                    runOnMount
                >
                    <List style={{ textAlign: "left" }}>{getListItems}</List>
                </VelocityTransitionGroup>
            </div>
        );
    }
}
