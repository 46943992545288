import React, { Component } from "react";
import _ from "lodash";
import { Icon, Label, Divider } from "semantic-ui-react";
import { TagManager } from "../TagManager.react";

export class MenuTagTree extends Component {
    render() {
        let allArticles = this.props.articles;
        let allArticleTags = _.uniq(
            _.flatten(
                _.map(allArticles, (article) => _.map(article.tags, "name"))
            )
        );
        let selectedTags = _.map(this.props.tags, (t) => {
            return {
                _id: t._id,
                name: t.name,
                parent: t.parent != null ? t.parent._id : "0",
            };
        });

        let topTags = _.filter(selectedTags, { parent: "0" });

        let presentTags = _.union(
            topTags,
            _.filter(selectedTags, (t) => allArticleTags.indexOf(t.name) !== -1)
        );
        const dataSrc = _.union(
            [
                {
                    _id: "0",
                    name: "All tags...",
                    parent: null,
                },
            ],
            presentTags
        );
        const dataTree = treeify(dataSrc, null);
        const viableDataTree = _.filter(
            dataTree[0].children,
            (cat) => cat.children
        );
        return (
            <div>
                <TagManager
                    key={Math.random()}
                    allTags={viableDataTree}
                    path={this.props.location}
                />
                {this.props.location.query.tags && (
                    <div>
                        <Divider />
                        <div>
                            Filters:
                            <br />
                            {_.map(
                                this.props.location.query.tags.split(","),
                                (tag, idx) => {
                                    return (
                                        <Label
                                            key={`filtering-${idx}`}
                                            style={{
                                                margin: "3px",
                                            }}
                                        >
                                            {tag}
                                            <Icon
                                                name="delete"
                                                onClick={() =>
                                                    this.props.removeTagFilter(
                                                        tag
                                                    )
                                                }
                                            />
                                        </Label>
                                    );
                                }
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

function treeify(arr, parent) {
    var out = [];
    for (var i in arr) {
        if (arr[i].parent === parent) {
            var children = treeify(arr, arr[i]._id);
            if (children.length) {
                arr[i].children = children;
            }
            out.push(_.omit(arr[i], ["parent"]));
        }
    }
    return out;
}
