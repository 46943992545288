import React, { Component } from "react";
import _ from "lodash";
import { List } from "semantic-ui-react";
export class TrendingArticles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetchCompleted: false,
            error: false,
            errorMessage: [],
            trendingArticles: [],
        };
    }
    componentDidMount() {
        if (this.props.location.query.category !== 'library') {
            let category = this.props.location.query.category
                ? this.props.location.query.category
                : "article"
            this.props.fetchTrendingArticles(category)
                .then((data) => {
                    this.setState({
                        trendingArticles: data
                    })
                })
                .catch((e) => {
                    this.setState({
                        error: true,
                        errorMessage: e.errors,
                    });
                });
        }
    }
    render() {
        let listItem = {
            lineHeight: "1.5",
            padding: "0 10px",
        };
        
        return (
            <List divided relaxed style={{ cursor: "pointer" }} >
                {
                    _.map(this.state.trendingArticles, (article) => {
                        return (
                            <List.Item
                                key={Math.random()}
                            >
                                <List.Content style={listItem}>
                                    <span
                                        onClick={() => this.props.selectArticle(
                                            article
                                        )}
                                    >
                                        {article.name}
                                    </span>
                                </List.Content>
                            </List.Item>
                        );
                    })
                }
            </List >
        );
    }
}