// Import from NPM
// -------------------------------------
import React from "react";
import { Icon, Image } from "semantic-ui-react";
import { hashHistory } from "react-router";
import { connect } from "react-redux";

// Import from Config
// -------------------------------------
import { getAppConfig } from "config/client.config";
import { Dic } from "config/initializers/config.translations";

import { CoursesAction } from "../../../redux/Courses.action.react";
import { gradient } from "config/app.helpers";

export class Footer extends React.Component {
    goTo = /* istanbul ignore next - react-router function */ (a) => {
        hashHistory.push(a);
    };

    render() {
        const isPortrait = window.innerHeight > window.innerWidth;
        const lang = this.props.auth.user.lang || "en";
        const dic = Object.assign({}, Dic[lang].common, Dic[lang].awards);
        let isIFrame = window.location !== window.parent.location;
        let path =
            this.props.routing.locationBeforeTransitions &&
                this.props.routing.locationBeforeTransitions.pathname
                ? this.props.routing.locationBeforeTransitions.pathname
                : "";
        let courseId = window.location.href.split("/courses/")[1]
            ? isIFrame
                ? window.location.href
                    .split("/courses/")[1]
                    .split("/")[0]
                    .split("?")[0]
                : window.location.href.split("/courses/")[1].split("/")[0]
            : null;

        let thisCourse =
            this.props.courses &&
                this.props.auth.user.courses.length > 1 &&
                courseId
                ? this.props.courses[courseId]
                : this.props.courses &&
                    this.props.auth.user.courses.length === 1
                    ? this.props.courses[this.props.auth.user.courses[0]]
                    : {};

        let readingdeck = path.startsWith("/decks/");

        let barStyle = {
            position: "fixed",
            bottom: "35px",
            left: "0",
            width: "100%",
            height: "60px",
            // background: this.props.transparent
            //     ? "transparent"
            //     : thisCourse
            //     ? thisCourse.colors.base
            //     : getAppConfig().colors.menuColor,
            zIndex: "1001",
        };
        let bottomMenuStyle = {
            float: "right",
            marginRight: "10px",
            marginTop: "-15px",
        };
        let footerBtnStyle = {
            marginTop: "5px",
            marginRight: "2px",
            fontSize: "0.75em",
            minWidth: (window.innerWidth * 0.67 - 20) / 2 + "px",
            textAlign: "center",
            color:
                thisCourse && thisCourse.colors
                    ? thisCourse.colors.primaryText
                    : getAppConfig().colors.iconColor,
            cursor: "pointer",
            float: "right",
            background:
                thisCourse && thisCourse.colors
                    ? gradient(thisCourse.colors.primary, "to bottom")
                    : getAppConfig().colors.menuColor,
            padding: "15px 10px 15px 10px",
            borderRadius: "5px 5px 0 0",
        };
        let footerIconStyle = {
            fontSize: "2em",
            background: "transparent",
            color:
                thisCourse && thisCourse.colors
                    ? thisCourse.colors.primaryText
                    : getAppConfig().colors.iconColor,
            cursor: "pointer",
            display: "block",
            margin: "3px auto",
        };

        let logoImageStyle = {
            width: "30%",
            height: "77px",
            position: "absolute",
            left: "10px",
            bottom: "0",
            borderRadius: "10px 10px 0 0",
            background:
                thisCourse && thisCourse.colors
                    ? thisCourse.colors.primary
                    : getAppConfig().colors.menuColor,
            padding: "5px",
        };

        let poweredByStyle = {
            position: "fixed",
            bottom: "0px",
            textAlign: "center",
            width: "100%",
            zIndex: "1001",
            color: getAppConfig().colors.iconColor
        }

        let poweredBy = (<div style={poweredByStyle} >
            <Image
                alt="clientLogo"
                src={"assets/images/configurable/Powerdby-v1.png"}
                style={{
                    margin: "auto",
                    height: "30px"
                }}
            />
        </div>);

        if (this.props.auth.user && this.props.auth.isUserLoggedIn) {
            if (isPortrait) {
                return (
                    <div>
                        {!readingdeck && (
                            <div>
                                <div style={barStyle}>
                                    <div style={logoImageStyle}>
                                        <Image
                                            alt="clientLogo"
                                            src={`${this.props.courses !== undefined &&
                                                Object.keys(this.props.courses)
                                                    .length !== 0 &&
                                                thisCourse &&
                                                thisCourse.image !== ""
                                                ? thisCourse.image
                                                : "assets/images/configurable/missing43.jpg"
                                                }?${Date.now()}`}
                                            onClick={() => {
                                                if (this.props.auth.inductor) {
                                                    this.goTo(
                                                        `/content/${this.props.inductor}/${courseId}`
                                                    );
                                                } else {
                                                    this.goTo(`/courses/${courseId}`);
                                                }
                                            }}
                                            style={{
                                                borderRadius: "10px 10px 0 0",
                                                cursor: "pointer",
                                            }}
                                        />
                                    </div>
                                    <div style={bottomMenuStyle}>
                                        {/* <div style={courseNameStyle}></div> */}
                                        <div
                                            style={footerBtnStyle}
                                            onClick={() =>
                                                this.goTo(
                                                    `/courses/${thisCourse._id}/rewards/profile`
                                                )
                                            }
                                        >
                                            <Icon name="gift" style={footerIconStyle} />
                                            {dic.awardsFooter}
                                        </div>
                                        {/* <div
                                    style={footerBtnStyle}
                                    onClick={() => this.goTo("/notifications")}
                                >
                                    <Icon name="bell" style={footerIconStyle} />
                                    Messages
                                </div> */}
                                        <div
                                            style={footerBtnStyle}
                                            onClick={() =>
                                                this.goTo(
                                                    `/courses/${thisCourse._id}/rewards/leaderboard`
                                                )
                                            }
                                        >
                                            <Icon
                                                name="list ol"
                                                style={footerIconStyle}
                                            />
                                            {dic.leaderboardFooter}
                                        </div>
                                    </div>

                                </div>
                                {poweredBy}
                            </div>
                        )}
                    </div>
                );
            } else {
                return (
                    poweredBy
                );
            }
        }
    }
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        courses: state.courses,
        routing: state.routing,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            getLeaderboard: (courseId) => {
                return dispatch(CoursesAction.getLeaderboard(courseId));
            },
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Footer);
