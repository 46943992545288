import { getAppConfig } from "config/client.config";
import _ from "lodash";

export const PERFORMANCE_ACCESS = "PERFORMANCE_ACCESS";

const _performanceAccess = /* istanbul ignore next - Action Creator */ (
    status,
    data,
    err
) => {
    return {
        type: PERFORMANCE_ACCESS,
        data,
        err,
        status,
    };
};

const mailReportPage = /* istanbul ignore next - Request and Pure passthrough */ report => {
    return (dispatch, getState, Request) => {
        return Request.fetch(getAppConfig().endpoints.mailReportPath, {
            method: "POST",
            body: JSON.stringify({
                file: report
            })
        })
            .then(() => {
                return Promise.resolve("Sent");
            })
            .catch(err => {
                return Promise.reject("Rejected");
            });
    };
};

function fetchUserDetails(userId) {
    return (dispatch, getState, Request) => {
        return Request.fetch(
            getAppConfig().endpoints.getUserDetailsPath.replace(":id", userId),
            {
                method: "GET"
            }
        )
            .then(response => {
                return response.json().then(data => {
                    return Promise.resolve(data);
                });
            })
            .catch(err => {
                return Promise.reject(err);
            });
    };
}
const fetchLearnerLog = learnerId => {
    return (dispatch, getState, Request) => {
        let getLearnerLogPath = getAppConfig().endpoints.fetchTrackerPath.replace(
            ":user_id",
            learnerId
        );
        return Request.fetch(getLearnerLogPath)
            .then(response => {
                return response.json().then(data => {
                    return data;
                });
            })
            .catch(err => {
                console.log(err);
            });
    };
};
const fetchLearnerProfile = learnerId => {
    return (dispatch, getState, Request) => {
        let getLearnerProfilePath = getAppConfig().endpoints.adminDataPaths[
            "getLearnerProfilePath"
        ].replace(":id", learnerId);
        return Request.fetch(getLearnerProfilePath)
            .then(response => {
                return response.json().then(data => {
                    return data;
                });
            })
            .catch(err => {
                console.log(err);
                // Response Schema expected
                // return {
                //     userId: "D002",
                //     username: "arijitl",
                //     status: "Active",
                //     name: "Arijit Lahiri",
                //     email: "arijit@quodeck.com",
                //     tags: ["Marketing", "Product", "Director", "Content"].join(
                //         ", "
                //     ),
                //     direct_reportees: 2,
                //     indirect_reportees: 3,
                //     created_at: new Date().toString().split("GMT")[0],
                //     first_login: new Date().toString().split("GMT")[0],
                // };
            });
    };
};
const fetchLearnerActivity = learnerId => {
    return (dispatch, getState, Request) => {
        let getLearnerActivityPath = getAppConfig().endpoints.adminDataPaths[
            "getLearnerActivityPath"
        ].replace(":id", learnerId);
        return Request.fetch(getLearnerActivityPath)
            .then(response => {
                return response.json().then(data => {
                    return data;
                });
            })
            .catch(err => {
                console.log(err);
                // Response Schema expected
                // return {
                //     sessions: 370,
                //     courses_count: 16,
                //     courses_started: 10,
                //     time_spent: 360000,
                //     points: 230000,
                //     days_active_percent: 43,
                //     days_active: [
                //         { name: "Apr", sessions: 30 },
                //         { name: "May", sessions: 20 },
                //         { name: "Jun", sessions: 27 },
                //         { name: "Jul", sessions: 18 },
                //         { name: "Aug", sessions: 23 },
                //         { name: "Sep", sessions: 24 },
                //         { name: "Oct", sessions: 24 },
                //         { name: "Nov", sessions: 24 },
                //         { name: "Dec", sessions: 24 },
                //         { name: "Jan", sessions: 24 },
                //         { name: "Feb", sessions: 24 },
                //         { name: "Mar", sessions: 24 }
                //     ],
                //     last_accessed_at: new Date(),
                //     monthly_time: [
                //         { name: "Apr", minutes: 30000 },
                //         { name: "May", minutes: 20000 },
                //         { name: "Jun", minutes: 27000 },
                //         { name: "Jul", minutes: 18000 },
                //         { name: "Aug", minutes: 23000 },
                //         { name: "Sep", minutes: 34000 },
                //         { name: "Oct", minutes: 34000 },
                //         { name: "Nov", minutes: 34000 },
                //         { name: "Dec", minutes: 34000 },
                //         { name: "Jan", minutes: 34000 },
                //         { name: "Feb", minutes: 34000 },
                //         { name: "Mar", minutes: 34000 }
                //     ]
                // };
            });
    };
};
const fetchLearnerCourses = learnerId => {
    return (dispatch, getState, Request) => {
        let getLearnerCoursesPath = getAppConfig().endpoints.adminDataPaths[
            "getLearnerCoursesPath"
        ].replace(":id", learnerId);
        return Request.fetch(getLearnerCoursesPath)
            .then(response => {
                return response.json().then(data => {
                    return _.map(data, c => {
                        return { text: c.name, value: c._id };
                    });
                });
            })
            .catch(err => {
                console.log(err);
                // Response Schema expected
                // return [
                //     { text: "Basics of Digital Marketing ", value: "5" },
                //     { text: "Essentials of Rural Distribution", value: "6" }
                // ];
            });
    };
};

const getLearnerHistory = (learnerId, courseId) => {
    return (dispatch, getState, Request) => {
        let getLearnerHistoryPath = getAppConfig().endpoints.adminDataPaths[
            "getLearnerHistoryPath"
        ].replace(":user_id", learnerId)
            .replace(":course_id", courseId);
        return Request.fetch(getLearnerHistoryPath)
            .then(response => {
                return response.json().then(data => {
                    return data;
                });
            })
            .catch(err => {
                console.log(err);

            });
    };
};

const fetchLearnerProgress = learnerId => {
    return (dispatch, getState, Request) => {
        let getLearnerProgressPath = getAppConfig().endpoints.adminDataPaths[
            "getLearnerProgressPath"
        ].replace(":id", learnerId);
        return Request.fetch(getLearnerProgressPath)
            .then(response => {
                return response.json().then(data => {
                    return data;
                });
            })
            .catch(err => {
                return Promise.reject(err);
                // Response Schema expected
                // return {
                //     completed: 50,
                //     started: 60,
                //     incomplete_completion: 35
                // };
            });
    };
};
const fetchLearnerCourseProgress = (learnerId, courseId) => {
    return (dispatch, getState, Request) => {
        let getLearnerCourseProgressPath = getAppConfig()
            .endpoints.adminDataPaths["getLearnerCourseProgressPath"].replace(
                ":id",
                learnerId
            )
            .replace(":course_id", courseId);
        return Request.fetch(getLearnerCourseProgressPath)
            .then(response => {
                return response.json().then(data => {
                    return data;
                });
            })
            .catch(err => {
                console.log(err);
                // Response Schema expected
                // return {
                //     name: "Basics of Digital Marketing",
                //     complete: false,
                //     started: true,
                //     sequential: false,
                //     has_final: false,
                //     content_coverage: 80,
                //     deck_status: {
                //         qdfs: { count: 20, complete: 10 },
                //         docdecks: { count: 10, complete: 2 },
                //         videcks: { count: 10, complete: 5 },
                //         scormdecks: { count: 10, complete: 3 },
                //         assessments: { count: 8, complete: 2 },
                //         surveys: { count: 6, complete: 4 },
                //         games: { count: 8, complete: 8 },
                //         certdecks: { count: 1, complete: 0 }
                //     },
                //     last_accessed_at: new Date(),
                //     completed_at: new Date(),
                //     view_attempts: 360,
                //     time_spent: 260000,
                //     points: 93500
                // };
            });
    };
};

const fetchLearnerCompletedCourses = learnerId => {
    return (dispatch, getState, Request) => {
        let getLearnerCompletedCoursesPath = getAppConfig().endpoints.adminDataPaths[
            "getLearnerCompletedCoursesPath"
        ].replace(":id", learnerId);
        return Request.fetch(getLearnerCompletedCoursesPath)
            .then(response => {
                return response.json().then(data => {
                    return _.map(data, c => {
                        return { text: c.name, value: c._id };
                    });
                });
            })
            .catch(err => {
                console.log(err);
                // Response Schema expected
                // return [
                //     { text: "Basics of Digital Marketing ", value: "5" },
                //     { text: "Essentials of Rural Distribution", value: "6" }
                // ];
            });
    };
};
const fetchLearnerPerformance = learnerId => {
    return (dispatch, getState, Request) => {
        let getLearnerPerformancePath = getAppConfig().endpoints.adminDataPaths[
            "getLearnerPerformancePath"
        ].replace(":id", learnerId);
        return Request.fetch(getLearnerPerformancePath)
            .then(response => {
                return response.json().then(data => {
                    return data;
                });
            })
            .catch(err => {
                console.log(err);
                // Response Schema expected
                // return {
                //     average_final_score: 50,
                //     score_completion: [
                //         { score: 90, completion: 20 },
                //         { score: 80, completion: 10 },
                //         { score: 70, completion: 30 },
                //         { score: 40, completion: 25 },
                //         { score: 50, completion: 40 },
                //         { score: 10, completion: 28 }
                //     ]
                // };
            });
    };
};
const fetchLearnerCoursePerformance = (learnerId, courseId) => {
    return (dispatch, getState, Request) => {
        let getLearnerCoursePerformancePath = getAppConfig()
            .endpoints.adminDataPaths[
            "getLearnerCoursePerformancePath"
        ].replace(":id", learnerId)
            .replace(":course_id", courseId);
        return Request.fetch(getLearnerCoursePerformancePath)
            .then(response => {
                return response.json().then(data => {
                    return data;
                });
            })
            .catch(err => {
                console.log(err);
                // Response Schema expected
                // return {
                //     name: "Basics of Digital Marketing",
                //     sequential: false,
                //     has_final: false,
                //     final_score: 80,
                //     assessment_scores: [
                //         { name: "Pre-assessment", score: 30 },
                //         { name: "Understanding CTR", score: 20 },
                //         { name: "Understanding Agencies", score: 27 },
                //         { name: "Final Assessment", score: 90 }
                //     ],
                //     completed_at: new Date(),
                //     view_attempts: 360,
                //     time_spent: 260000,
                //     points: 93500
                // };
            });
    };
};

const dispatchPerformanceAccess = () => {
    return (dispatch, getState, Request) => {
        return Promise.resolve(
            dispatch(
                _performanceAccess(
                    "success",
                    {},
                    null
                )
            )
        );
    }
}

class AnalyticsAction {
    static mailReportPage = mailReportPage;
    static fetchUserDetails = fetchUserDetails;
    static fetchLearnerProfile = fetchLearnerProfile;
    static fetchLearnerActivity = fetchLearnerActivity;
    static fetchLearnerCourses = fetchLearnerCourses;
    static fetchLearnerProgress = fetchLearnerProgress;
    static fetchLearnerLog = fetchLearnerLog;
    static fetchLearnerCourseProgress = fetchLearnerCourseProgress;
    static fetchLearnerCompletedCourses = fetchLearnerCompletedCourses;
    static fetchLearnerPerformance = fetchLearnerPerformance;
    static fetchLearnerCoursePerformance = fetchLearnerCoursePerformance;
    static getLearnerHistory = getLearnerHistory;
    static dispatchPerformanceAccess = dispatchPerformanceAccess;
}

export default AnalyticsAction;
