import React from "react";
import Text from "../../contentBlocks/Text.react";
import SlideHeader from "../../contentBlocks/SlideHeader.react";
import { Button } from "config/initializers/styles.app";
import { Form, TextArea } from "semantic-ui-react";

import { getAppConfig } from "config/client.config";
import { Dic } from "config/initializers/config.translations";
import Separator from "../../contentBlocks/Separator.react";
import { VelocityTransitionGroup } from "velocity-react";
import PropTypes from "prop-types";
import SlideHeaderSidebar from "../../contentBlocks/SlideHeaderSidebar.react";

import { Grid } from "semantic-ui-react";

export default class OpenAnswer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            answer: "",
        };
    }
    static propTypes = {
        data: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            iconlist: PropTypes.array,
            options: PropTypes.array,
            question: PropTypes.string,
            multiselect: PropTypes.array,
            purpose: PropTypes.string,
        }).isRequired,
        trackInteraction: PropTypes.func.isRequired,
        colors: PropTypes.shape({
            baseText: PropTypes.string,
            primary: PropTypes.string,
        }).isRequired,
        slideId: PropTypes.number,
        answer: PropTypes.number,
        slideShow: PropTypes.bool,
        mobileView: PropTypes.bool,
    };
    static defaultProps = {
        mobileView: false,
    };

    handleClick = () => {
        this.props.trackInteraction(this.state.answer);
    };

    changeText = (event) => {
        this.setState({ answer: event.target.value });
    };

    render() {
        const dic = Object.assign(
            {},
            Dic[window.lang].common,
            Dic[window.lang].deck,
            Dic[window.lang].overlay
        );
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let defaultStyle = {
            margin: "auto",
            width: "80%",
            textAlign: "center",
            paddingTop: window.innerWidth > 1400 ? "50px" : "25px",
            height: "100%",
            overflow: "hidden",
        };
        let defaultLandscapeStyle = {
            margin: "40px auto",
            width: "100%",
            textAlign: "center",
            position: "relative",
            float: "right",
            padding: "30px",
            overflow: "hidden",
        };
        let title =
            this.props.data.header !== undefined
                ? this.props.data.header
                : this.props.data.title;
        let subtitle =
            this.props.data.subheader !== undefined
                ? this.props.data.subheader
                : this.props.data.subtitle;
        return (
            <div className="full-height">
                {isPortrait ? (
                    <div>
                        <SlideHeader
                            {...this.props}
                            title={title}
                            subtitle={subtitle}
                        />
                        <div style={defaultStyle}>
                            <Text
                                keyName={"question-" + this.props.slideId}
                                text={this.props.data.question}
                                color={this.props.colors.baseText}
                                fontSize="large"
                                animation="transition.expandIn,500,500"
                            />
                            <Separator
                                keyName="separator"
                                color={this.props.colors.primary}
                                animation="transition.bounceLeftIn,500,500"
                            />
                            <VelocityTransitionGroup
                                enter={{
                                    animation: "transition.bounceUpIn",
                                    duration: "500",
                                    delay: "500",
                                }}
                                leave={{ animation: "fadeOut", duration: "0" }}
                                runOnMount
                            >
                                <Form>
                                    <TextArea
                                        rows="5"
                                        onChange={this.changeText}
                                        style={getAppConfig().textInputStyle}
                                    />
                                </Form>
                                <Button
                                    style={{ marginTop: "10px" }}
                                    size={isPortrait ? "medium" : "big"}
                                    onClick={this.handleClick}
                                >
                                    {dic.submitAnswer}
                                </Button>
                            </VelocityTransitionGroup>
                        </div>
                    </div>
                ) : (
                    <Grid style={{ minHeight: "100%" }}>
                        <Grid.Row style={{ paddingBottom: "0" }}>
                            <Grid.Column
                                width={8}
                                style={{
                                    paddingRight: "0",
                                    background: this.props.colors.primary,
                                }}
                            >
                                <SlideHeaderSidebar
                                    {...this.props}
                                    title={title}
                                    subtitle={subtitle}
                                />
                            </Grid.Column>
                            <Grid.Column width={8} style={{ paddingLeft: "0" }}>
                                <div style={defaultLandscapeStyle}>
                                    <Text
                                        keyName={
                                            "question-" + this.props.slideId
                                        }
                                        text={this.props.data.question}
                                        color={this.props.colors.baseText}
                                        fontSize="large"
                                        animation="transition.expandIn,500,500"
                                    />
                                    <Separator
                                        keyName="separator"
                                        color={this.props.colors.primary}
                                        animation="transition.bounceLeftIn,500,500"
                                    />
                                    <VelocityTransitionGroup
                                        enter={{
                                            animation: "transition.bounceUpIn",
                                            duration: "500",
                                            delay: "500",
                                        }}
                                        leave={{
                                            animation: "fadeOut",
                                            duration: "0",
                                        }}
                                        runOnMount
                                    >
                                        <Form>
                                            <TextArea
                                                rows="5"
                                                onChange={this.changeText}
                                                style={
                                                    getAppConfig()
                                                        .textInputStyle
                                                }
                                            />
                                        </Form>
                                        <Button
                                            style={{ marginTop: "10px" }}
                                            size={isPortrait ? "medium" : "big"}
                                            onClick={this.handleClick}
                                        >
                                            {dic.submitAnswer}
                                        </Button>
                                    </VelocityTransitionGroup>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
            </div>
        );
    }
}
