import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Caption from "../../contentBlocks/Caption.react";
import ImageGroup from "../../contentBlocks/ImageGroup.react";
import Carousel from "mobile/appComponents/carousel/Carousel.react";
import { resolveImage } from "config/app.helpers";
import SlideHeader from "../../contentBlocks/SlideHeader.react";
import SlideHeaderSidebar from "../../contentBlocks/SlideHeaderSidebar.react";
import { Grid } from "semantic-ui-react";

export default class Cards extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            index: 0,
        };
    }

    static propTypes = {
        data: PropTypes.shape({
            cards: PropTypes.array,
            subtitle: PropTypes.string,
            title: PropTypes.string,
        }).isRequired,
        colors: PropTypes.shape({}).isRequired,
    };

    handleClick = (index) => {
        this.setState({ index: index });
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let defaultStyle = {
            margin: "-20px auto",
            width: "100%",
            height: "100%",
            textAlign: "center",
            position: "relative",
        };
        let captionStyle = {
            position: "relative",
            width: "90%",
            margin: isPortrait ? "-10px auto" : "20px auto",
            textAlign: "center",
            fontSize: "0.65em",
            padding: "10px",
        };
        let cardImgStyle = {
            width: "100%",
            margin: "0 auto",
            position: "relative",
        };
        let cardCarouselStyle = {
            background: "#000",
        };
        let defaultLandscapeStyle = {
            margin: "auto",
            width: "100%",
            textAlign: "center",
            position: "relative",
            float: "right",
            padding: "0",
        };

        let carouselItems = _.map(this.props.data.cards, (elm, index) => {
            let _this = this;
            let visible = this.state.index === index ? "" : "hide";
            return (
                <div className={visible} key={"card-" + index}>
                    <div style={cardImgStyle}>
                        <ImageGroup
                            {..._this.props}
                            keyName={"image-" + index}
                            images={[elm.image]}
                            animation="fadeIn,100"
                        />
                    </div>
                    <div style={captionStyle}>
                        <Caption
                            keyName={"caption-" + index}
                            text={elm.text}
                            className={visible}
                            colors={_this.props.colors}
                            animation="fadeIn,100"
                        />
                    </div>
                </div>
            );
        });
        let carouselData = _.map(this.props.data.cards, (elm) => {
            return resolveImage(elm.image.id, this.props.imageLibrary);
        });
        return (
            <div className="full-height">
                {isPortrait ? (
                    <div>
                        <SlideHeader
                            {...this.props}
                            title={this.props.data.title}
                            subtitle={this.props.data.subtitle}
                        />
                        <div style={defaultStyle}>
                            {carouselItems}
                            <div style={cardCarouselStyle}>
                                <Carousel
                                    carouselItems={carouselData}
                                    autoPlay={false}
                                    arrows={true}
                                    slidesToShow={carouselData.length}
                                    slidesToScroll={1}
                                    changeImg={this.handleClick}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <Grid style={{ minHeight: "100%" }}>
                        <Grid.Row style={{ paddingBottom: "0" }}>
                            <Grid.Column
                                width={8}
                                style={{
                                    paddingRight: "0",
                                    backgroundColor: this.props.colors.primary,
                                }}
                            >
                                <SlideHeaderSidebar
                                    {...this.props}
                                    title={this.props.data.title}
                                    subtitle={this.props.data.subtitle}
                                />
                            </Grid.Column>
                            <Grid.Column
                                width={8}
                                style={{
                                    paddingLeft: "0",
                                    paddingBottom: "3%",
                                }}
                            >
                                <div style={defaultLandscapeStyle}>
                                    {carouselItems}
                                    <div style={cardCarouselStyle}>
                                        <Carousel
                                            carouselItems={carouselData}
                                            autoPlay={false}
                                            arrows={true}
                                            slidesToShow={carouselData.length}
                                            slidesToScroll={1}
                                            changeImg={this.handleClick}
                                        />
                                    </div>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
            </div>
        );
    }
}
