// Import from NPM
// -------------------------------------
import React from "react";
import PropTypes from "prop-types";
import { Icon, Input } from "semantic-ui-react";
import { hashHistory, Link } from "react-router";
import { connect } from "react-redux";

// Import from Config
// -------------------------------------
import { getAppConfig } from "config/client.config";
import { Button } from "config/initializers/styles.app";

// Import Components
// -------------------------------------
import BurgerMenu from "./BurgerMenu.react";
import NotificationBadge from "./NotificationBadge.react";
export class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSearchBar: false,
        };
    }
    static propTypes = {
        backBtn: PropTypes.bool,
        transparent: PropTypes.bool,
        backFn: PropTypes.func,
    };

    static defaultProps = {
        backBtn: true,
        transparent: false,
        backFn: null,
    };

    goBackOneLevel = /* istanbul ignore next - react-router function */ () => {
        if (this.props.backFn == null) hashHistory.goBack();
        else this.props.backFn();
    };

    handleSearch = (e) => this.setState({ searchText: e.target.value.trim() });
    startSearch = () => {
        if (this.state.searchText.length > 2) {
            hashHistory.push(`/articles?search=${this.state.searchText}`);
            this.setState({ showSearchBar: false });
        }
    };

    openSearchBar = () => this.setState({ showSearchBar: true });
    closeSearchBar = () => this.setState({ showSearchBar: false });

    toggleDisplayNotification = () => {
        hashHistory.push("/notifications");
    }

    render() {
        let showBackButton = !(
            this.props.routing.locationBeforeTransitions.pathname === "/home" ||
            this.props.routing.locationBeforeTransitions.pathname ===
            "/editProfile"
        );

        let profileSettings = getAppConfig().learningCenter.profileList
            ? getAppConfig().learningCenter.profileList
            : {};

        let isPortrait = window.innerHeight < window.innerWidth;

        let barStyle = {
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "50px",
            background: this.props.transparent
                ? "transparent"
                : getAppConfig().colors.menuColor,
            zIndex: "1001",
        };
        let logoStyle = {
            position: "absolute",
            left: showBackButton ? "40px" : "12px",
            top: "11px",
            height: "28px",
            transform: getAppConfig().singleCourseApp
                ? "translate(-50%,0)"
                : "translate(0,0)",
        };
        let backBtnStyle = {
            position: "absolute",
            left: "10px",
            top: "15px",
            height: "40px",
            fontSize: "1.5em",
            background: "transparent",
            color: getAppConfig().colors.iconColor,
            cursor: "pointer",
        };
        let searchIconStyle = {
            position: "absolute",
            float: "right",
            right: profileSettings.notifications ? "22%" : "13%",
            top: "15px",
            fontSize: "1.5em",
            background: "transparent",
            color: getAppConfig().colors.iconColor,
            cursor: "pointer",
        };
        let loginBtn = {
            float: "right",
            margin: "13px 10px",
            fontSize: "1.2em",
            color: getAppConfig().colors.iconColor,
        };
        if (this.props.auth.user && this.props.auth.isUserLoggedIn) {
            return (
                <div style={barStyle}>
                    {this.props.blog.articles.length > 0 && (
                        <div>
                            <Icon
                                name="search"
                                style={searchIconStyle}
                                onClick={this.openSearchBar}
                            />

                            {this.state.showSearchBar && (
                                <div
                                    id="searchid"
                                    style={{
                                        width: "100%",
                                        position: "absolute",
                                        top: "100%",
                                        zIndex: "-1",
                                        boxShadow:
                                            "rgba(60, 60, 60, 0.8) 0px 0px 0px 99999px",
                                        background: "#f5f5f5",
                                    }}
                                >
                                    <Input
                                        fluid
                                        icon="search"
                                        placeholder="Search..."
                                        onChange={this.handleSearch}
                                        style={{ margin: "9px 5px" }}
                                    />
                                    <Button.Group fluid>
                                        <Button
                                            negative
                                            content="Cancel"
                                            onClick={this.closeSearchBar}
                                        />
                                        <Button
                                            content="Start Search"
                                            onClick={this.startSearch}
                                        />
                                    </Button.Group>
                                </div>
                            )}
                        </div>
                    )}
                    {profileSettings.notifications && (
                        <NotificationBadge
                            toggleDisplayNotification={this.toggleDisplayNotification}
                            unreadNotification={this.props.notifications.unreadNotification}
                        />
                    )}

                    {!getAppConfig().singleCourseApp &&
                        this.props.routing.locationBeforeTransitions
                            .pathname !== "/editProfile" && (
                            <BurgerMenu
                                key={"burger-menu-" + Math.random()}
                                {...this.props}
                                tileLayout={true}
                            />
                        )}
                    {!isPortrait && showBackButton && (
                        <Icon
                            id="navbar-backBtn"
                            name="chevron left"
                            style={backBtnStyle}
                            onClick={this.goBackOneLevel}
                        />
                    )}
                    {!this.props.transparent && (
                        <a href="#/session/home">
                            <img
                                src={getAppConfig().ui.logo}
                                alt="Brand Logo"
                                style={logoStyle}
                            />
                        </a>
                    )}
                </div>
            );
        } else
            return (
                <div style={barStyle}>
                    {!this.props.transparent && (
                        <a href="#/session/home">
                            <img
                                src={getAppConfig().ui.logo}
                                alt="Brand Logo"
                                style={logoStyle}
                            />
                        </a>
                    )}
                    <Link to="/login" style={loginBtn}>
                        Login
                    </Link>
                </div>
            );
    }
}
const mapStateToProps = (state) => {
    return {
        courses: state.courses,
        routing: state.routing,
        blog: state.blog,
        auth: state.auth,
        notifications: state.notification
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {},
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
