import React from "react";
import PropTypes from "prop-types";
import { Grid } from "semantic-ui-react";
import SlideHeader from "../../contentBlocks/SlideHeader.react";
import SlideHeaderSidebar from "slides/contentBlocks/SlideHeaderSidebar.react";
import Text from "../../contentBlocks/Text.react";

export default class Title extends React.Component {
    static propTypes = {
        colors: PropTypes.shape({
            base: PropTypes.string.isRequired,
            primary: PropTypes.string.isRequired,
            primaryVariation: PropTypes.string.isRequired,
            secondary: PropTypes.string.isRequired,
            baseText: PropTypes.string.isRequired,
            primaryText: PropTypes.string.isRequired,
            secondaryText: PropTypes.string.isRequired,
        }).isRequired,
        data: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
        }).isRequired,
        fontSize: PropTypes.string,
        mobileView: PropTypes.bool,
    };

    static defaultProps = {
        mobileView: false,
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;

        let defaultLandscapeStyle = {
            margin: "15px auto",
            width: "100%",
            textAlign: "center",
            position: "relative",
            float: "right",
            padding: "30px",
        };
        let hasImage =
            this.props.data.image !== undefined &&
            !this.props.data.image.id.includes("default");
        return (
            <div className="full-height">
                {!isPortrait && hasImage ? (
                    <Grid style={{ minHeight: "100%" }}>
                        <Grid.Row style={{ paddingBottom: "0" }}>
                            <Grid.Column
                                width={8}
                                style={{
                                    padding: "15px",
                                    display: "flex",
                                }}
                            >
                                <SlideHeaderSidebar
                                    {...this.props}
                                    title={""}
                                    subtitle={""}
                                />
                            </Grid.Column>
                            <Grid.Column width={8} style={{ paddingLeft: "0" }}>
                                <div style={defaultLandscapeStyle}>
                                    <Text
                                        keyName={
                                            "question-" + this.props.slideId
                                        }
                                        text={this.props.data.title}
                                        color={this.props.colors.baseText}
                                        fontSize="large"
                                        animation="fadeIn,100"
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                ) : (
                    <SlideHeader
                        {...this.props}
                        title={this.props.data.title}
                        fontSize={isPortrait ? "huge" : "ultra"}
                        height={
                            isPortrait
                                ? `calc(100vh - ${window.innerWidth * 0.5 +
                                      130}px)`
                                : "50%"
                        }
                        largePresenter={true}
                    />
                )}
            </div>
        );
    }
}
