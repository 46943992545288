// Import from NPM
// -------------------------------------
import axios from "axios";
import _ from "lodash";
import localforage from "localforage";
import SimpleCrypto from "simple-crypto-js";
import { hashHistory } from "react-router";

// Import App Configuration and Setup
// -------------------------------------
import { appConfig } from "config/initializers/config.app";
import { getAppConfig } from "config/client.config";

// Import App Actions and Reduxers
// -------------------------------------
import { Store } from "backend/storage/mobile/mobile.store";
import { LOGOUT } from "appRedux/helpers/reduxConstants";
class Request {
    static assetHelpers = {
        fileSystem: null,
        zip: null,
    };

    //--------------------------------------------------------------
    // This is the main function that is provided by this module
    //--------------------------------------------------------------
    static fetch(path, data, domain) {
        let methodData = _.merge({}, data, { url: path });

        // Fetch JS backward Compatibility
        if (data && data.body) methodData.data = methodData.body;
        if (data && data.credentials && data.credentials === "include")
            methodData.withCredentials = true;

        return (
            // Set the axios default parameters - Base url, maxRedirects, request timeout and the data sanitizer
            // Then check and set the access token in the request if it is already available
            // Then check if the current access token has expired and if not, send the request to the server
            // Then check if the user is running the most current version of the app
            // If everything checks out properly, send the request to the api and return the result to the request originator

            Promise.all([this.setDefaults(), this.checkAndSetTokenForRequest()])
                .then((setupResponse) => {
                    return this.checkForExpiry(path)
                        .then((expiryResponse) => {
                            return axios
                                .request(methodData)
                                .then((fetchResponse) => {
                                    const headers = fetchResponse.headers;
                                    return this.checkMinAppVersion(headers)
                                        .then((versionResponse) => {
                                            this.setTokenForNextRequest(
                                                null,
                                                headers["access-token"],
                                                headers["client"],
                                                headers["expiry"],
                                                headers["uid"],
                                                headers["token-type"]
                                            );
                                            return {
                                                json: () =>
                                                    Promise.resolve(
                                                        fetchResponse.data
                                                    ),
                                                blob: () =>
                                                    Promise.resolve(
                                                        fetchResponse.data
                                                    ),
                                                ok: true,
                                            };
                                        })
                                        .catch((versionInfo) => {
                                            console.log(
                                                "Version Error: ",
                                                versionInfo
                                            );
                                            // Route the user to the force update page
                                            hashHistory.push(
                                                "/forceUpdate?latest=" +
                                                    versionInfo
                                            );
                                        });
                                })
                                .catch((fetchError) => {
                                    console.log("Fetch Error:", fetchError);
                                    return this.handleFetchError(fetchError);
                                });
                        })
                        .catch((expiryError) => {
                            console.log("Expiry Error:", expiryError);
                            if (expiryError.response) {
                                return Promise.reject(expiryError);
                            } else {
                                // handle expired tokens
                                this.logoutAndResetToken().then(() => {
                                    const loginPath =
                                        appConfig.auth.routes.login;
                                    if (loginPath.indexOf("http") === -1)
                                        hashHistory.push(loginPath);
                                    else {
                                        let targetPath = window.location.href.split(
                                            "#/"
                                        )[1];
                                        window.location = loginPath.replace(
                                            "login",
                                            targetPath.split("?")[0]
                                        );
                                    }
                                });
                            }
                        });
                })
                // This will always be resolved so no need of catch handling.
                .catch((setupErrors) => {
                    console.log("Setup Error:", setupErrors);
                    if (setupErrors.response) {
                        return Promise.reject(setupErrors);
                    }
                })
        );
    }

    // ------------------------------------------------------------------------------------------------
    // Set the axios defaults
    // baseURL: `baseURL` will be prepended to `url` unless `url` is absolute.
    // maxRedirects: maxRedirects` defines the maximum number of redirects to follow in node.js. If set to 0, no redirects will be followed.
    // timeout: `timeout` specifies the number of milliseconds before the request times out. If the request takes longer, it will be aborted.
    // transformRequest: If the POST|PUT|PATCH call data is not stringified, then stringify them. If it is formdata, send it as it is
    // ------------------------------------------------------------------------------------------------
    static setDefaults() {
        axios.defaults.baseURL = getAppConfig().apiUrls.apiUrl;
        axios.defaults.timeout = 120000; // This is optimal for all API requests
        axios.defaults.maxRedirects = 5;
        axios.defaults.transformRequest = [
            function(data, headers) {
                // If the POST|PUT|PATCH call data is not stringified, then stringify them. If it is formdata, send it as it is.
                if (data) {
                    let formData = data;
                    let isStringified = _.isString(formData);
                    // If data is not strigified and not of type formdata, then stringify before sending.
                    let isFormData =
                        formData.toString().indexOf("FormData") > -1;
                    if (data && !isStringified && !isFormData) {
                        data = JSON.stringify(data);
                    }
                    // If you sending data in body, the header has to be type application/json
                    if (!isFormData) {
                        headers.post["Content-Type"] = "application/json";
                        headers.patch["Content-Type"] = "application/json";
                        headers.common[
                            "App-Version"
                        ] = getAppConfig().appVersion;
                    }
                }
                return data;
            },
        ];
        return Promise.resolve("Success: Axios defaults set");
    }

    // ------------------------------------------------------------------------------------------------
    // Check whether axios headers contains access token
    // If not present then we want to get it from the local storage and set it for the request
    // This is essentially to handle refresh or app close conditions, where headers of axios are reset
    // ------------------------------------------------------------------------------------------------
    static checkAndSetTokenForRequest() {
        if (!axios.defaults.headers.common["access-token"]) {
            // Get the token from local storage
            return this.getAccessTokenFromLocalStore()
                .then((tokenHash) => {
                    // And set it in the request header
                    return this.setTokenForNextRequest(tokenHash);
                })
                .catch((err) => {
                    console.log("Error: Unable to set Token Hash: ", err);
                });
        } else {
            // The token is already present, so do nothing
            return Promise.resolve("Ignore: Token already present");
        }
    }

    // ------------------------------------------------------------------------------------------------
    // Get access token from the local store
    // This has to be stored in an encrypted format and gets decrypted in this function before using
    // ------------------------------------------------------------------------------------------------
    static getAccessTokenFromLocalStore() {
        return localforage
            .getItem("tokenHash")
            .then((stringifiedTokenHashEncoded) => {
                if (stringifiedTokenHashEncoded) {
                    // This has to be stored in an encrypted format and gets decrypted in this function before using
                    const simpleCrypto = new SimpleCrypto(
                        getAppConfig().secretKey
                    );
                    const decryptedValue = simpleCrypto.decrypt(
                        stringifiedTokenHashEncoded
                    );
                    const tokenValue =
                        typeof decryptedValue === "string" ||
                        decryptedValue instanceof String
                            ? JSON.parse(decryptedValue)
                            : decryptedValue;
                    return tokenValue;
                } else {
                    // If LocalStore Doesn't have any token hash (generally in initial app start), return blank hash.
                    return {};
                }
            })
            .catch((err) => {
                console.log("Error: Unable to get Token Hash: ", err);
            });
    }
    // For backward compatibility
    static getToken() {
        return this.getAccessTokenFromLocalStore();
    }

    // ------------------------------------------------------------------------------------------------
    // Set the access token into the request header
    // For this, either pass the token from the local store
    // Or pass the token parameters to create and save a new token in the local store
    // ------------------------------------------------------------------------------------------------
    static setTokenForNextRequest(
        tokenHash,
        token,
        client,
        expiry,
        uid,
        tokenType = "Bearer"
    ) {
        if (
            tokenHash !== null &&
            tokenHash["access-token"] &&
            tokenHash["token-type"] &&
            tokenHash["expiry"] &&
            tokenHash["client"] &&
            tokenHash["uid"]
        ) {
            // If a token hash has been passed and it is in a valid format
            // Set the received access token into the request header
            return Promise.resolve(
                (axios.defaults.headers.common = {
                    ...axios.defaults.headers.common,
                    ...tokenHash,
                })
            );
        } else if (token && client && expiry && uid && tokenType) {
            // If token parameters have been passed in a valid format
            // Create and save a new token in the local store and set it in the request header
            return Promise.resolve(
                (axios.defaults.headers.common = {
                    ...axios.defaults.headers.common,
                    ...this.saveAndReturnAccessToken(
                        token,
                        client,
                        expiry,
                        uid,
                        tokenType
                    ),
                })
            );
        } else {
            return Promise.resolve(
                "Ignore: No access token in store and no access token parameters passed"
            );
        }
    }
    static saveAndReturnAccessToken(token, client, expiry, uid, tokenType) {
        const tokenHash = {
            "access-token": token,
            "token-type": tokenType,
            expiry: expiry,
            client: client,
            uid: uid,
        };
        const stringifiedTokenHashEncoded = JSON.stringify(tokenHash);
        const simpleCrypto = new SimpleCrypto(getAppConfig().secretKey);
        const encryptedValue = simpleCrypto.encrypt(
            stringifiedTokenHashEncoded
        );
        localforage.setItem("tokenHash", encryptedValue);
        Promise.resolve(tokenHash);
    }

    // ------------------------------------------------------------------------------------------------
    // Check if the access token being used has expired
    // This has to be checked only for paths that require authentication
    // ------------------------------------------------------------------------------------------------
    static checkForExpiry(url) {
        const unauthPaths = _.map(
            getAppConfig().unauthenticatedPaths,
            (path) => getAppConfig().endpoints[path]
        );
        const thisPath = url.split("?")[0].replace(/^[0-9a-fA-F]{24}$/, ":id");

        if (
            unauthPaths.indexOf(thisPath) !== -1 ||
            thisPath.indexOf("deep_show") !== -1 ||
            thisPath.indexOf("connect") !== -1
        ) {
            // Don't check for urls that are unauthenticated. If you add an unauthenticated url, ensure it gets added here
            return Promise.resolve(
                "Ignore: Unauthenticated paths, so no access token required"
            );
        } else {
            // Get the current expiry
            const timestamp = _.now() / 1000;
            // Get the expiry currently stored in the axios.defaults
            let expiry = axios.defaults.headers.common.expiry;
            if (expiry) {
                // If the default access header has an expiry set
                if (timestamp < _.parseInt(expiry))
                    return Promise.resolve("Success: Unexpired access token");
                else {
                    console.log(
                        "Failure: Expired Token. Expiry: ",
                        expiry,
                        " ; Current: ",
                        timestamp
                    );
                    return Promise.reject("Failure: Expired access token");
                }
            } else {
                // If the default access header does not have an expiry set
                return this.getAccessTokenFromLocalStore()
                    .then((tokenHash) => {
                        // Check the access token from the store
                        if (tokenHash.expiry) {
                            if (timestamp < _.parseInt(tokenHash.expiry)) {
                                return Promise.resolve(
                                    "Success: Unexpired access token"
                                );
                            } else {
                                console.log(
                                    "Failure: Expired Token. Expiry: ",
                                    expiry,
                                    " ; Current: ",
                                    timestamp
                                );
                                return Promise.reject(
                                    "Failure: Expired access token"
                                );
                            }
                        } else {
                            return Promise.reject(
                                "Failure: No expiry mentioned"
                            );
                        }
                    })
                    .catch((err) => {
                        console.log(
                            "Error: Unable to get access token from store."
                        );
                    });
            }
        }
    }

    // ------------------------------------------------------------------------------------------------
    // Check if the most current app version is being used, else trigger a force upgrade
    // Version is coded as LTS.STABLE.CUSTOM
    // Version configuration defines trigger in terms of force update on LTS, Stable or Custom version upgrade
    // ------------------------------------------------------------------------------------------------
    static checkMinAppVersion(headers) {
        let latestVersion = headers["latest-version"];
        let server =
            latestVersion &&
            _.map(latestVersion.split("."), (n) => parseInt(n, 0));
        let client = _.map(getAppConfig().appVersion.current.split("."), (n) =>
            parseInt(n, 0)
        );
        if (server)
            switch (getAppConfig().appVersion.updateOn) {
                case "lts":
                    return server[0] > client[0]
                        ? Promise.reject(latestVersion)
                        : Promise.resolve("Success: Version is most current");
                case "stable":
                    return server[0] > client[0] || server[1] > client[1]
                        ? Promise.reject(latestVersion)
                        : Promise.resolve("Success: Version is most current");
                case "custom":
                    return server[0] > client[0] ||
                        server[1] > client[1] ||
                        server[2] > client[2]
                        ? Promise.reject(latestVersion)
                        : Promise.resolve("Success: Version is most current");
                default:
                    return Promise.resolve(
                        "Ignore: Upgrade cycle not mentioned"
                    );
            }
        else return Promise.resolve("Ignore: No server version available");
    }

    // ------------------------------------------------------------------------------------------------
    // Handle errors when the fetch call fails
    // ------------------------------------------------------------------------------------------------
    static handleFetchError(error) {
        const config = getAppConfig();
        let exceptionError;
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx

            // Get all the unauthenticated paths into an array with full paths
            const unauthenticatedPaths = _.map(
                config.unauthenticatedPaths.concat("signOutPath"),
                (path) => config.apiUrls.apiUrl + config.endpoints[path]
            );
            exceptionError = new Error(error.response.statusText);
            exceptionError.response = error.response.data;
            exceptionError.errors = error.response.data.errors;
            if (
                error.response.status === 401 &&
                unauthenticatedPaths.indexOf(error.config.url) === -1
            ) {
                // If this is an unauthorized access error and the fetching url is not an unauthenticated route
                // Log the user out as a security feature and then throw the error
                return this.logoutAndResetToken().then(() => {
                    const unauthRoot =
                        appConfig.auth.routes.unauthenticatedRoot;
                    if (unauthRoot.indexOf("http") === -1)
                        hashHistory.push(unauthRoot);
                    else {
                        window.location = unauthRoot.replace(
                            "login",
                            window.location.href.split("/#/")[1]
                        );
                    }
                });
                // .then(() => {
                //     return Promise.reject(exceptionError);
                // });
            } else {
                // Throw the error
                return Promise.reject(exceptionError);
            }
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            exceptionError = new Error("Server Not Responded");
            exceptionError.response = "Server Not Responded";
            exceptionError.errors = [
                "The Request failed. Please make sure you are connected to Internet",
            ];
            return Promise.reject(exceptionError);
        } else {
            // Something happened in setting up the request that triggered an Error
            exceptionError = new Error(error.message);
            exceptionError.response = error.message;
            exceptionError.errors = [error.message];
            return Promise.reject(exceptionError);
        }
    }

    // ------------------------------------------------------------------------------------------------
    // Log out the user and reset access token
    // This is invoked in case of unauthorized access on the app
    // ------------------------------------------------------------------------------------------------

    static logoutAndResetToken() {
        // const logoutPath = getAppConfig().endpoints.signOutPath;
        // return axios
        //     .request(logoutPath, {
        //         method: "POST",
        //         body: "",
        //     })
        //     .then(() => {
        return this.resetToken();
        // })
        // .catch(() => {
        //     return this.resetToken();
        // });
    }

    static resetToken() {
        console.log("Token is being reset");
        return localforage.removeItem("tokenHash", null).then(() => {
            return localforage
                .setItem("lastLocation", window.location.href.split("/#/")[1])
                .then(() => {
                    axios.defaults.headers.common = {
                        Accept: "application/json, text/plain, */*",
                    };
                    return Promise.resolve(
                        Store.dispatch({ type: LOGOUT, status: "success" })
                    );
                })
                .catch((err) => {
                    console.log("Error: Unable to reset token in store.");
                });
        });
    }

    static resetTokenWithoutDispatch() {
        console.log("Token is being reset");
        return localforage.removeItem("tokenHash", null).then(() => {
            return localforage
                .setItem("lastLocation", window.location.href.split("/#/")[1])
                .then(() => {
                    axios.defaults.headers.common = {
                        Accept: "application/json, text/plain, */*",
                    };
                    return Promise.resolve(0);
                })
                .catch((err) => {
                    console.log("Error: Unable to reset token in store.");
                });
        });
    }

    // ------------------------------------------------------------------------------------------------
    // Fetch file from local system
    // This is used in the handling of offline mode
    // ------------------------------------------------------------------------------------------------

    static fetchFile(path, data) {
        return this.setDefaults()
            .then(() => {
                let options = {
                    ...axios.defaults,
                    ...{
                        responseType: "blob",
                        Accept: "application/octet-stream",
                    },
                };
                let methodData = _.merge({}, data, { url: path });
                if (data && data.body) {
                    methodData.data = methodData.body;
                }
                let fetchOpts = { ...options, ...methodData };
                return axios
                    .request(fetchOpts)
                    .then((response) => {
                        const headers = response.headers;
                        this.setTokenHeadersForNextRequest(
                            headers["access-token"],
                            headers["client"],
                            headers["expiry"],
                            headers["uid"],
                            headers["token-type"]
                        );
                        return response.data;
                    })
                    .catch((error) => {
                        return this.handleFetchError(error);
                    });
            })
            .catch((error) => {
                if (error.response) {
                    return Promise.reject(error);
                } else {
                    let exceptionError = new Error(error.message);
                    exceptionError.response = error.message;
                    exceptionError.errors = [error.message];
                    return Promise.reject(exceptionError);
                }
            });
    }
}
window.AxiosRequest = Request;
window.axios = axios;
window.localforage = localforage;
export { Request };
