import React, { PureComponent } from "react";
import _ from "lodash";
import { Segment, Table } from "semantic-ui-react";
import Loading from "../../../appComponents/loading/Loading.react.jsx";
import ErrorPage from "common/globals/error/ErrorPage.react.jsx";

// Import from Config
// -------------------------------------
import { getAppConfig } from "config/client.config";

export default class Profile extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            fetchCompleted: false,
            error: null,
            profileData: null
        };
    }
    componentDidMount() {
        this.props.actions
            .fetchLearnerProfile(this.props.user.id)
            .then(data => {
                this.setState({ fetchCompleted: true, profileData: data });
            })
            .catch(
                /* istanbul ignore next - tested in the render */ e => {
                    this.setState({
                        fetchCompleted: true,
                        error: true
                    });
                }
            );
    }
    render() {
        const isPortrait = window.innerHeight > window.innerWidth;
        let pageStyle = {
            base: {
                width: "720px",
                margin: "120px 40px",
                textAlign: "center",
                padding: "50px",
                fontSize: "1.25em"
            },
            baseMobile: {
                width: "100%",
                textAlign: "center",
                fontSize: "1.25em",
                height: "100vh",
                overflow: "hidden scroll",
                marginTop: "50px",
                paddingBottom: getAppConfig().inductor ? "50%" : ""
            },
            tableHeader: {
                background: "#767676",
                color: "#ffffff"
            },
            header: {
                background: getAppConfig().colors.accents.primary,
                color: getAppConfig().colors.accents.primaryText
            }
        };
        if (!this.state.fetchCompleted) return <Loading />;
        else if (this.state.fetchCompleted && !this.state.error) {
            return (
                <Segment raised style={isPortrait ? pageStyle.baseMobile : pageStyle.base}>
                    <h2>User Profile</h2>
                    <Table celled compact>
                        {!isPortrait && <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell
                                    width={6}
                                    style={pageStyle.tableHeader}
                                >
                                    Parameter
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    width={10}
                                    style={pageStyle.tableHeader}
                                >
                                    Details
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        }
                        <Table.Body>
                            {this.state.profileData !== null &&
                                _.map(
                                    _.toPairs(this.state.profileData),
                                    (elm, index) => {
                                        return (
                                            <Table.Row
                                                key={
                                                    "profile-parameter-" + index
                                                }
                                            >
                                                <Table.Cell
                                                    style={pageStyle.header}
                                                >
                                                    {_.startCase(
                                                        elm[0].replace("_", " ")
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {elm[1] !== null &&
                                                        (
                                                            elm[1]
                                                                .toString()
                                                                .match(/:/g) || []
                                                        ).length === 2 &&
                                                        (
                                                            elm[1]
                                                                .toString()
                                                                .match(/-/g) || []
                                                        ).length === 2
                                                        ? new Date(
                                                            elm[1]
                                                        ).toLocaleString()
                                                        : elm[1]}
                                                </Table.Cell>
                                            </Table.Row>
                                        );
                                    }
                                )}
                        </Table.Body>
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan="2">
                                    &lt;&lt; Click on the tabs to the left to
                                    find out more
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </Segment>
            );
        } else {
            return <ErrorPage msg="Sorry, could not fetch the content" />;
        }
    }
}
