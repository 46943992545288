import React, { Component } from "react";
import { Button } from "config/initializers/styles.app";

export class MenuCard extends Component {
    render() {
        return (
            <div>
                {this.props.content.text}
                <br />
                <br />
                {this.props.content.button && (
                    <Button
                        content={this.props.content.button.text}
                        onClick={() =>
                            this.props.goToPage(this.props.content.button.link)
                        }
                    />
                )}
            </div>
        );
    }
}
