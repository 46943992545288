import React from "react";
import PropTypes from "prop-types";
import Text from "../../contentBlocks/Text.react";
import { Button } from "config/initializers/styles.app";

import { Dic } from "config/initializers/config.translations";
import SlideHeader from "../../contentBlocks/SlideHeader.react";
import SlideHeaderSidebar from "../../contentBlocks/SlideHeaderSidebar.react";
import { Grid } from "semantic-ui-react";

export default class ExternalLink extends React.Component {
    static propTypes = {
        colors: PropTypes.shape({
            base: PropTypes.string.isRequired,
            primary: PropTypes.string.isRequired,
            primaryVariation: PropTypes.string.isRequired,
            secondary: PropTypes.string.isRequired,
            baseText: PropTypes.string.isRequired,
            primaryText: PropTypes.string.isRequired,
            secondaryText: PropTypes.string.isRequired,
        }).isRequired,
        data: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            paragraph: PropTypes.string,
            link: PropTypes.string,
            image: PropTypes.string,
        }).isRequired,
        slideId: PropTypes.number,
        mobileView: PropTypes.bool,
    };
    render() {
        const dic = Object.assign(
            {},
            Dic[window.lang].common,
            Dic[window.lang].deck,
            Dic[window.lang].overlay
        );
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let defaultStyle = {
            margin: "auto",
            width: "80%",
            textAlign: "center",
            paddingTop: "50px",
        };
        let buttonStyle = {
            marginTop: "10px",
        };

        let defaultLandscapeStyle = {
            margin: "50px auto",
            width: "100%",
            textAlign: "center",
            position: "relative",
            float: "right",
            padding: "25px",
        };
        let title =
            this.props.data.header !== undefined
                ? this.props.data.header
                : this.props.data.title;
        let subtitle =
            this.props.data.subheader !== undefined
                ? this.props.data.subheader
                : this.props.data.subtitle;
        return (
            <div className="full-height">
                {isPortrait ? (
                    <div>
                        <SlideHeader
                            {...this.props}
                            title={title}
                            subtitle={subtitle}
                        />
                        <div style={defaultStyle}>
                            <Text
                                keyName="paragraph"
                                text={this.props.data.paragraph}
                                color={this.props.colors.baseText}
                                fontSize="large"
                                animation="transition.expandIn,500,600"
                            />
                            <br />
                            <a
                                href={this.props.data.link}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Button
                                    primary
                                    fluid
                                    size="huge"
                                    style={buttonStyle}
                                >
                                    {dic.go}
                                </Button>
                            </a>
                        </div>
                    </div>
                ) : (
                    <Grid style={{ minHeight: "100%" }}>
                        <Grid.Row style={{ paddingBottom: "0" }}>
                            <Grid.Column
                                width={8}
                                style={{
                                    paddingRight: "0",
                                    backgroundColor: this.props.colors.primary,
                                }}
                            >
                                <SlideHeaderSidebar
                                    {...this.props}
                                    title={title}
                                    subtitle={subtitle}
                                />
                            </Grid.Column>
                            <Grid.Column width={8} style={{ paddingLeft: "0" }}>
                                <div style={defaultLandscapeStyle}>
                                    <Text
                                        keyName="paragraph"
                                        text={this.props.data.paragraph}
                                        color={this.props.colors.baseText}
                                        fontSize="large"
                                        animation="transition.expandIn,500,600"
                                    />
                                    <br />
                                    <a
                                        href={this.props.data.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Button
                                            primary
                                            fluid
                                            size="huge"
                                            style={buttonStyle}
                                        >
                                            {dic.go}
                                        </Button>
                                    </a>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
            </div>
        );
    }
}
