// Import from NPM
// -------------------------------------
import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import { slide as Menu } from "react-burger-menu";
import { Link } from "react-router";

// Import from Config
// -------------------------------------
import { getAppConfig } from "config/client.config";
import { resolveImage } from "config/app.helpers";
import { Image } from "config/initializers/styles.app";

// Import Components
// -------------------------------------
import BurgerMenuItem from "./BurgerMenuItem.react";

export class BurgerMenu extends React.Component {
    static propTypes = {
        transparent: PropTypes.bool,
    };

    static defaultProps = {
        transparent: false,
    };

    render() {
        const isPortrait = window.innerHeight > window.innerWidth;
        let menuStyles = {
            bmBurgerButton: {
                position: "fixed",
                width: "20px",
                height: "20px",
                right: "20px",
                top: "15px",
            },
            bmMenuWrap: {
                width: "100%",
                height: "100vh",
                borderRight: "1px solid #666",
            },
            bmBurgerBars: {
                background: getAppConfig().colors.iconColor,
            },
            bmCrossButton: {
                top: "10px",
                right: "10px",
                height: "42px",
                width: "42px",
                color: "#000000",
            },
            bmCross: {
                height: "24px",
                background: "#000000",
            },
            bmMenu: {
                background: "#ffffff",
                padding: "2.5em 0",
                fontSize: "1.5em",
                lineHeight: "1.5",
                overflow: "hidden",
            },
            bmMorphShape: {
                fill: "#373a47",
            },
            bmOverlay: {
                background: "rgba(0, 0, 0, 0.3)",
                position: "relative",
                zIndex: "99999",
            },
        };
        let headerItemStyle = {
            background: getAppConfig().colors.master,
            paddingTop: "0",
            border: "1px solid " + getAppConfig().colors.master,
            color: getAppConfig().colors.masterText,
        };
        let profilePicStyle = {
            display: "inline-block",
            width: "25%",
            height: "auto",
            overflow: "hidden",
        };
        let profileStyle = {
            display: "inline-block",
            paddingLeft: "20px",
            width: "60%",
            height: "40%",
            verticalAlign: "middle",
        };
        let footerItemStyle = {
            display: "block",
            position: "absolute",
            bottom: "0",
            left: "0",
            width: "100%",
            background: getAppConfig().colors.accents.secondary,
            padding: "12px",
            border: "1px solid" + getAppConfig().master,
            color: "#000000",
            cursor: "pointer",
            fontSize: "1em",
            textAlign: "center",
        };

        let footerItemTextStyle = { color: getAppConfig().colors.accents.secondaryText }

        let image = this.props.auth.user ? this.props.auth.user.image : null;
        return (
            <Menu right styles={menuStyles}>
                <div style={headerItemStyle}>
                    {/* <a href="#/home"> */}
                    <Image
                        src={
                            image === null || image === undefined
                                ? "assets/images/configurable/avatar.jpg"
                                : resolveImage(image.image)
                        }
                        style={profilePicStyle}
                    />
                    {/* </a> */}
                    {this.props.auth.user && (
                        <div style={profileStyle}>
                            {this.props.auth.user.first_name}{" "}
                            {this.props.auth.user.last_name}
                            {/* <div style={{ fontSize: "0.6em" }}>
                            {this.props.auth.user.username}
                        </div> */}
                        </div>
                    )}
                </div>
                <div style={{
                    overflow: "scroll",
                    display: "block",
                    height: "100%",
                    width: "100%",
                    paddingBottom: "6em",
                }}>
                    {
                        _.map(getAppConfig().home.links, (item, index) => {
                            if (item.link) return (
                                <BurgerMenuItem
                                    active={
                                        window.location.hash.substring(1) ===
                                        item.link
                                    }
                                    name={item.name}
                                    // icon={"chevron right"}
                                    link={item.link}
                                    key={"bm-menu-item-" + index}
                                    external={item.external}
                                />
                            );
                        })
                    }
                </div>
                {isPortrait && (
                    <div>
                        <Link to="/logout" style={footerItemStyle} className="profileSeg">
                            <div style={footerItemTextStyle}>Logout</div>
                        </Link>
                    </div>
                )}
            </Menu>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

const mapDispatchToProps = /* istanbul ignore next - redux function*/ (
    dispatch
) => {
    return {
        actions: {},
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(BurgerMenu);
