import _ from "lodash";
import { REHYDRATE } from "redux-persist/constants";
import {
    FETCH_LIBRARY,
    AGGREGATE_COURSES,
    AGGREGATE_SCORM,
    SET_LAST_LOCATION,
    SUBMIT_RATING
} from "appRedux/helpers/reduxConstants";

import { scorm_aggregation } from "appRedux/helpers/aggregation.helpers";
import { aggregateCourseStateAndHistory } from "./helpers/aggregatorFunctions";

const initialState = {};
const defaultCourseState = {
    completion: false,
    score: 0,
    viewedPercentage: 0,
    completionDate: null,
    timeSpent: 0,
    viewAttempts: 0,
    subStates: {},
    lastPosition: 0,
    gamePoints: 0,
    totalPoints: 0
};
const defaultCourseHistory = {
    f: {},
    y: {},
    q: {},
    m: {},
};

const CoursesReducer = (state = initialState, action) => {
    let newState, targetCourse, currentCourse;
    switch (action.type) {
        /* istanbul ignore next - react-persist function */
        case REHYDRATE:
            let courses = action.payload.courses;
            if (courses) {
                return { ...state, ...courses };
            } else {
                return state;
            }

        case FETCH_LIBRARY:
            return Object.assign(
                {},
                _.mapValues(action.data.courses, course => {
                    let newTopicsState = {};
                    _.mapValues(course.locations, location => {
                        if (location.contentList.length > 0) {
                            newTopicsState[location._id] = false;
                        }
                    });
                    return Object.assign({}, course, {
                        lastPosition: course.currentState
                            ? course.currentState.lastPosition
                                ? course.locations.length > 1
                                    ? course.currentState.lastPosition ===
                                        course.locations.length
                                        ? course.currentState.lastPosition - 1
                                        : course.currentState.lastPosition
                                    : 0
                                : state[course._id] && state[course._id].lastPosition ? state[course._id].lastPosition : 0
                            : 0,
                        currentState:
                            course.currentState === undefined
                                ? Object.assign({}, defaultCourseState, {
                                    subStates: newTopicsState
                                })
                                : Object.assign({}, course.currentState, {
                                    subStates: {
                                        ...newTopicsState,
                                        ...course.currentState.subStates
                                    }
                                }),
                        history:
                            course.history === undefined
                                ? defaultCourseHistory
                                : course.history
                    });
                })
            );
        case SET_LAST_LOCATION:
            newState = _.cloneDeep(state);
            targetCourse = _.find(state, {
                _id: action.data.courseId
            });
            currentCourse = _.cloneDeep(targetCourse);
            currentCourse.lastPosition = action.data.location;
            newState[action.data.courseId] = currentCourse;
            return newState;
        case SUBMIT_RATING:
            newState = _.cloneDeep(state);
            targetCourse = _.find(state, {
                _id: action.data.courseId
            });
            currentCourse = _.cloneDeep(targetCourse);
            currentCourse.currentState.rating = action.data.rating;
            newState[action.data.courseId] = currentCourse;
            return newState;
        case AGGREGATE_COURSES:
            return aggregateCourseStateAndHistory(state, action.data);
        case AGGREGATE_SCORM:
            return scorm_aggregation(state, action);
        default:
            return state;
    }
};

export { CoursesReducer };
