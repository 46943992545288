import React from "react";
import PropTypes from "prop-types";
import { getAppConfig } from "config/client.config";
import { VelocityTransitionGroup } from "velocity-react";
class Text extends React.Component {
    static propTypes = {
        keyName: PropTypes.string,
        color: PropTypes.string,
        fontSize: PropTypes.string,
        animation: PropTypes.string,
        text: PropTypes.string,
        isHeader: PropTypes.bool,
        textAlign: PropTypes.string,
    };

    static defaultProps = {
        color: "#000",
        fontSize: "1em",
        animation: "fadeIn, 500",
        isHeader: false,
    };

    render() {
        const isPortrait = window.innerHeight > window.innerWidth;
        let fontSize = "1em";
        /* istanbul ignore next - Request and Pure passthrough */
        switch (this.props.fontSize) {
            case "small":
                fontSize = 0.7;
                break;
            case "normal":
            case "medium":
                fontSize = 1.15;
                break;
            case "large":
                fontSize = 1.5;
                break;
            case "big":
            case "bigger":
                fontSize = 1.85;
                break;
            case "huge":
                fontSize = 2.2;
                break;
            case "huger":
                fontSize = 2.8;
                break;
            case "ultra":
                fontSize = 3.5;
                break;
            default:
                fontSize = 1;
        }
        let mediaAdjustmentStyle = {
            ...this.props.style,
            fontSize:
                /* istanbul ignore next - Request and Pure passthrough */
                window.innerWidth < 400 ? "0.9em" : "1em",
        };
        let textStyle = {
            ...getAppConfig().textStyle,
            lineHeight: "1.1",
            color: this.props.color,
            fontSize: fontSize + "em",
            textAlign: this.props.textAlign,
            fontWeight: "500",
        };
        let headerStyle = {
            ...getAppConfig().headerStyle,
            lineHeight: "1.1",
            color: this.props.color,
            fontSize: isPortrait
                ? fontSize * 0.75 + "em"
                : fontSize * 0.8 + "em",
        };

        let animation = this.props.animation.split(",");
        return (
            <div style={mediaAdjustmentStyle}>
                <VelocityTransitionGroup
                    enter={{
                        animation: animation[0],
                        duration: animation[1],
                        delay: animation[2],
                    }}
                    leave={{
                        animation: "fadeOut",
                        duration: "1",
                        translateY: "-40px",
                    }}
                    runOnMount
                >
                    <div
                        key={this.props.keyName}
                        style={
                            /* istanbul ignore next - Request and Pure passthrough */
                            this.props.isHeader ? headerStyle : textStyle
                        }
                    >
                        {this.props.text}
                    </div>
                </VelocityTransitionGroup>
            </div>
        );
    }
}

export default Text;
