import _ from "lodash";
import { REHYDRATE } from "redux-persist/constants";
import {
    FETCH_LIBRARY,
    AGGREGATE_TOPICS,
} from "appRedux/helpers/reduxConstants";
import { aggregateTopicStateAndHistory } from "appRedux/helpers/aggregatorFunctions";

const initialState = {};
const defaultTopicState = {
    completion: false,
    viewedPercentage: 0,
    timeSpent: 0,
    points: 0,
    viewAttempts: 0,
    subStates: {},
};
const defaultTopicHistory = {
    f: {},
    y: {},
    q: {},
    m: {},
};

const TopicsReducer = (state = initialState, action) => {
    switch (action.type) {
        /* istanbul ignore next - react-persist function */
        case REHYDRATE:
            let topics = action.payload.topics;
            if (topics) {
                return { ...state, ...topics };
            } else {
                return state;
            }

        case FETCH_LIBRARY:
            return Object.assign(
                {},
                _.mapValues(action.data.topics, (topic) => {
                    let newDecksState = {};
                    _.map(topic.contentList, (deck) => {
                        newDecksState[deck._id] = false;
                    });
                    return Object.assign({}, _.omit(topic, "contentList"), {
                        currentState:
                            topic.currentState === undefined
                                ? Object.assign({}, defaultTopicState, {
                                    subStates: newDecksState,
                                })
                                : Object.assign({}, topic.currentState, {
                                    subStates: {
                                        ...newDecksState,
                                        ...topic.currentState.subStates,
                                    },
                                }),
                        history:
                            topic.history === undefined
                                ? defaultTopicHistory
                                : topic.history,
                    });
                })
            );
        case AGGREGATE_TOPICS:
            return aggregateTopicStateAndHistory(state, action.data);
        default:
            return state;
    }
};

export { TopicsReducer };
