import React from "react";
import PropTypes from "prop-types";
import SlideHeader from "../../contentBlocks/SlideHeader.react";
import SlideHeaderSidebar from "../../contentBlocks/SlideHeaderSidebar.react";
import ConversationGroup from "../../contentBlocks/ConversationGroup.react";
import { Grid } from "semantic-ui-react";

class Conversation extends React.Component {
    static propTypes = {
        data: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            conversation: PropTypes.array,
        }).isRequired,
        colors: PropTypes.object,
    };
    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let defaultStyle = {
            margin: "auto",
            width: "90%",
            textAlign: "center",
            height: "80%",
            paddingTop: "20px",
        };
        let defaultLandscapeStyle = {
            margin: "auto",
            width: "100%",
            textAlign: "center",
            position: "relative",
            float: "right",
        };
        return (
            <div className="full-height">
                {isPortrait ? (
                    <div>
                        <SlideHeader
                            {...this.props}
                            title={this.props.data.title}
                            subtitle={this.props.data.subtitle}
                        />
                        <div style={defaultStyle}>
                            <ConversationGroup
                                {...this.props}
                                colors={this.props.colors}
                                data={this.props.data.conversation}
                                animation="transition.bounceUpIn,400,1500"
                            />
                        </div>
                    </div>
                ) : (
                    <Grid style={{ minHeight: "100%" }}>
                        <Grid.Row style={{ paddingBottom: "0" }}>
                            <Grid.Column
                                width={8}
                                style={{
                                    paddingRight: "0",
                                    background: this.props.colors.primary,
                                }}
                            >
                                <SlideHeaderSidebar
                                    {...this.props}
                                    title={this.props.data.title}
                                    subtitle={this.props.data.subtitle}
                                />
                            </Grid.Column>
                            <Grid.Column width={8} style={{ paddingLeft: "0" }}>
                                <div style={defaultLandscapeStyle}>
                                    <ConversationGroup
                                        {...this.props}
                                        colors={this.props.colors}
                                        data={this.props.data.conversation}
                                        animation="transition.bounceUpIn,400,1500"
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
            </div>
        );
    }
}

export default Conversation;
