import React, { Component } from "react";
import PropTypes from "prop-types";
import ButtonGroup from "../../contentBlocks/ButtonGroup.react";
import SlideHeader from "../../contentBlocks/SlideHeader.react";
import SlideHeaderSidebar from "../../contentBlocks/SlideHeaderSidebar.react";
import { Grid } from "semantic-ui-react";

export default class PopupList extends Component {
    static propTypes = {
        colors: PropTypes.shape({
            base: PropTypes.string.isRequired,
            primary: PropTypes.string.isRequired,
            primaryVariation: PropTypes.string.isRequired,
            secondary: PropTypes.string.isRequired,
            baseText: PropTypes.string.isRequired,
            primaryText: PropTypes.string.isRequired,
            secondaryText: PropTypes.string.isRequired,
        }).isRequired,
        data: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            popupitems: PropTypes.array,
        }).isRequired,
        slideId: PropTypes.number,
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let defaultStyle = {
            margin: "auto",
            width: "80%",
            textAlign: "center",
            paddingTop: "50px",
        };
        let defaultLandscapeStyle = {
            marginTop: "15px",
            padding: "30px",
            fontSize: "1.5em",
            width: "100%",
            textAlign: "center",
            position: "relative",
            float: "right",
        };
        let title =
            this.props.data.header !== undefined
                ? this.props.data.header
                : this.props.data.title;
        let subtitle =
            this.props.data.subheader !== undefined
                ? this.props.data.subheader
                : this.props.data.subtitle;
        let popupitems =
            this.props.data.popupitems !== undefined
                ? this.props.data.popupitems
                : this.props.data.options;
        return (
            <div className="full-height">
                {isPortrait ? (
                    <div>
                        <SlideHeader
                            {...this.props}
                            title={title}
                            subtitle={subtitle}
                        />
                        <div
                            style={
                                isPortrait
                                    ? defaultStyle
                                    : defaultLandscapeStyle
                            }
                        >
                            <ButtonGroup
                                keyName="popupitem"
                                colors={this.props.colors}
                                options={popupitems}
                                extractFrom="option"
                                animation="transition.slideDownIn,500,300"
                                callback={this.props.callback}
                                slideShow={this.props.slideShow}
                            />
                        </div>
                    </div>
                ) : (
                    <Grid style={{ minHeight: "100%" }}>
                        <Grid.Row style={{ paddingBottom: "0" }}>
                            <Grid.Column
                                width={8}
                                style={{
                                    paddingRight: "0",
                                    background: this.props.colors.primary,
                                }}
                            >
                                <SlideHeaderSidebar
                                    {...this.props}
                                    title={title}
                                    subtitle={subtitle}
                                />
                            </Grid.Column>
                            <Grid.Column width={8} style={{ paddingLeft: "0" }}>
                                <div style={defaultLandscapeStyle}>
                                    <ButtonGroup
                                        keyName="popupitem"
                                        colors={this.props.colors}
                                        options={popupitems}
                                        extractFrom="option"
                                        animation="transition.slideDownIn,500,300"
                                        callback={this.props.callback}
                                        slideShow={this.props.slideShow}
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
            </div>
        );
    }
}
