import React from "react";
import PropTypes from "prop-types";
import Text from "../../contentBlocks/Text.react";
import Separator from "../../contentBlocks/Separator.react";
import Caption from "../../contentBlocks/Caption.react";
import SlideHeader from "../../contentBlocks/SlideHeader.react";
import { Grid } from "semantic-ui-react";
import { ComponentInputs } from "schema/FormSchema.react";

export default class ParagraphLine extends React.Component {
    static propTypes = {
        colors: PropTypes.shape({
            base: PropTypes.string.isRequired,
            primary: PropTypes.string.isRequired,
            primaryVariation: PropTypes.string.isRequired,
            secondary: PropTypes.string.isRequired,
            baseText: PropTypes.string.isRequired,
            primaryText: PropTypes.string.isRequired,
            secondaryText: PropTypes.string.isRequired,
        }).isRequired,
        data: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            paragraph: PropTypes.string,
            caption: PropTypes.string,
        }).isRequired,
        slideId: PropTypes.number,
        mobileView: PropTypes.bool,
        desktopView: PropTypes.bool,
    };

    static defaultProps = {
        mobileView: false,
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;

        let defaultStyle = {
            margin: "0 5%",
            width: "90%",
            height: isPortrait ? "auto" : "100%",
            textAlign: isPortrait ? "center" : "left",
            padding: "15px 0",
        };
        let defaultLCaptionStyle = {
            margin: "20px 0 20px 30px",
            width: "100%",
            textAlign: "left",
            padding: "20px",
            background: "#eeeeee",
            fontSize: "0.85em",
        };

        return (
            <div className="full-height">
                {isPortrait ? (
                    <div>
                        <SlideHeader
                            {...this.props}
                            title={this.props.data.title}
                            subtitle={this.props.data.subtitle}
                        />
                        <div style={defaultStyle}>
                            <Text
                                keyName={"paragraphline-" + this.props.slideId}
                                text={this.props.data.paragraph}
                                color={this.props.colors.baseText}
                                animation="transition.slideDownIn,500,250"
                                fontSize={
                                    this.props.data.paragraph &&
                                    this.props.data.paragraph.length >
                                        ComponentInputs.paragraph.idealLength
                                        ? "normal"
                                        : "large"
                                }
                            />
                            <Separator
                                color={this.props.colors.primary}
                                keyName={"separator-" + this.props.slideId}
                                animation="transition.swoopIn,500,500"
                            />
                            <Caption
                                keyName={"caption-" + this.props.slideId}
                                text={this.props.data.caption}
                                colors={this.props.colors}
                                animation="transition.bounceUpIn,500,750"
                                fontSize={
                                    this.props.data.caption &&
                                    this.props.data.caption.length >
                                        ComponentInputs.caption.idealLength
                                        ? "normal"
                                        : "large"
                                }
                                textAlign={"center"}
                            />
                        </div>
                    </div>
                ) : (
                    <Grid>
                        <Grid.Row>
                            <SlideHeader
                                {...this.props}
                                title={this.props.data.title}
                                subtitle={this.props.data.subtitle}
                                noIcon={true}
                            />
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={6}>
                                <div style={defaultLCaptionStyle}>
                                    <Caption
                                        keyName={
                                            "caption-" + this.props.slideId
                                        }
                                        text={this.props.data.caption}
                                        colors={this.props.colors}
                                        animation="transition.bounceUpIn,500,750"
                                        fontSize="normal"
                                        textAlign={"left"}
                                    />
                                </div>
                            </Grid.Column>
                            <Grid.Column width={10}>
                                <div style={defaultStyle}>
                                    <Text
                                        keyName={
                                            "paragraphline-" +
                                            this.props.slideId
                                        }
                                        text={this.props.data.paragraph}
                                        color={this.props.colors.baseText}
                                        animation="transition.slideDownIn,500,250"
                                        fontSize={"large"}
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
            </div>
        );
    }
}
