import React from "react";
import { Card, Image, Divider } from "semantic-ui-react";
import { Button } from "config/initializers/styles.app";

import { getAppConfig } from "config/client.config";
import { resolveImage } from "config/app.helpers";
import { Dic } from "config/initializers/config.translations";

import _ from "lodash";
import PropTypes from "prop-types";

export default class ImageOverlay extends React.Component {
    static propTypes = {
        data: PropTypes.shape({
            image: PropTypes.object,
            paragraph: PropTypes.array,
        }),
        imageLibrary: PropTypes.array,
        selectedIndex: PropTypes.number,
        gotoNextSlide: PropTypes.func,
    };

    render() {
        const dic = Object.assign(
            {},
            Dic[window.lang].common,
            Dic[window.lang].deck,
            Dic[window.lang].overlay
        );
        /* istanbul ignore next - Request and Pure passthrough */
        let { feedback } = this.props.data,
            feedbackArr =
                !feedback || feedback.length === 0
                    ? ["", "", dic.thanks]
                    : feedback;

        let refinedFeedback = _.map(feedbackArr, (fb, index) => {
            var res = {
                header: "",
                text: fb,
                color: "green",
            };
            /* istanbul ignore next - Request and Pure passthrough */
            if (feedback && feedback.length > 0) {
                if (index === 0) {
                    res = {
                        ...res,
                        header: dic.correct,
                        icon: "check circle",
                        color: "green",
                    };
                } else if (index === 1) {
                    res = {
                        ...res,
                        header: dic.incorrect,
                        icon: "remove circle",
                        color: "red",
                    };
                } else {
                    res = {
                        ...res,
                        header: dic.thanks,
                        icon: "thumbs up",
                        color: "yellow",
                    };
                }
            }
            return res;
        });
        let cardStyle = {
            borderRadius: 0,
            boxShadow: "none",
            textAlign: "center",
        };

        let headerStyle = {
            ...getAppConfig().headerStyle,
            fontSize: "4em",
        };
        let textStyle = {
            ...getAppConfig().textStyle,
            fontSize: "2em",
            lineHeight: "1.2",
        };
        let cardList = () => {
            return _.map(refinedFeedback, (feedback, index) => {
                return (
                    <Card
                        className={
                            /* istanbul ignore next - Request and Pure passthrough */
                            this.props.selectedIndex === index ? "show" : "hide"
                        }
                        style={cardStyle}
                        key={"card_" + index}
                    >
                        <Card.Content>
                            <Card.Header style={headerStyle}>
                                {feedback["header"]}
                            </Card.Header>
                            <Image
                                src={resolveImage(
                                    this.props.data.image.id === undefined
                                        ? this.props.data.images
                                        : this.props.data.image.id,
                                    this.props.imageLibrary
                                )}
                            />
                            {(() => {
                                /* istanbul ignore next - Request and Pure passthrough */
                                if (this.props.data.paragraph !== undefined) {
                                    return (
                                        <div>
                                            <Card.Description style={textStyle}>
                                                {this.props.data.paragraph}
                                            </Card.Description>
                                            <Divider fitted />
                                        </div>
                                    );
                                }
                            })()}
                            <Card.Description style={textStyle}>
                                {feedback["text"]}
                            </Card.Description>
                        </Card.Content>
                        <Card.Content extra>
                            <Button onClick={this.props.gotoNextSlide}>
                                {dic.close}
                            </Button>
                        </Card.Content>
                    </Card>
                );
            });
        };
        return (
            <div>
                <Card.Group>{cardList()}</Card.Group>
            </div>
        );
    }
}
