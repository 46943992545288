// Import from NPM
// -------------------------------------
import React from "react";
import { Image, Loader } from "semantic-ui-react";
import { getAppConfig } from "config/client.config";
import { connect } from "react-redux";
import { Dic } from "config/initializers/config.translations";
class Refreshing extends React.Component {
    render() {
        const dic = Dic[this.props.auth.user.lang].common;
        let isPortrait = window.innerHeight < window.innerWidth;
        let wrapperStyle = {
            height: "100vh",
            width: isPortrait ? "100%" : "100vw",
            background: "#ce0c1c",
            position: "relative",
            overflow: "hidden",
        };
        let logoStyle = {
            position: "absolute",
            left: isPortrait ? "50%" : "50%",
            top: "15%",
            transform: "translate(-50%,0)",
            width: "20%",
            maxWidth: "128px",
        };
        let swishStyle = {
            position: "absolute",
            left: "-10vw",
            bottom: "-10%",
            width: "120vw",
            height: "30%",
            background: "#454545",
            transform: "rotate(10deg)",
        };
        return (
            <div style={wrapperStyle}>
                {!getAppConfig().inductor && (
                    <Image src={getAppConfig().ui.iconlogo} style={logoStyle} />
                )}
                <Loader
                    size="massive"
                    active
                    inverted
                    style={{ marginleft: "150px" }}
                >
                    {!this.props.inGame && !this.props.offline && (
                        <p>{dic.refreshing}</p>
                    )}
                </Loader>
                <div style={swishStyle} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(Refreshing);
