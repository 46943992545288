import React from "react";
import PropTypes from "prop-types";
import { Button } from "config/initializers/styles.app";
import { Card, Icon } from "semantic-ui-react";

import { getAppConfig } from "config/client.config";
import { Dic } from "config/initializers/config.translations";
import _ from "lodash";

export default class LinkOverlay extends React.Component {
    static propTypes = {
        data: PropTypes.object,
        gotoNextSlide: PropTypes.func,
        gotoSlide: PropTypes.func,
        selectedIndex: PropTypes.number,
    };
    render() {
        const dic = Object.assign(
            {},
            Dic[window.lang].common,
            Dic[window.lang].deck,
            Dic[window.lang].overlay
        );
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let refinedFeedback = _.map(this.props.data.feedback, (fb, index) => {
            var res = {
                header: "",
                icon: "trophy",
                text: fb,
                color: "green",
            };
            if (index === 0 || index === 2) {
                res = {
                    ...res,
                    header: dic.correct,
                    icon: "check circle",
                    color: "green",
                };
                res["btn"] = (
                    <Button
                        onClick={() => {
                            this.props.gotoNextSlide();
                        }}
                    >
                        {dic.close}
                    </Button>
                );
            } else if (index === 1) {
                res = {
                    ...res,
                    header: dic.incorrect,
                    icon: "remove circle",
                    color: "red",
                };
                res["btn"] = (
                    <Button
                        onClick={() => {
                            this.props.gotoSlide(
                                this.props.data.slideLink,
                                true
                            );
                        }}
                    >
                        {dic.knowmore}
                    </Button>
                );
            }

            return res;
        });

        let cardStyle = {
            borderRadius: 0,
            boxShadow: "none",
            textAlign: "center",
            width: isPortrait ? "100%" : "50%",
            margin: "0 auto",
            padding: "30px",
        };
        let headerStyle = {
            ...getAppConfig().headerStyle,
            fontSize: "4em",
        };
        let textStyle = {
            ...getAppConfig().textStyle,
            fontSize: "1.5em",
            lineHeight: "1.1",
        };

        let cardList = () => {
            return _.map(refinedFeedback, (feedback, index) => {
                let visible =
                    this.props.selectedIndex === index ? "show" : "hide";

                return (
                    <Card
                        className={visible}
                        key={"card_" + index}
                        style={cardStyle}
                    >
                        <Card.Content>
                            <Card.Header style={headerStyle}>
                                {feedback["header"]}
                            </Card.Header>
                            <Card.Description style={textStyle}>
                                <Icon
                                    name={feedback["icon"]}
                                    size={"huge"}
                                    color={feedback["color"]}
                                />
                                <br />
                                <br />
                                {feedback["text"]}
                            </Card.Description>
                        </Card.Content>
                        <Card.Content extra>
                            <div className="ui two buttons">
                                {feedback["btn"]}
                            </div>
                        </Card.Content>
                    </Card>
                );
            });
        };
        return (
            <div>
                <Card.Group>{cardList()}</Card.Group>
            </div>
        );
    }
}
