import React, { Component } from "react";
import _ from "lodash";
import { List, Icon, Dropdown } from "semantic-ui-react";
import { menuNavigator } from "config/settings/learning_center.settings";
import { getAppConfig } from "config/client.config";
import { hashHistory } from "react-router";
export class MenuNavigator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTagParent: null,
        };
    }
    openTag = (tag) => {
        this.setState({ selectedTagParent: tag });
        if (tag.firstChild)
            this.selectTags([tag.name, tag.firstChild.name]);
    };
    openEmptyTag = (tag) => {
        if (!this.props.articleTag) {
            if (tag.firstChild)
                this.props.selectTags(tag.firstChild.name)
        }
        else {
            if (tag.firstChild)
                this.selectTags([tag.name, tag.firstChild.name]);
        }
    };

    openTagDesktop = (tag) => {
        this.setState({ selectedTagParent: tag });
    };

    selectTags = (tags) => {
        let urlArray = [];
        if (this.props.location.query.category)
            urlArray.push("category=" + this.props.location.query.category);
        if (this.props.location.query.search)
            urlArray.push("search=" + this.props.location.query.search);
        urlArray.push("tags=" + tags.join(","));
        hashHistory.push("/articles?" + urlArray.join("&"));
    };
    clearTags = () => {
        let currentLocation = hashHistory.getCurrentLocation();
        let urlArray = [];
        if (this.props.location.query.category)
            urlArray.push("category=" + this.props.location.query.category);
        if (this.props.location.query.search)
            urlArray.push("search=" + this.props.location.query.search);

        hashHistory.push(
            currentLocation.pathname +
            (urlArray.length > 0 ? "?" : "") +
            urlArray.join("&")
        );
    };
    render() {
        let listItem = {
            lineHeight: "1.5",
            padding: "0 10px",
        };
        let activeStyle = {
            color: "#000000",
        };
        let inactiveStyle = {
            color: "#666666",
        };
        let subtagStyle = {
            borderLeft: "1px solid #cccccc",
            fontSize: "0.9em",
        };
        let subActiveTagStyle = {
            borderLeft: "1px solid #cccccc",
            fontSize: "0.9em",
            background: "#e5e5e5",
        };
        let queryData = Object.keys(this.props.location.query)
        let queryParams = queryData.length === 0 || queryData[0] === 'tags' || queryData[0] === 'search'
            ? getAppConfig().learningCenter.profileList.enabledLearn
            : this.props.location.query;
        let categoryTags = {};
        let isEmpty;
        let category = queryParams.category ? queryParams.category : "";
        if (category) {
            let modifiedTags = _.map(this.props.tags, (t) => {
                return {
                    _id: t._id,
                    name: t.name,
                    parent: t.parent != null ? t.parent._id : "0",
                };
            });
            const combinedTags = _.union(
                [
                    {
                        _id: "0",
                        name: "All tags...",
                        parent: null,
                    },
                ],
                modifiedTags
            );
            const combinedTagTree = treeify(combinedTags, null);
            const navigationTree = _.assign(
                {},
                menuNavigator,
                getAppConfig().learningCenter.menuNavigator
            );
            const menu = ['article', 'document', 'event', 'faq', 'news', 'video', 'webinar', 'gallery', 'tutorial', 'assessment', 'survey', 'game']
            const navTree = _.find(combinedTagTree[0].children, {
                name: navigationTree[queryParams.category],
            });
            let learn = _.map(menu, m => {
                let learnMenu = _.find(combinedTagTree[0].children, {
                    name: navigationTree[m],
                })
                return learnMenu ? learnMenu.children : ""
            });
            let learnMenuTag = _.flatten(_.uniq(learn));
            let filteredTag = _.filter(learnMenuTag, tag => {
                if (tag) {
                    return tag.children ?
                        tag : ""
                }
            })
            categoryTags = this.props.selectTag ? { children: filteredTag.slice(0, 16) } : navTree;
            isEmpty = Object.values(learnMenuTag).every(x => (x === undefined || x === ''));
        }
        if (categoryTags == null || isEmpty) {
            let allArticles = this.props.articles;
            let allArticleTags = _.uniqBy(
                _.flatten(_.map(allArticles, "tags")),
                "name"
            );
            categoryTags = {
                children: _.map(allArticleTags, (t) => {
                    return {
                        id: t._id,
                        name: t.name,
                        firstChild: t,
                    };
                }),
            };
        } else {
            let articleCategoryFilteredTags = _.uniq(
                _.flatten(
                    _.map(
                        _.filter(this.props.blog.articles, (article) => {
                            return this.props.selectTag
                                ? article
                                : queryParams.category === article.category;
                        }),
                        (article) => {
                            return _.map(article.tags, "name");
                        }
                    )
                )
            );

            categoryTags = {
                children: _.filter(
                    _.map(categoryTags.children, (t) => {
                        let availableChildren
                        if (t) {
                            availableChildren = _.filter(t.children, (tag) => {
                                return _.includes(
                                    articleCategoryFilteredTags,
                                    tag.name
                                );
                            });
                            if (
                                (this.state.selectedTagParent &&
                                    this.state.selectedTagParent.id === t._id) ||
                                (!this.state.selectedTagParent &&
                                    queryParams.tags &&
                                    queryParams.tags.includes(t.name))
                            )
                                return {
                                    id: t._id,
                                    name: t.name,
                                    children: availableChildren,
                                };
                            else if (availableChildren.length > 0) {
                                return {
                                    id: t._id,
                                    name: t.name,
                                    firstChild: availableChildren[0],
                                };
                            } else return null;
                        }
                    }),
                    (child) => child != null
                ),
            };
        }

        return (
            <div>
                {!this.props.selectTag ?
                    <List divided relaxed style={{ cursor: "pointer" }}>
                        {this.props.menuNavigation &&
                            <div>
                                <Icon
                                    name="close"
                                    onClick={this.props.closeTagsList}
                                    style={{
                                        float: "right"
                                    }}
                                />
                            </div>
                        }
                        <List.Item
                            key={Math.random()}
                            onClick={this.clearTags}
                            style={
                                queryData.length !== 0 ? queryParams.tags || this.state.selectedTagParent
                                    ? inactiveStyle
                                    : activeStyle
                                    : activeStyle
                            }
                        >
                            <List.Content style={listItem}>All</List.Content>
                        </List.Item>
                        {_.map(categoryTags.children, (tag) => {
                            return (
                                <List.Item
                                    key={Math.random()}
                                    style={tag.children ? activeStyle : inactiveStyle}
                                >
                                    <List.Content style={listItem}>
                                        <span onClick={() => this.props.menuNavigation
                                            ? !isEmpty ? this.openTagDesktop(tag) : this.openEmptyTag(tag)
                                            : this.openTag(tag)}>
                                            {tag.name}
                                        </span>
                                        {tag.children && (
                                            <List
                                                divided
                                                style={{
                                                    cursor: "pointer",
                                                }}
                                            >
                                                {_.map(tag.children, (subtag) => {
                                                    return (
                                                        <List.Item
                                                            key={Math.random()}
                                                            onClick={() =>
                                                                this.selectTags([
                                                                    tag.name,
                                                                    subtag.name,
                                                                ])
                                                            }
                                                            style={
                                                                !this.props.menuNavigation
                                                                    ? queryParams.tags.includes(
                                                                        subtag.name
                                                                    )
                                                                        ? subActiveTagStyle
                                                                        : subtagStyle
                                                                    : subtagStyle
                                                            }
                                                        >
                                                            <List.Content
                                                                style={listItem}
                                                            >
                                                                {subtag.name}
                                                            </List.Content>
                                                        </List.Item>
                                                    );
                                                })}
                                            </List>
                                        )}
                                    </List.Content>
                                </List.Item>
                            );
                        })}
                    </List>
                    :
                    <Dropdown icon="">
                        <Dropdown.Menu>
                            <Dropdown.Item
                                key={Math.random()}
                                onClick={this.clearTags}
                                id="all-menu-Item"
                            >
                                All
                            </Dropdown.Item>
                            {_.map(categoryTags.children, (tag, index) => {
                                return (
                                    <Dropdown.Item key={'tags-' + index}
                                        id="menu-Item"
                                    >
                                        <Dropdown
                                            onClick={() => !isEmpty ? this.openTagDesktop(tag) : this.openEmptyTag(tag)}
                                            text={tag.name}
                                            pointing="left"
                                            className="ui dropdown dropdownMenu"
                                        >
                                            <Dropdown.Menu
                                                id="dropdown-menu"
                                            >
                                                {_.map(tag.children, (subtag, idx) => {
                                                    return (
                                                        <Dropdown.Item
                                                            style={{ background: "#ffffff" }}
                                                            key={'idx-' + idx}
                                                            onClick={() =>
                                                                this.props.selectTags([
                                                                    tag.name,
                                                                    subtag.name,
                                                                ])
                                                            }
                                                        >
                                                            {subtag.name}
                                                        </Dropdown.Item>
                                                    )
                                                })
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Dropdown.Item>
                                )
                            })
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                }
            </div>
        );
    }
}

function treeify(arr, parent) {
    var out = [];
    for (var i in arr) {
        if (arr[i].parent === parent) {
            var children = treeify(arr, arr[i]._id);
            if (children.length) {
                arr[i].children = children;
            }
            out.push(_.omit(arr[i], ["parent"]));
        }
    }
    return out;
}
