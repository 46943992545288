import _ from "lodash";

const defaultSeed = {
    array: [
        "<< List item 1. Replace with your own text >>",
        "<< List item 2. Replace with your own text >>",
        "<< List item 3. Replace with your own text >>",
        "<< List item 4. Replace with your own text >>",
    ],
};

let maxLengths = {
    tiny: 60,
    short: 160,
    normal: 280,
    long: 420,
    verbose: 800,
    infinite: 999,
};

let idealLengths = {
    tiny: 40,
    short: 80,
    normal: 128,
    long: 256,
    verbose: 400,
    infinite: 999,
};

export const ComponentInputs = {
    title: {
        type: "text",
        name: "Title",
        maxLength: maxLengths.tiny,
        idealLength: idealLengths.tiny,
    },
    voiceover: {
        type: "audio",
        name: "Voiceover",
        maxLength: maxLengths.tiny,
        idealLength: idealLengths.tiny,
    },
    tts_audio: {
        type: "audio",
        name: "Voiceover",
        maxLength: maxLengths.long,
        idealLength: idealLengths.long,
    },
    header: {
        type: "text",
        name: "Header",
        maxLength: maxLengths.tiny,
        idealLength: idealLengths.tiny,
    },
    caption: {
        type: "text",
        name: "Caption",
        maxLength: maxLengths.normal,
        idealLength: idealLengths.normal,
    },
    icon: { type: "icon", name: "Icon" },
    subtitle: {
        type: "text",
        name: "Subtitle",
        maxLength: maxLengths.short,
        idealLength: idealLengths.short,
    },
    subheader: {
        type: "text",
        name: "Subheader",
        maxLength: maxLengths.short,
        idealLength: idealLengths.short,
    },
    question: {
        type: "text",
        name: "Question",
        maxLength: maxLengths.normal,
        idealLength: idealLengths.normal,
    },
    answer: {
        type: "text",
        name: "Answer",
        maxLength: maxLengths.short,
        idealLength: idealLengths.tiny,
    },
    link: {
        type: "text",
        name: "Link",
        maxLength: maxLengths.infinite,
        idealLength: idealLengths.infinite,
    },
    presenter: { type: "image", name: "Presenter Character", aspectRatio: 1 },
    brancher: {
        type: "brancher",
        name: "Select Branches",
        maxLength: maxLengths.normal,
        idealLength: idealLengths.normal,
        addable: true,
    },
    video: {
        type: "text",
        name: "Video URL",
        maxLength: maxLengths.normal,
        idealLength: idealLengths.normal,
    },
    paragraph: {
        type: "textarea",
        name: "Paragraph",
        maxLength: maxLengths.long,
        idealLength: idealLengths.long,
    },
    splash: {
        type: "textarea",
        name: "Splash",
        maxLength: maxLengths.verbose,
        idealLength: idealLengths.verbose,
    },
    blurb: {
        type: "textarea",
        name: "Blurb",
        maxLength: maxLengths.normal,
        idealLength: idealLengths.normal,
    },
    image: { type: "image", name: "Image", aspectRatio: 0.75 },
    background: { type: "image", name: "Image", aspectRatio: 1.33 },
    bullets: {
        type: "bullets",
        name: "List Items",
        maxLength: maxLengths.normal,
        idealLength: idealLengths.short,
        addable: true,
    },
    rebullets: {
        type: "bullets",
        name: "List Items",
        maxLength: maxLengths.normal,
        idealLength: idealLengths.short,
        addable: true,
    },
    iconlist: {
        type: "imagelist",
        name: "List Items",
        maxLength: maxLengths.normal,
        idealLength: idealLengths.short,
        aspectRatio: 1,
        addable: true,
    },
    diptych: {
        type: "imagelist",
        name: "List Items",
        maxLength: maxLengths.normal,
        idealLength: idealLengths.normal,
        aspectRatio: 0.75,
        addable: false,
    },
    triptych: {
        type: "imagelist",
        name: "List Items",
        maxLength: maxLengths.normal,
        idealLength: idealLengths.normal,
        aspectRatio: 0.75,
        addable: false,
    },
    grid: {
        type: "imagelist",
        name: "List Items",
        maxLength: maxLengths.normal,
        idealLength: idealLengths.normal,
        aspectRatio: 0.75,
        addable: false,
    },
    cards: {
        type: "imagelist",
        name: "List Items",
        maxLength: maxLengths.normal,
        idealLength: idealLengths.short,
        aspectRatio: 0.75,
        addable: true,
    },
    conversation: {
        type: "imagelist",
        name: "Conversation",
        maxLength: maxLengths.normal,
        idealLength: idealLengths.short,
        aspectRatio: 1,
        addable: true,
    },
    popupitems: {
        type: "optionimages",
        name: "Options",
        maxLength: maxLengths.normal,
        idealLength: idealLengths.short,
        aspectRatio: 0.75,
        addable: true,
    },
    pdf: { type: "pdf", name: "Upload PDF" },
    options: {
        type: "radiolist",
        name: "Options",
        maxLength: maxLengths.normal,
        idealLength: idealLengths.normal,
        addable: true,
    },
    choice: {
        type: "radiolist",
        name: "Choice",
        maxLength: maxLengths.normal,
        idealLength: idealLengths.normal,
        addable: false,
    },
    multiselect: {
        type: "checklist",
        name: "Options",
        maxLength: maxLengths.normal,
        idealLength: idealLengths.normal,
        addable: true,
    },
    imageOpts: {
        type: "imgChecklist",
        name: "Options",
        aspectRatio: 0.75,
        addable: false,
    },
    feedback: {
        type: "feedback",
        name: "Feedback",
        maxLength: maxLengths.normal,
        idealLength: idealLengths.normal,
        addable: false,
    },
    purpose: {
        type: "select",
        name: "Select Purpose",
        options: ["Provide Insight", "Test Understanding"],
    },
    pattern: {
        type: "select",
        name: "Select Pattern",
        options: ["basic", "disced"],
    },
    position: {
        type: "select",
        name: "Select Text Position",
        options: [
            "top left",
            "top center",
            "top right",
            "center left",
            "center center",
            "center right",
            "bottom left",
            "bottom center",
            "bottom right",
        ],
    },
    layout: {
        type: "select",
        name: "Select Image Position",
        options: ["side by side full", "side by side split"],
    },
    theme: {
        type: "select",
        name: "Select Theme",
        options: ["normal", "inverted"],
    },
    filter: {
        type: "select",
        name: "Select Filter",
        options: [
            "None",
            "Blur",
            "Brighten",
            "Contrast",
            "Rotate-Hue",
            "Invert",
            "Saturate",
            "Greyscale",
            "Sepia",
        ],
    },
    slideLink: { type: "slideselect", name: "Select Slide" },
};

export const ComponentDefaults = {
    title: "<< Please put in a title >>",
    tts_audio: "",
    caption: "<< Please put in a caption >>",
    icon: "icm icon-wall",
    subtitle: "<< Please put in a subtitle >>",
    question: "<< Please put in a question >>",
    answer: "Answer",
    video: "https://www.youtube.com/watch?v=NpEaa2P7qZI",
    pdf: "",
    link: "https://www.quodeck.com",
    paragraph: "<< Please put in a block of text >>",
    splash: "<< Please put in a block of text >>",
    blurb: "<< Please put in a block of text >>",
    image: "default43",
    presenter: "default43",
    background: "default43",
    bullets: defaultSeed.array,
    rebullets: defaultSeed.array,
    brancher: _.map(defaultSeed.array, (item, idx) => {
        return { text: item, slideLink: 1 };
    }),
    iconlist: _.map(defaultSeed.array, (item) => {
        return { text: item, image: "default11" };
    }),
    diptych: _.map(defaultSeed.array.slice(0, 2), (item) => {
        return { text: item, image: "default43" };
    }),
    triptych: _.map(defaultSeed.array.slice(0, 3), (item) => {
        return { text: item, image: "default43" };
    }),
    grid: _.map(defaultSeed.array.slice(0, 4), (item) => {
        return { text: item, image: "default43" };
    }),
    cards: _.map(defaultSeed.array, (item) => {
        return { text: item, image: "default43" };
    }),
    popupitems: _.map(defaultSeed.array, (item) => {
        return {
            option: item,
            image: "default43",
            caption: "<< Please put in a caption >>",
        };
    }),
    conversation: _.map(defaultSeed.array, (item) => {
        return { text: item, image: "default11" };
    }),
    options: _.map(defaultSeed.array, (item, idx) => {
        return { text: item, correct: idx === 0 ? "checked" : "" };
    }),
    imageOpts: _.map(defaultSeed.array.slice(0, 4), (item, idx) => {
        return { image: "default43", correct: idx === 0 ? "checked" : "" };
    }),
    choice: _.map(defaultSeed.array.slice(0, 2), (item, idx) => {
        return { text: item, correct: idx === 0 ? "checked" : "" };
    }),
    multiselect: _.map(defaultSeed.array, (item, idx) => {
        return { text: item, correct: idx === 0 ? "checked" : "" };
    }),
    slideLink: 1,
    feedback: ["That was correct", "That was wrong", "Thanks for the answer"],
    purpose: ComponentInputs.purpose.options[0],
    layout: ComponentInputs.purpose.options[0],
    pattern: ComponentInputs.pattern.options[0],
    theme: ComponentInputs.theme.options[0],
    filter: ComponentInputs.filter.options[0],
    position: ComponentInputs.position.options[0],
    single_select: "Choose one of the options",
    odd_one_out: "Pick the Odd One out",
    true_false: "Choose one of the options",
    know_more_single_select: "Choose one of the options",
    pick_picture: "Choose the correct picture",
    anagram: "Unscramble the answer",
    open_answer: "Tell us something",
    multiple_select: "Choose one or more options",
    fill_in_the_blanks: "Fill in the blank",
    detailer: "Fill in the blank",
    picture_fuddle: "Can you identify this",
    feedback_paragraph: "Can you identify this",
    rank_them_right: "Rank these options logically",
    timeliner: "Rank chronologically",
    uploader: "Please upload a file "
};

// const sampleSentences = [
//     {
//         length: "40",
//         text: "Welcome, friend, to a story without end.",
//     },
//     {
//         length: "60",
//         text: "Welcome, friend, to a story with neither beginning nor end. ",
//     },
//     {
//         length: "80",
//         text:
//             "He smiled, slipped the ring back into his pocket and stepped out into the cold. ",
//     },
//     {
//         length: "128",
//         text:
//             "Ulrich Kunsi set off again, walking as a hunter, bent over, looking for tracks, and saying to his dog: 'Seek, old fellow, seek!'",
//     },
//     {
//         length: "160",
//         text:
//             "And as he sat thus, at once busy and absent, he was startled to his feet. A flash of ice, a flash of fire, a bursting gush of blood, went over him in an instant",
//     },
//     {
//         length: "256",
//         text:
//             "I kept quite still and said nothing. For a whole hour I didn't move a muscle, and in the meantime I did not hear him lie down. He was still sitting up in the bed listening, just as I have done, night after night, hearkening to the death watches in the wall",
//     },
//     {
//         length: "280",
//         text:
//             "Pa stood there, big, hard, brown-skinned, and mighty beside of Professor Herbert. I didn't know Pa was so much bigger and harder. I'd never seen Pa in a schoolhouse before. I'd seen Professor Herbert. He'd always looked big before to me. He did not look big standing beside of Pa.",
//     },
//     {
//         length: "400",
//         text:
//             "To a night of unrest succeeded a day of expectation and disappointment, but on the day after, as I wandered aimlessly about the neighborhood, I met her. I did not repeat my folly of uncovering, nor venture so much as too long a look to manifest an interest in her; yet my heart was beating audibly. I trembled and colored as she turned her big black eyes upon me with a look of obvious recognition...",
//     },
//     {
//         length: "420",
//         text:
//             "Rip's story was soon told, for the whole twenty years had been to him but as one night. The neighbors stared when they heard it; some were seen to wink at each other, and put their tongues in their cheeks: and the self-important man in the cocked hat, who, when the alarm was over, had returned to the field, screwed down the corners of his mouth, and shook his head - upon which there was a general shaking of the head.",
//     },
//     {
//         length: "800",
//         text:
//             "From my infancy I was noted for the docility and humanity of my disposition. My tenderness of heart was even so conspicuous as to make me the jest of my companions. I was especially fond of animals, and was indulged by my parents with a great variety of pets. With these I spent most of my time, and never was so happy as when feeding and caressing them. This peculiarity of character grew with my growth, and in my manhood, I derived from it one of my principal sources of pleasure. To those who have cherished an affection for a faithful and sagacious dog, I need hardly be at the trouble of explaining the nature or the intensity of the gratification thus derivable. There is something in the unselfish and self-sacrificing love of a brute, which goes directly to the heart of him who has lost all",
//     },
// ];
