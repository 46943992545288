import _ from "lodash";

import None from "slides/slideTemplates/normalTemplates/None.react";
import Blurb from "slides/slideTemplates/normalTemplates/Blurb.react";
import Conversation from "slides/slideTemplates/normalTemplates/Conversation.react";
import Title from "slides/slideTemplates/normalTemplates/Title.react";
import TitleSubtitle from "slides/slideTemplates/normalTemplates/TitleSubtitle.react";
import Splash from "slides/slideTemplates/normalTemplates/Splash.react";
import Paragraph from "slides/slideTemplates/normalTemplates/Paragraph.react";
import ParagraphLine from "slides/slideTemplates/normalTemplates/ParagraphLine.react";
import BulletList from "slides/slideTemplates/normalTemplates/BulletList.react";
import LineBulletList from "slides/slideTemplates/normalTemplates/LineBulletList.react";
import DoDontList from "slides/slideTemplates/normalTemplates/DoDontList.react";
import IconList from "slides/slideTemplates/normalTemplates/IconList.react";
import IconListTimeline from "slides/slideTemplates/normalTemplates/IconListTimeline.react";
import IconListGallery from "slides/slideTemplates/normalTemplates/IconListGallery.react";
import IconListCaptions from "slides/slideTemplates/normalTemplates/IconListCaptions.react";
import ImageCaption from "slides/slideTemplates/normalTemplates/ImageCaption.react";
import Image from "slides/slideTemplates/normalTemplates/Image.react";
import ImageZoom from "slides/slideTemplates/normalTemplates/ImageZoom.react";
import BackgroundImage from "slides/slideTemplates/normalTemplates/BackgroundImage.react";
import FillInTheBlank from "slides/slideTemplates/questionTemplates/FillInTheBlank.react";
import Anagram from "slides/slideTemplates/questionTemplates/Anagram.react";
import Cards from "slides/slideTemplates/normalTemplates/Cards.react";
import Gallery from "slides/slideTemplates/normalTemplates/Gallery.react";
import OpenAnswer from "slides/slideTemplates/questionTemplates/OpenAnswer.react";
import Detailer from "slides/slideTemplates/questionTemplates/Detailer.react";
import SingleSelect from "slides/slideTemplates/questionTemplates/SingleSelect.react";
import KnowMoreSingleSelect from "slides/slideTemplates/questionTemplates/KnowMoreSingleSelect.react";
import OddOneOut from "slides/slideTemplates/questionTemplates/OddOneOut.react";
import TrueFalse from "slides/slideTemplates/questionTemplates/TrueFalse.react";
import MultipleSelect from "slides/slideTemplates/questionTemplates/MultipleSelect.react";
import Diptych from "slides/slideTemplates/normalTemplates/Diptych.react";
import Triptych from "slides/slideTemplates/normalTemplates/Triptych.react";
import Grid from "slides/slideTemplates/normalTemplates/Grid.react";
import PopupList from "slides/slideTemplates/normalTemplates/PopupList.react";
import Video from "slides/slideTemplates/normalTemplates/Video.react";
import VideoFullTitle from "slides/slideTemplates/normalTemplates/VideoFullTitle.react";
import VideoFullParagraph from "slides/slideTemplates/normalTemplates/VideoFullParagraph.react";
import VideoCaption from "slides/slideTemplates/normalTemplates/VideoCaption.react";
import ExternalLink from "slides/slideTemplates/normalTemplates/ExternalLink.react";
import InternalLink from "slides/slideTemplates/normalTemplates/InternalLink.react";
import Brancher from "slides/slideTemplates/normalTemplates/Brancher.react";
import PickPicture from "slides/slideTemplates/questionTemplates/PickPicture.react";
import FeedbackParagraph from "slides/slideTemplates/questionTemplates/FeedbackParagraph.react";
import PictureFuddle from "slides/slideTemplates/questionTemplates/PictureFuddle.react";
import Timeliner from "slides/slideTemplates/questionTemplates/Timeliner.react";
import AnimatedBulletList from "slides/slideTemplates/normalTemplates/AnimatedBulletList.react";
import RankThemRight from "slides/slideTemplates/questionTemplates/RankThemRight.react";
import PdfViewer from "slides/slideTemplates/normalTemplates/PdfViewer.react";

import ImageOverlay from "slides/overlayTemplates/ImageOverlay.react";
import FeedbackCard from "slides/overlayTemplates/FeedbackCard.react";
import LinkOverlay from "slides/overlayTemplates/LinkOverlay.react";
import ImageWithCaption from "slides/overlayTemplates/ImageWithCaption.react";
import FileUploader from "slides/slideTemplates/questionTemplates/FileUploader.react";
import IconCaption from "slides/slideTemplates/normalTemplates/IconCaption.react";

const TemplateList = {
    none: {
        key: "none",
        name: "None",
        template: None,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: false, interactive: false },
        components: []
    },
    title: {
        key: "title",
        name: "Title",
        template: Title,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: false, interactive: false },
        components: ["icon", "title", "presenter"]
    },
    title_subtitle: {
        key: "title_subtitle",
        name: "Title with Subtitle",
        template: TitleSubtitle,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: false, interactive: false },
        components: ["icon", "title", "subtitle"]
    },
    splash: {
        key: "splash",
        name: "Splash",
        template: Splash,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: false, interactive: false },
        components: ["splash"]
    },
    blurb: {
        key: "blurb",
        name: "Blurb",
        template: Blurb,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: false, interactive: false },
        components: ["icon", "title", "subtitle", "blurb", "presenter"]
    },
    paragraph: {
        key: "paragraph",
        name: "Paragraph",
        template: Paragraph,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: false, interactive: false },
        components: ["icon", "title", "subtitle", "paragraph", "presenter"]
    },
    paragraph_line: {
        key: "paragraph_line",
        name: "Captioned Paragraph",
        template: ParagraphLine,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: false, interactive: false },
        components: ["icon", "title", "subtitle", "paragraph", "caption"]
    },
    bulletlist: {
        key: "bulletlist",
        name: "Bullet List",
        template: BulletList,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: false, interactive: false },
        components: ["icon", "title", "subtitle", "bullets"]
    },
    line_bulletlist: {
        key: "line_bulletlist",
        name: "Captioned Bullet List",
        template: LineBulletList,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: false, interactive: false },
        components: ["icon", "title", "subtitle", "caption", "bullets"]
    },
    dodontlist: {
        key: "dodontlist",
        name: "Dos and Donts List",
        template: DoDontList,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: false, interactive: true },
        components: ["title", "subtitle", "bullets", "rebullets"]
    },
    animated_bulletlist: {
        key: "animated_bulletlist",
        name: "Carousel List",
        template: AnimatedBulletList,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: true, interactive: true },
        components: [
            "title",
            "subtitle",
            "bullets",
            "pattern",
            "theme",
            "image",
            "presenter"
        ]
    },
    image: {
        key: "image",
        name: "Image",
        template: Image,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: false },
        components: ["title", "subtitle", "image", "presenter"]
    },
    image_caption: {
        key: "image_caption",
        name: "Captioned Image",
        template: ImageCaption,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: false },
        components: ["title", "subtitle", "image", "caption", "presenter"]
    },
    image_zoom: {
        key: "image_zoom",
        name: "Zoomable Image",
        template: ImageZoom,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: true },
        components: ["title", "subtitle", "image", "caption"]
    },
    background_image: {
        key: "background_image",
        name: "Background Image",
        template: BackgroundImage,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: false },
        components: ["title", "paragraph", "position", "background"]
    },
    iconlist: {
        key: "iconlist",
        name: "Icon Bullet List",
        template: IconList,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: false },
        components: ["icon", "title", "subtitle", "iconlist"]
    },
    iconlist_timeline: {
        key: "iconlist_timeline",
        name: "Icon List Timeline",
        template: IconListTimeline,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: true },
        components: ["title", "subtitle", "iconlist"]
    },
    iconlist_gallery: {
        key: "iconlist_gallery",
        name: "Icon List Gallery",
        template: IconListGallery,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: true },
        components: ["title", "cards"]
    },
    iconlist_captions: {
        key: "iconlist_captions",
        name: "Icon List Captions",
        template: IconListCaptions,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: false, interactive: true },
        components: ["title", "subtitle", "cards"]
    },
    diptych: {
        key: "diptych",
        name: "Diptych",
        template: Diptych,
        overlay: ImageWithCaption,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: true },
        components: [
            "title",
            "subtitle",
            "diptych",
            "caption",
            "layout",
            "presenter"
        ]
    },
    triptych: {
        key: "triptych",
        name: "Triptych",
        template: Triptych,
        overlay: ImageWithCaption,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: true },
        components: [
            "title",
            "subtitle",
            "triptych",
            "caption",
            "layout",
            "presenter"
        ]
    },
    grid: {
        key: "grid",
        name: "Image Grid",
        template: Grid,
        overlay: ImageWithCaption,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: true },
        components: ["title", "subtitle", "grid", "caption"]
    },
    cards: {
        key: "cards",
        name: "Image Cards",
        template: Cards,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: true },
        components: ["title", "cards"]
    },
    gallery: {
        key: "gallery",
        name: "Image Gallery",
        template: Gallery,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: true },
        components: ["title", "subtitle", "cards"]
    },
    popuplist: {
        key: "popuplist",
        name: "Popup List",
        template: PopupList,
        overlay: ImageWithCaption,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: true },
        components: ["icon", "title", "subtitle", "popupitems"]
    },
    pdfviewer: {
        key: "pdfviewer",
        name: "PDF Viewer",
        template: PdfViewer,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: false, interactive: false },
        components: ["title", "pdf"]
    },
    video: {
        key: "video",
        name: "Video",
        template: Video,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: true },
        components: ["title", "subtitle", "video"]
    },
    video_caption: {
        key: "video_caption",
        name: "Captioned Video",
        template: VideoCaption,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: true },
        components: ["title", "subtitle", "video", "caption", "presenter"]
    },
    video_full_title: {
        key: "video_full_title",
        name: "Title with Video",
        template: VideoFullTitle,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: false },
        components: ["title", "video"]
    },
    video_full_paragraph: {
        key: "video_full_paragraph",
        name: "Paragraph with Video",
        template: VideoFullParagraph,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: true, interactive: false },
        components: ["title", "paragraph", "video"]
    },
    external_link: {
        key: "external_link",
        name: "External Link",
        template: ExternalLink,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: false, interactive: true },
        components: ["icon", "title", "subtitle", "paragraph", "link"]
    },
    internal_link: {
        key: "internal_link",
        name: "Link to Slide",
        template: InternalLink,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: false, interactive: true },
        components: ["icon", "title", "subtitle", "paragraph", "slideLink"]
    },
    conversation: {
        key: "conversation",
        name: "Conversation",
        template: Conversation,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: true, visual: true, interactive: false },
        components: ["icon", "title", "subtitle", "conversation"]
    },
    odd_one_out: {
        key: "odd_one_out",
        name: "Odd One Out",
        template: OddOneOut,
        overlay: FeedbackCard,
        questionable: true,
        scorable: true,
        interaction: "singleSelect",
        audit: { verbose: false, visual: false, interactive: true },
        components: [
            "icon",
            "title",
            "question",
            "options",
            "feedback",
            "purpose"
        ]
    },
    single_select: {
        key: "single_select",
        name: "Single Select",
        template: SingleSelect,
        overlay: FeedbackCard,
        questionable: true,
        scorable: true,
        interaction: "singleSelect",
        audit: { verbose: false, visual: false, interactive: true },
        components: [
            "icon",
            "title",
            "question",
            "options",
            "feedback",
            "purpose"
        ]
    },
    multiple_select: {
        key: "multiple_select",
        name: "Multiple Select",
        template: MultipleSelect,
        overlay: FeedbackCard,
        questionable: true,
        scorable: true,
        interaction: "multiSelect",
        audit: { verbose: false, visual: false, interactive: true },
        components: [
            "icon",
            "title",
            "question",
            "multiselect",
            "feedback",
            "purpose"
        ]
    },
    true_false: {
        key: "true_false",
        name: "Binary Choice",
        template: TrueFalse,
        overlay: FeedbackCard,
        questionable: true,
        scorable: true,
        interaction: "trueFalse",
        audit: { verbose: false, visual: false, interactive: true },
        components: [
            "icon",
            "title",
            "question",
            "choice",
            "feedback",
            "purpose"
        ]
    },
    fill_in_the_blanks: {
        key: "fill_in_the_blanks",
        name: "Cloze Question",
        template: FillInTheBlank,
        overlay: FeedbackCard,
        questionable: true,
        scorable: true,
        interaction: "fillInTheBlank",
        audit: { verbose: false, visual: false, interactive: true },
        components: [
            "icon",
            "title",
            "question",
            "answer",
            "feedback",
            "purpose"
        ]
    },
    anagram: {
        key: "anagram",
        name: "Anagram",
        template: Anagram,
        overlay: FeedbackCard,
        questionable: true,
        scorable: true,
        interaction: "fillInTheBlank",
        audit: { verbose: false, visual: false, interactive: true },
        components: [
            "icon",
            "title",
            "question",
            "answer",
            "feedback",
            "purpose"
        ]
    },
    open_answer: {
        key: "open_answer",
        name: "Open Answer",
        template: OpenAnswer,
        overlay: FeedbackCard,
        questionable: true,
        scorable: false,
        interaction: "openAnswer",
        audit: { verbose: false, visual: false, interactive: true },
        components: ["icon", "title", "question"]
    },
    detailer: {
        key: "detailer",
        name: "Cloze with Feedback",
        template: Detailer,
        overlay: LinkOverlay,
        questionable: true,
        scorable: true,
        interaction: "fillInTheBlank",
        audit: { verbose: false, visual: false, interactive: true },
        components: [
            "icon",
            "title",
            "question",
            "answer",
            "slideLink",
            "feedback",
            "purpose"
        ]
    },
    know_more_single_select: {
        key: "know_more_single_select",
        name: "MCQ with Feedback",
        template: KnowMoreSingleSelect,
        overlay: LinkOverlay,
        questionable: true,
        scorable: true,
        interaction: "singleSelect",
        audit: { verbose: false, visual: false, interactive: true },
        components: [
            "icon",
            "title",
            "question",
            "options",
            "slideLink",
            "feedback",
            "purpose"
        ]
    },
    brancher_template: {
        key: "brancher_template",
        name: "Brancher",
        template: Brancher,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: false, interactive: true },
        components: ["icon", "title", "paragraph", "brancher"]
    },
    picture_fuddle: {
        key: "picture_fuddle",
        name: "Picture Fuddle",
        template: PictureFuddle,
        overlay: ImageOverlay,
        questionable: true,
        scorable: true,
        interaction: "fillInTheBlank",
        audit: { verbose: false, visual: true, interactive: true },
        components: [
            "icon",
            "title",
            "image",
            "filter",
            "question",
            "answer",
            "feedback",
            "purpose"
        ]
    },
    feedback_paragraph: {
        key: "feedback_paragraph",
        name: "Fuddle with Feedback",
        template: FeedbackParagraph,
        overlay: ImageOverlay,
        questionable: true,
        scorable: true,
        interaction: "fillInTheBlank",
        audit: { verbose: false, visual: true, interactive: true },
        components: [
            "icon",
            "title",
            "image",
            "filter",
            "question",
            "answer",
            "feedback",
            "paragraph",
            "purpose"
        ]
    },
    pick_picture: {
        key: "pick_picture",
        name: "Picture Single Select",
        template: PickPicture,
        overlay: FeedbackCard,
        questionable: true,
        scorable: true,
        interaction: "singleSelect",
        audit: { verbose: false, visual: true, interactive: true },
        components: ["title", "question", "imageOpts", "feedback", "purpose"]
    },
    rank_them_right: {
        key: "rank_them_right",
        name: "Ranking Options",
        template: RankThemRight,
        overlay: FeedbackCard,
        questionable: true,
        scorable: true,
        interaction: "rankThem",
        audit: { verbose: false, visual: false, interactive: true },
        components: [
            "icon",
            "title",
            "question",
            "bullets",
            "feedback",
            "purpose"
        ]
    },
    timeliner: {
        key: "timeliner",
        name: "Timelined Options",
        template: Timeliner,
        overlay: FeedbackCard,
        questionable: true,
        scorable: true,
        interaction: "rankThem",
        audit: { verbose: false, visual: false, interactive: true },
        components: [
            "icon",
            "title",
            "question",
            "bullets",
            "feedback",
            "purpose"
        ]
    },
    uploader: {
        key: "uploader",
        name: "File Uploader",
        template: FileUploader,
        questionable: true,
        scorable: false,
        interaction: "uploader",
        audit: { verbose: false, visual: false, interactive: true },
        components: [
            "icon",
            "title",
            "subtitle",
            "question"
        ],
    },
    icon_caption: {
        key: "image_caption",
        name: "Captioned Icon",
        template: IconCaption,
        overlay: null,
        questionable: false,
        scorable: false,
        interaction: "",
        audit: { verbose: false, visual: true, interactive: false },
        components: ["image", "caption"]
    }
};

const trackInteractionCase = (slideData, response) => {
    let options;
    let interactionType = TemplateList[slideData.template].interaction;
    switch (interactionType) {
        case "singleSelect":
            options =
                slideData.data.options ||
                slideData.data.imageOpts ||
                slideData.data.images;
            return {
                correct: options[response]["correct"] === "checked",
                response: response
            };
        case "trueFalse":
            options = slideData.data.choice;
            return {
                correct: options[response]["correct"] === "checked",
                response: response
            };
        case "fillInTheBlank":
            let givenAnswer = response.trim().toLowerCase(),
                correctAns = slideData.data.answer
                    .toString()
                    .trim()
                    .toLowerCase();
            return {
                correct: givenAnswer === correctAns,
                response: response
            };
        case "multiSelect":
            options = slideData.data.multiselect;
            let correctCount = _.filter(options, ["correct", "checked"]),
                allCorrect = true;
            _.map(response, item => {
                if (options[item]["correct"] !== "checked") allCorrect = false;
            });
            return {
                correct: allCorrect && response.length === correctCount.length,
                response: response
            };
        case "rankThem":
            options = slideData.data.bullets;
            return {
                correct: _.isEqual(options, response),
                response: response
            };
        case "openAnswer":
        case "uploader":
            return { correct: true, response: response };
        default:
    }
};

export { TemplateList, trackInteractionCase };
