// Import from NPM
// -------------------------------------
import React from "react";
import { connect } from "react-redux";
import { Grid, Image, Dropdown, Icon } from "semantic-ui-react";
import { hashHistory, Link } from "react-router";
import Avatar from "boring-avatars";

// Import from Config
// -------------------------------------
import { getAppConfig } from "config/client.config";
import AnalyticsContainer from "../../analytics/AnalyticsContainer.react";
import { gradient } from "config/app.helpers";

// Import Components
// -------------------------------------
import NotificationContainer from "../../notification/NotificationContainer.react";
import ProfileContainer from "../../profile/ProfileContainer.react";
import { Button } from "config/initializers/styles.app";
import ContactUsModal from "./ContactUsModal.react";
import HelpDeskAction from "appRedux/HelpDesk.action.react";

export class DesktopInductorNavbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            displayNotification: false,
            slideMenuVisible: false,
            enableTagsList: false,
            selectedTagParent: null,
            displayProfile: false,
            currentTab: 0,
            openContactUs: false,
        };
    }
    toggleSlideMenu = () => {
        this.setState({
            slideMenuVisible: true,
        });
    };
    handleClose = () => {
        this.setState({
            slideMenuVisible: false,
            currentTab: 0,
        });
    };
    handleLogout = () => {
        hashHistory.push("/logout");
    };
    goBack = () => {
        hashHistory.goBack();
    };
    goToEdit = () => {
        hashHistory.push("/editProfile");
    };
    refreshPage = () => {
        hashHistory.push("/session/home");
    };
    goToPage = (a) => {
        if (a.indexOf("/profile") !== -1) this.toggleDisplayProfile();
        else if (a.indexOf("/notifications") !== -1)
            this.toggleDisplayNotification();
        else if (a.indexOf("http") === -1 && a.indexOf("mailto:") === -1)
            hashHistory.push(a);
        else if (a.indexOf("mailto:") !== -1) window.open(a, "_blank");
        else window.location = a;
        this.setState({ enableTagsList: false });
    };
    goToHome = () => {
        window.localforage
            .setItem("lastLocation", "home")
            .then(() => hashHistory.push("/session/home"));
        this.setState({ enableTagsList: false });
    };

    handleSearch = (e) => {
        if (e.key === "Enter") {
            if (this.state.searchText.length > 2)
                hashHistory.push(`/articles?search=${this.state.searchText}`);
        } else {
            this.setState({ searchText: e.target.value.trim() });
        }
    };

    toggleDisplayNotification = () =>
        this.setState({ displayNotification: !this.state.displayNotification });

    toggleDisplayProfile = () =>
        this.setState({ displayProfile: !this.state.displayProfile });

    handleContactsButtonClick = () =>
        this.setState({ openContactUs: !this.state.openContactUs });

    selectTags = (tags) => {
        if (tags.indexOf(",") !== -1) {
            hashHistory.push("/articles?tags=" + tags.join(","));
        } else {
            hashHistory.push("/articles?tags=" + tags);
        }
        this.setState({ enableTagsList: false });
    };

    closeTagsList = () => {
        this.setState({ enableTagsList: false });
    };
    render() {
        let courseId =
            window.location.href.indexOf("/courses/") !== -1
                ? window.location.href.split("/courses/")[1].split("/")[0]
                : window.location.href.indexOf("/decks/") !== -1
                    ? window.location.href.split("/decks/")[1].split("/")[2]
                    : null;

        let thisCourse =
            this.props.auth.user.courses !== undefined ?
                this.props.courses &&
                    this.props.auth.user.courses.length > 1 &&
                    courseId
                    ? this.props.courses[courseId]
                    : this.props.courses &&
                        this.props.auth.user.courses.length === 1
                        ? this.props.courses[this.props.auth.user.courses[0]]
                        : {}
                : {};
        let colorArray = [
            "#ffcdb2",
            "#ffb4a2",
            "#e5989b",
            "#b5838d",
            "#6d6875",
        ];
        let barStyle = {
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            background:
                thisCourse && thisCourse.colors
                    ? gradient(thisCourse.colors.primary, "to right")
                    : "#666666",
            zIndex: "1001",
            height: "55px",
            display: window.innerWidth > 768 ? "block" : "none",
        };
        let navbarStyle = {
            maxWidth: "1280px",
            margin: "0 auto",
        };
        let logoBlockStyle = {
            height: "55px",
            width: "300px",
            display: "inline-block",
            cursor: "pointer",
        };
        let logoStyle = {
            height: "55px",
            padding: thisCourse && thisCourse.logo ? "0" : "11px 22px",
            background:
                thisCourse && thisCourse.logo && thisCourse.colors
                    ? thisCourse.colors.base
                    : "#454545",
            verticalAlign: "middle",
        };

        let navIconStyle = {
            menu: {
                paddingLeft: "15px",
                display: "inline-block",
                width: "100%",
            },
            block: {
                display: "inline-block",
                marginTop: "11px",
                textAlign: "center",
                color: getAppConfig().colors.iconColor,
                marginRight: "25px",
                cursor: "pointer",
            },
            activeBlock: {
                display: "inline-block",
                textAlign: "center",
                background: "rgba(0,0,0,0.5)",
                color: getAppConfig().colors.iconColor,
                padding: "11px 12.5px 4px 12.5px",
                margin: "0px 12.5px 0 -12.5px",
                cursor: "pointer",
            },
            dropDownblock: {
                display: "inline-block",
                marginTop: "11px",
                textAlign: "center",
                color: getAppConfig().colors.iconColor,
                marginRight: "7px",
                cursor: "pointer",
            },
            activeDropDownBlock: {
                display: "inline-block",
                textAlign: "center",
                background: "rgba(0,0,0,0.5)",
                color: getAppConfig().colors.iconColor,
                padding: "11px 12.5px 4px 12.5px",
                margin: "0px 0px 0 -12.5px",
                cursor: "pointer",
            },
            icon: { fontSize: "20px" },
            name: { fontSize: "10px" },
            username: {
                color:
                    thisCourse && thisCourse.colors
                        ? thisCourse.colors.primaryText
                        : "#ffffff",
                paddingRight: "8px",
                fontSize: "1em",
                fontWeight: "bold",
            },
            avatar: {
                width: "32px",
                borderRadius: "50%",
                margin: "10px auto",
                boxSizing: "border-box",
            },
            avatarIcon: {
                borderRadius: "50%",
                margin: "10px auto",
                boxSizing: "border-box",
                width: "32px",
                height: "32px",
            },
            notifyIcon: {
                marginRight: "15px",
                fontSize: "1.5em",
                cursor: "pointer",
                color:
                    thisCourse && thisCourse.colors
                        ? thisCourse.colors.primaryText
                        : "#ffffff",
            },
            ellipsis: {
                marginLeft: "10px",
                color:
                    thisCourse && thisCourse.colors
                        ? thisCourse.colors.primaryText
                        : "#ffffff",
            },
        };
        let loginBtn = {
            float: "right",
            margin: "13px 10px",
            fontSize: "1.2em",
            color: getAppConfig().colors.iconColor,
        };
        let userProfileStyle = {
            right: "1%",
            textAlign: "right",
        };
        let profileSettings = getAppConfig().learningCenter.profileList
            ? getAppConfig().learningCenter.profileList
            : {};

        if (this.props.auth.user && this.props.auth.isUserLoggedIn)
            return (
                <div style={barStyle}>
                    <div style={navbarStyle}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={8}>
                                    {window.location.hash.includes("decks") && (
                                        <div
                                            style={{
                                                height: "55px",
                                                display: "inline-block",
                                                verticalAlign: "middle",
                                                lineHeight: "55px",
                                                padding: "0 5px",
                                                cursor: "pointer",
                                            }}
                                            onClick={this.goBack}
                                        >
                                            <Icon
                                                name="chevron left"
                                                size="large"
                                            />
                                        </div>
                                    )}

                                    <img
                                        src={
                                            thisCourse && thisCourse.logo
                                                ? thisCourse.logo
                                                : "assets/images/defaults/logo-inverted.png"
                                        }
                                        alt="Brand Logo"
                                        style={logoStyle}
                                    />
                                    {window.location.hash.includes("decks") && (
                                        <div
                                            style={{
                                                display: "inline-block",
                                                fontFamily: "Oswald",
                                                fontSize: "2em",
                                                lineHeight: "55px",
                                                textTransform: "uppercase",
                                                marginLeft: "15px",
                                                height: "55px",
                                                padding: 0,
                                                verticalAlign: "middle",
                                            }}
                                        >
                                            {thisCourse.name}
                                        </div>
                                    )}
                                </Grid.Column>
                                <Grid.Column width={8} style={userProfileStyle}>
                                    {profileSettings.enableContactUs && (
                                        <Button
                                            grouped
                                            content={
                                                "Contact us for Free Trial"
                                            }
                                            onClick={
                                                this.handleContactsButtonClick
                                            }
                                            style={{
                                                margin: "13px 4px",
                                                color: getAppConfig().colors
                                                    .iconColor,
                                            }}
                                        />
                                    )}
                                    {/* {profileSettings.notifications && profileSettings.enableContactUs === false && (
                                        <NotificationBadge
                                            toggleDisplayNotification={this.toggleDisplayNotification}
                                            unreadNotification={this.props.notifications.unreadNotification}
                                            right="45%"
                                        />
                                    )} */}
                                    {getAppConfig().inductor ? (
                                        <div
                                            style={{
                                                display: "flex",
                                                float: "right",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "55px",
                                            }}
                                        >
                                            <Dropdown
                                                className="notifications"
                                                icon="bell"
                                                direction="left"
                                                style={navIconStyle.notifyIcon}
                                            >
                                                <Dropdown.Menu
                                                    style={{
                                                        marginTop: "15px",
                                                    }}
                                                >
                                                    <Dropdown.Item
                                                        style={{ padding: 0 }}
                                                    >
                                                        <NotificationContainer />
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>

                                            <span style={navIconStyle.username}>
                                                {text_truncate(
                                                    `${this.props.user.first_name} ${this.props.user.last_name}`,
                                                    18
                                                )}
                                            </span>
                                            {this.props.user.avatar == null ||
                                                this.props.user.avatar === "" ? (
                                                <div
                                                    style={
                                                        navIconStyle.avatarIcon
                                                    }
                                                >
                                                    <Avatar
                                                        size={30}
                                                        name={`${this.props.user.first_name} ${this.props.user.last_name}`}
                                                        variant="beam"
                                                        colors={colorArray}
                                                    />
                                                </div>
                                            ) : (
                                                <Image
                                                    src={this.props.user.avatar}
                                                    style={navIconStyle.avatar}
                                                />
                                            )}
                                            <Dropdown
                                                direction="left"
                                                icon="ellipsis vertical"
                                                style={navIconStyle.ellipsis}
                                            >
                                                <Dropdown.Menu
                                                    style={{
                                                        marginTop: "15px",
                                                    }}
                                                >
                                                    <Dropdown.Item
                                                        icon={"pencil"}
                                                        text={"Edit Profile"}
                                                        onClick={this.goToEdit}
                                                    />
                                                    <Dropdown.Item
                                                        icon={"sync"}
                                                        text={"Resync Platform"}
                                                        onClick={
                                                            this.refreshPage
                                                        }
                                                    />
                                                    <Dropdown.Item
                                                        icon={"log out"}
                                                        text={"Log out"}
                                                        onClick={
                                                            this.handleLogout
                                                        }
                                                    />
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    ) : (
                                        <Dropdown
                                            icon=""
                                            trigger={
                                                <Image
                                                    src="assets/images/configurable/avatar.jpg"
                                                    avatar
                                                    style={navIconStyle.avatar}
                                                />
                                            }
                                        >
                                            <Dropdown.Menu
                                                style={{
                                                    marginLeft: "-120px",
                                                    marginTop: "15px",
                                                    minWidth: "12vw",
                                                }}
                                            >
                                                <Dropdown.Header
                                                    content={`${this.props.user.first_name} ${this.props.user.last_name}`}
                                                />
                                                <Dropdown.Divider />
                                                {profileSettings.notifications && (
                                                    <Dropdown.Item
                                                        icon={"bell"}
                                                        text={"Notifications"}
                                                        onClick={
                                                            this
                                                                .toggleDisplayNotification
                                                        }
                                                    />
                                                )}
                                                {profileSettings.editProfile && (
                                                    <Dropdown.Item
                                                        icon={"edit outline"}
                                                        text={"Edit Profile"}
                                                        onClick={() =>
                                                            this.goToPage(
                                                                "/editProfile"
                                                            )
                                                        }
                                                    />
                                                )}
                                                {profileSettings.myperformance &&
                                                    getAppConfig().apiUrls
                                                        .gamifixURL && (
                                                        <Dropdown.Item
                                                            icon={"chart pie"}
                                                            text={
                                                                "My Performance"
                                                            }
                                                            onClick={() => {
                                                                this.toggleSlideMenu();
                                                                this.setState({
                                                                    currentTab: 5,
                                                                });
                                                            }}
                                                        />
                                                    )}
                                                {profileSettings.reports &&
                                                    this.props.user.username !==
                                                    "sysadmin" && (
                                                        <Dropdown.Item
                                                            icon={"chart area"}
                                                            text={
                                                                "Progress Reports"
                                                            }
                                                            onClick={() =>
                                                                this.toggleSlideMenu()
                                                            }
                                                        />
                                                    )}
                                                {profileSettings.logout && (
                                                    <Dropdown.Item
                                                        icon={"log out"}
                                                        text={"Exit"}
                                                        onClick={
                                                            () =>
                                                                this.goToPage(
                                                                    "/logout"
                                                                )
                                                            // this.goToPage(
                                                            //     getAppConfig().auth
                                                            //         .routes
                                                            //         .unauthenticatedRoot
                                                            // )
                                                        }
                                                    />
                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    )}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        {this.state.slideMenuVisible && (
                            <AnalyticsContainer
                                currentTab={this.state.currentTab}
                                slideMenuVisible={this.state.slideMenuVisible}
                                handleClose={this.handleClose}
                            />
                        )}
                    </div>
                    {this.state.displayNotification && (
                        <NotificationContainer
                            openModal={this.state.displayNotification}
                            toggleNotification={this.toggleDisplayNotification}
                        />
                    )}
                    {this.state.displayProfile && (
                        <ProfileContainer
                            openModal={this.state.displayProfile}
                            toggleDisplayProfile={this.toggleDisplayProfile}
                        />
                    )}
                    {this.state.openContactUs && (
                        <ContactUsModal
                            openModal={this.state.openContactUs}
                            handleModalClose={this.handleContactsButtonClick}
                            sendContactRequst={
                                this.props.actions.sendContactRequst
                            }
                        />
                    )}
                </div>
            );
        else
            return (
                <div style={barStyle}>
                    <a href="#/session/home" style={logoBlockStyle}>
                        <img
                            src={
                                "/assets/images/configurable/logo-inverted.png"
                            }
                            alt="Brand Logo"
                            style={logoStyle}
                        />
                    </a>
                    <Link to="/login" style={loginBtn}>
                        Login
                    </Link>
                </div>
            );
    }
}
const mapStateToProps = (state) => {
    return {
        courses: state.courses,
        auth: state.auth,
        blog: state.blog,
        notifications: state.notification,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            sendContactRequst: (
                name,
                phonenumber,
                workemail,
                organisation,
                designation,
                message
            ) => {
                return dispatch(
                    HelpDeskAction.sendContactRequst(
                        name,
                        phonenumber,
                        workemail,
                        organisation,
                        designation,
                        message
                    )
                );
            },
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DesktopInductorNavbar);

const text_truncate = function (str, length, ending) {
    if (str == null) {
        str = "";
    }
    if (length == null) {
        length = 100;
    }
    if (ending == null) {
        ending = "...";
    }
    if (str.length > length) {
        return str.substring(0, length - ending.length) + ending;
    } else {
        return str;
    }
};
