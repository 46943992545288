import React from "react";
import PropTypes from "prop-types";
import Text from "../../contentBlocks/Text.react";
import SlideHeader from "../../contentBlocks/SlideHeader.react";
import { ComponentInputs } from "schema/FormSchema.react";

export default class TitleSubtitle extends React.Component {
    static propTypes = {
        colors: PropTypes.shape({
            base: PropTypes.string.isRequired,
            primary: PropTypes.string.isRequired,
            primaryVariation: PropTypes.string.isRequired,
            secondary: PropTypes.string.isRequired,
            baseText: PropTypes.string.isRequired,
            primaryText: PropTypes.string.isRequired,
            secondaryText: PropTypes.string.isRequired,
        }).isRequired,
        data: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
        }).isRequired,
        fontSize: PropTypes.string,
        mobileView: PropTypes.bool,
    };

    static defaultProps = {
        mobileView: false,
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let defaultStyle = {
            margin: isPortrait ? "auto" : "0",
            width: isPortrait ? "80%" : "80%",
            textAlign: isPortrait ? "center" : "left",
            padding: isPortrait ? "40px 0" : "50px",
        };
        return (
            <div className="full-height">
                <SlideHeader
                    {...this.props}
                    title={this.props.data.title}
                    fontSize={isPortrait ? "huge" : "ultra"}
                    height="60%"
                />
                <div style={defaultStyle}>
                    <Text
                        keyName={`subtitle-${this.props.slideId}`}
                        text={this.props.data.subtitle}
                        color={this.props.colors.baseText}
                        animation="transition.slideDownIn,1000,750"
                        fontSize={
                            isPortrait
                                ? this.props.data.subtitle.length >
                                  ComponentInputs.subtitle.idealLength
                                    ? "normal"
                                    : "large"
                                : "large"
                        }
                    />
                </div>
            </div>
        );
    }
}
