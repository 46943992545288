import React from "react";
export default class FileBase64 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
        };
    }

    static defaultProps = {
        multiple: false,
    };

    handleRef = (c) => {
        this.inputRef = c;
    };

    handleChange(e) {
        // get the files
        let files = e.target.files;
        // Process each file
        var allFiles = [];
        for (var i = 0; i < files.length; i++) {
            let file = files[i];

            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                let size = Math.round(file.size / 1000);
                if (this.props.limit == null || size < this.props.limit) {
                    let fileInfo = {
                        name: file.name,
                        type: file.type,
                        size: size + " kB",
                        base64: reader.result,
                        file: file,
                    };

                    // Push it to the state
                    allFiles.push(fileInfo);

                    // If all files have been processed
                    if (allFiles.length === files.length) {
                        // Apply Callback function
                        if (this.props.multiple) this.props.onDone(allFiles);
                        else this.props.onDone(allFiles[0]);
                    }
                } else {
                    e.target.value = null;
                    this.props.onReject(
                        `The upload size of ${size}kB is higher than the limit of ${this.props.limit}kB. Please choose a smaller file.`
                    );
                }
            }; // reader.onload
        } // for

        this.setState({ files: [] });
    }

    render() {
        let imageUploadStyle = {
            display: "flex",
            wordWrap: "break-word"
        }
        return (
            <div style={imageUploadStyle}>
                <input
                    type="file"
                    ref={this.handleRef}
                    onChange={this.handleChange.bind(this)}
                    multiple={this.props.multiple}
                    accept={this.props.accept}
                    capture={this.props.capture}
                    style={{
                        width: this.props.inputWidth
                            ? this.props.inputWidth
                            : "none",
                    }}
                />
                {this.props.limit && (
                    <p
                        style={{
                            fontSize: "0.75em",
                            margin: "5px",
                            textAlign: "left",
                            fontStyle: "italic",
                        }}
                    >
                        Maximum File Size: {this.props.limit}kB
                    </p>
                )}
            </div>
        );
    }
}
