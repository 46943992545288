import _ from 'lodash';
import {FETCH_COMMENTS, ADD_COMMENT, DELETE_COMMENT} from 'appRedux/helpers/reduxConstants';
import {REHYDRATE} from 'redux-persist/constants';

const initialCommentsState = {
    "comments": [],
    "fetchCompleted": false,
    "error": null
};

const CommentsReducer = (state = initialCommentsState, action) => {
    switch (action.type) {
        case REHYDRATE:
            let feed = action.payload.feed;
            if (feed) {
                return {...state, ...feed}
            } else {
                return state;
            }
        case FETCH_COMMENTS:
            if (action.status === "fetching") {
                return {...state, fetchCompleted: false, error: null};
            } else if (action.status === "success") {
                return _.merge({}, action.data, {fetchCompleted: true, error: null});
            } else {
                return {...state, error: action.err, fetchCompleted: true};
            }
        case ADD_COMMENT:
            if (action.status === "fetching") {
                return {...state, fetchCompleted: false, error: null};
            } else if (action.status === "success") {
                state.comments.push(action.data.comments)
                return {...state, fetchCompleted: true, error: null};
            } else {
                return {...state, error: action.err, fetchCompleted: true};
            }
        case DELETE_COMMENT:
            if (action.status === "fetching") {
                return {...state, fetchCompleted: false, error: null};
            } else if (action.status === "success") {
                _.remove(state.comments, {id: action.data.comment.id});
                return {...state, fetchCompleted: true, error: null};
            } else {
                return {...state, error: action.err, fetchCompleted: true};
            }

        default:
            return state;
    }
};

export {CommentsReducer};