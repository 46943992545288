import { FETCH_LIBRARY } from "appRedux/helpers/reduxConstants";
import { REHYDRATE } from "redux-persist/constants";

const initialLibraryState = {
    categories: [],
    fetchCompleted: false,
    error: null
};

const LibraryReducer = (state = initialLibraryState, action) => {
    switch (action.type) {
        /* istanbul ignore next - react-persist function */
        case REHYDRATE:
            let library = action.payload.library;
            if (library) {
                return { ...state, ...library };
            } else {
                return state;
            }

        case FETCH_LIBRARY:
            return {
                categories: action.data.library.categories,
                fetchCompleted: true,
                error: null
            };
        default:
            return state;
    }
};

export { LibraryReducer };
