import React from "react";
import Separator from "../../contentBlocks/Separator.react";
import Bullets from "../../contentBlocks/Bullets.react";
import Caption from "../../contentBlocks/Caption.react";
import SlideHeader from "../../contentBlocks/SlideHeader.react";
import SlideHeaderSidebar from "../../contentBlocks/SlideHeaderSidebar.react";
import PropTypes from "prop-types";
import { Grid } from "semantic-ui-react";
import { ComponentInputs } from "schema/FormSchema.react";

export default class LineBulletList extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        colors: PropTypes.object,
        transform: PropTypes.number,
        translateY: PropTypes.number,
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let defaultStyle = {
            margin: "auto",
            width: "90%",
            textAlign: isPortrait ? "center" : "left",
            padding: isPortrait ? "15px 0" : "30px 0px 50px 0",
            overflow: "auto",
        };
        return (
            <div className="full-height">
                {isPortrait ? (
                    <div>
                        <SlideHeader
                            {...this.props}
                            title={this.props.data.title}
                            subtitle={this.props.data.subtitle}
                        />
                        <div style={defaultStyle}>
                            <Caption
                                keyName="caption"
                                text={this.props.data.caption}
                                colors={this.props.colors}
                                animation={"transition.slideDownIn,400,100"}
                                fontSize={
                                    this.props.data.caption &&
                                    this.props.data.caption.length >
                                        ComponentInputs.caption.idealLength
                                        ? "normal"
                                        : "large"
                                }
                                textAlign={"left"}
                            />
                            <Separator
                                color={this.props.colors.primary}
                                keyName={"separator-" + this.props.slideId}
                                animation="transition.swoopIn,500,500"
                            />
                            <Bullets
                                keyName={"bullets-" + this.props.slideId}
                                list={this.props.data.bullets}
                                color={this.props.colors.baseText}
                                animation="transition.slideDownIn,600,600"
                            />
                        </div>
                    </div>
                ) : (
                    <Grid style={{ minHeight: "100%" }}>
                        <Grid.Row style={{ paddingBottom: "0" }}>
                            <Grid.Column
                                width={8}
                                style={{
                                    paddingRight: "0",
                                    background: this.props.colors.primary,
                                }}
                            >
                                <SlideHeaderSidebar
                                    {...this.props}
                                    title={this.props.data.title}
                                    subtitle={this.props.data.subtitle}
                                />
                            </Grid.Column>
                            <Grid.Column width={8} style={{ paddingLeft: "0" }}>
                                <div style={defaultStyle}>
                                    <Caption
                                        keyName="caption"
                                        text={this.props.data.caption}
                                        colors={this.props.colors}
                                        animation={
                                            "transition.slideDownIn,400,100"
                                        }
                                        textAlign={"left"}
                                    />
                                    <Separator
                                        color={this.props.colors.primary}
                                        keyName={
                                            "separator-" + this.props.slideId
                                        }
                                        animation="transition.swoopIn,500,500"
                                    />
                                    <Bullets
                                        keyName={
                                            "bullets-" + this.props.slideId
                                        }
                                        list={this.props.data.bullets}
                                        color={this.props.colors.baseText}
                                        animation="transition.slideDownIn,600,600"
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
            </div>
        );
    }
}
