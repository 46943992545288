// import { Store } from "backend/storage/mobile/mobile.store";
import { appConfig } from "./initializers/config.app";
import _ from "lodash";

export const getAppConfig = () => {
    const initialState = appConfig;
    let storeState = {};
    // if (_.keys(Store.getState().appConfig.config).length > 0) {
    //     storeState = Store.getState().appConfig.config || {};
    // }
    const result = _.assign({}, initialState, storeState, {
        apiUrls: initialState.apiUrls,
    });
    return result;
};

window.getAppConfig = getAppConfig();
