import _ from "lodash";
import { REHYDRATE } from "redux-persist/constants";
import {
    FETCH_NOTIFICATIONS,
    FETCH_NOTIFICATION_COUNT,
    MARK_READ_NOTIFICATIONS,
    FETCH_UNREAD_NOTIFICATION_COUNT
} from "appRedux/helpers/reduxConstants";

const initialNotificationState = {
    default_schema: {
        id: "",
        title: "",
        description: "",
        image_src: "",
        updated_at: "",
        contains_link: false,
        read: false,
        type: "general",
        link: {
            url: "",
            type: "",
            element_id: "",
            parent_id: ""
        }
    },
    notifications: [],
    unreadNotificationCount: 0,
    totalNotificationCount: 0,
    lastNotificationFetched: Date.now(),
    lastNotificationCountFetched: Date.now(),
    error: null,
    unreadNotification: 0
};

const NotificationReducer = (state = initialNotificationState, action) => {
    switch (action.type) {
        case REHYDRATE:
            let notification = action.payload.notification;
            if (notification) {
                return { ...state, ...notification };
            } else {
                return state;
            }
        case FETCH_NOTIFICATIONS:
            if (action.status === "fetching") {
                return { ...state, error: null };
            } else if (action.status === "success") {
                const notifications = action.data;
                const unReadCount = _.countBy(notifications, "read");
                return {
                    ...state,
                    notifications: notifications,
                    error: null,
                    lastNotificationFetched: Date.now(),
                    unreadNotificationCount: unReadCount.false,
                    totalNotificationCount: notifications.length
                };
            } else {
                return { ...state, error: action.err };
            }
        case FETCH_NOTIFICATION_COUNT:
            if (action.status === "fetching") {
                return { ...state, error: null };
            } else if (action.status === "success") {
                return {
                    ...state,
                    error: null,
                    unreadNotificationCount: action.data,
                    lastNotificationCountFetched: Date.now()
                };
            } else {
                return { ...state, error: action.err };
            }
        case MARK_READ_NOTIFICATIONS:
            if (action.status === "fetching") {
                return { ...state, error: null };
            } else if (action.status === "success") {
                let retriveData = action.data.data;
                let notififcation = _.map(state.notifications, notification => {
                    if (retriveData.indexOf(notification.id) > -1)
                        notification.read = true;

                    return notification;
                });
                return {
                    ...state,
                    error: null,
                    notifications: notififcation,
                    lastNotificationCountFetched: Date.now()
                };
            } else {
                return { ...state, error: action.err };
            }
        case FETCH_UNREAD_NOTIFICATION_COUNT:
            if (action.status === "fetching") {
                return { ...state, error: null };
            } else if (action.status === "success") {
                return {
                    ...state,
                    error: null,
                    unreadNotification: action.data,
                };
            } else {
                return { ...state, error: action.err };
            }
        default:
            return state;
    }
};

export { NotificationReducer };
