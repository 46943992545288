import React from "react";
import YouTube from "react-youtube";
import PropTypes from "prop-types";
import { getAppConfig } from "config/client.config";
import { Player, BigPlayButton } from "video-react";
import "video-react/dist/video-react.css";
import Vimeo from "@u-wave/react-vimeo";

export default class VideoBox extends React.Component {
    constructor(props) {
        super(props);
        this._onPlay = this._onPlay.bind(this);
        this._onEnd = this._onEnd.bind(this);
        this._onReady = this._onReady.bind(this);
        this._onPause = this._onPause.bind(this);
        this.loadPlayer = this.loadPlayer.bind(this);
        this.toggleFullscreen = this.toggleFullscreen.bind(this);
        this.state = {
            hasError: false,
            fullScreen: false,
            hideButtons: this.props.hideButtons,
            isYouTube: false,
            isVimeo: false,
        };
    }
    static propTypes = {
        url: PropTypes.string,
        autoplay: PropTypes.bool,
        loop: PropTypes.bool,
        _onPlay: PropTypes.func,
        _onEnd: PropTypes.func,
        _onReady: PropTypes.func,
        _onPause: PropTypes.func,
    };
    static defaultProps = {
        url: "https://www.youtube.com/watch?v=DjXp7AFgSdk",
        autoplay: false,
        mobileView: false,
        rel: 0,
        mute: false,
    };

    componentDidMount() {
        if (
            this.props.url.indexOf("youtu.be") !== -1 ||
            this.props.url.indexOf("youtube") !== -1
        ) {
            this.setState({ isYouTube: true });
        } else if (this.props.url.indexOf("vimeo") !== -1) {
            this.setState({ isVimeo: true });
        } else {
            this.refs.player.subscribeToStateChange(
                this.handleStateChange.bind(this)
            );
        }
    }

    handleStateChange(state, prevState) {
        if (state.readyState && !prevState.readyState) {
            if (this.props._onReady) this.props._onReady();
        }
        if (state.hasStarted && !prevState.hasStarted) {
            if (this.props._onPlay) this.props._onPlay();
        }
        if (state.paused && !prevState.paused) {
            if (this.props._onPause) this.props._onPause();
        }
        if (state.ended && !prevState.ended) {
            if (this.props._onEnd) this.props._onEnd();
        }
    }

    getVideoId = (url) => {
        let videoId = "";
        if (url.indexOf("v=") === -1) {
            var video_array = url.split("/");
            videoId = video_array[video_array.length - 1];
        } else {
            videoId = url.split("v=")[1];
            var ampersandPosition = videoId.indexOf("&");
            if (ampersandPosition !== -1) {
                videoId = videoId.substring(0, ampersandPosition);
            }
        }
        return videoId;
    };

    _onReady(event) {
        if (event.target && this.props.autoplay) {
            event.target.playVideo();
        }

        if (this.props._onReady) this.props._onReady(event);
    }

    _onPlay = (event) => {};

    _onEnd = (event) => {
        if (this.props.loop) event.target.playVideo();
        if (this.props._onEnd) this.props._onEnd(event);
    };

    _onPause = (event) => {
        if (this.props._onPause) this.props._onPause(event);
    };

    _onError = (event) => {
        this.setState({ hasError: true });
    };
    toggleFullscreen(visible) {
        this.setState({ fullScreen: visible });
        if (getAppConfig().isMobile) {
            let locOrientation =
                window.screen.lockOrientation ||
                window.screen.mozLockOrientation ||
                window.screen.msLockOrientation ||
                window.screen.orientation.lock;
            if (visible) {
                locOrientation("landscape");
            } else {
                locOrientation("portrait");
            }
        }
    }
    loadPlayer = () => {
        if (this.state.hasError) {
            return (
                <div>
                    {" "}
                    Error While loading video.. please make sure that you are
                    connected to internet and try again later{" "}
                </div>
            );
        } else {
            const opts = {
                playerVars: {
                    autoplay: this.props.autoplay ? 1 : 0,
                    loop: this.props.loop ? 1 : 0,
                    mute: this.props.mute ? 1 : 0,
                    rel: 0,
                    controls: this.props.hideControls ? 0 : 1,
                    showinfo: this.props.hideInfo ? 0 : 1,
                },
                width: "100%",
                height: "100%",
            };
            let isPortrait =
                this.props.mobileView || window.innerHeight > window.innerWidth;
            let videoMobile = {
                width: "100%",
                height: "100%",
            };
            let videoDesktop = {
                width: "100%",
                height: "100%",
            };
            let videoWrap = isPortrait ? videoMobile : videoDesktop;

            if (this.state.isVimeo) {
                return (
                    <div
                        style={videoWrap}
                        onClick={this.setShowControl}
                        className="video-responsive vimeo-embed-container"
                    >
                        <Vimeo
                            video={this.props.url}
                            autoplay={true}
                            playerOptions={{ width: window.innerWidth }}
                            onReady={this._onReady}
                            onPlay={this._onPlay}
                            onEnd={this._onEnd}
                            onError={this._onError}
                            onPause={this._onPause}
                        />
                    </div>
                );
            } else if (this.state.isYouTube) {
                return (
                    <div
                        style={videoWrap}
                        className="video-responsive"
                        onClick={this.setShowControl}
                    >
                        <YouTube
                            videoId={this.getVideoId(this.props.url)}
                            opts={opts}
                            onReady={this._onReady}
                            onPlay={this._onPlay}
                            onEnd={this._onEnd}
                            onError={this._onError}
                            onPause={this._onPause}
                        />
                    </div>
                );
            } else {
                return (
                    <div
                        style={videoWrap}
                        className="video-responsive"
                        onClick={this.setShowControl}
                    >
                        <Player
                            playsInline
                            src={this.props.url}
                            autoplay={this.props.autoplay}
                            ref={"player"}
                        >
                            <BigPlayButton position="center" />
                        </Player>
                    </div>
                );
            }
        }
    };
    render() {
        let playerWrapper = {
            height: "50%",
            width: "100%",
        };
        return <div style={playerWrapper}>{this.loadPlayer()}</div>;
    }
}
