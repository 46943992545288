import React from "react";
import _ from "lodash";
import Text from "../../contentBlocks/Text.react";
import PropTypes from "prop-types";
import Separator from "../../contentBlocks/Separator.react";
import HandleButtonGroup from "../../contentBlocks/HandleButtonGroup.react";
import SlideHeader from "../../contentBlocks/SlideHeader.react";
import SlideHeaderSidebar from "../../contentBlocks/SlideHeaderSidebar.react";
import { Grid } from "semantic-ui-react";

export default class Timeliner extends React.Component {
    static propTypes = {
        colors: PropTypes.shape({
            base: PropTypes.string.isRequired,
            primary: PropTypes.string.isRequired,
            primaryVariation: PropTypes.string.isRequired,
            secondary: PropTypes.string.isRequired,
            baseText: PropTypes.string.isRequired,
            primaryText: PropTypes.string.isRequired,
            secondaryText: PropTypes.string.isRequired
        }).isRequired,
        data: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            question: PropTypes.string,
            bullets: PropTypes.array,
            purpose: PropTypes.string
        }).isRequired,
        slideId: PropTypes.number,
        trackInteraction: PropTypes.func.isRequired,
        mobileView: PropTypes.bool
    };

    static defaultProps = {
        mobileView: false
    };
    handleClick = items => {
        this.props.trackInteraction(items);
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let defaultStyle = {
            margin: "auto",
            width: "80%",
            textAlign: "center",
            paddingTop: "50px",
            height: "100%",
            //overflow: "hidden"
        };
        let defaultLandscapeStyle = {
            margin: "auto",
            width: "100%",
            textAlign: "center",
            position: "relative",
            float: "right",
            padding: "30px",
            height: "100%",
            //overflow: "hidden"
        };
        let title =
            this.props.data.header !== undefined
                ? this.props.data.header
                : this.props.data.title;
        let subtitle =
            this.props.data.subheader !== undefined
                ? this.props.data.subheader
                : this.props.data.subtitle;
        let bullets =
            this.props.data.bullets !== undefined
                ? this.props.data.bullets
                : _.map(this.props.data.options, option => {
                    return { "text": option.text, "year": option.year };
                });

        return (
            <div className="full-height">
                {isPortrait && (
                    <SlideHeader
                        {...this.props}
                        title={title}
                        subtitle={subtitle}
                    />
                )}
                {isPortrait && (
                    <div style={isPortrait ? defaultStyle : defaultLandscapeStyle}>
                        <Text
                            keyName={"question-" + this.props.slideId}
                            text={this.props.data.question}
                            color={this.props.colors.baseText}
                            fontSize="large"
                            animation="transition.expandIn,500,500"
                        />
                        <Separator
                            keyName="separator"
                            color={this.props.colors.primary}
                            animation="transition.bounceLeftIn,500,500"
                        />
                        <HandleButtonGroup
                            keyName="options"
                            colors={this.props.colors}
                            bullets={bullets}
                            animation="transition.slideDownIn,500,500"
                            callback={this.handleClick}
                            purpose={this.props.data.purpose}
                            mobileView={this.props.mobileView}
                        />
                    </div>
                )}
                {!isPortrait && (
                    <Grid style={{ minHeight: "100%" }}>
                        <Grid.Row style={{ paddingBottom: "0" }}>
                            <Grid.Column width={8} style={{ paddingRight: "0", backgroundColor: this.props.colors.primary }}>
                                <SlideHeaderSidebar
                                    {...this.props}
                                    title={title}
                                    subtitle={subtitle}
                                />
                            </Grid.Column>
                            <Grid.Column width={8} style={{ paddingLeft: "0" }}>
                                <div style={isPortrait ? defaultStyle : defaultLandscapeStyle}>
                                    <Text
                                        keyName={"question-" + this.props.slideId}
                                        text={this.props.data.question}
                                        color={this.props.colors.baseText}
                                        fontSize="large"
                                        animation="transition.expandIn,500,500"
                                    />
                                    <Separator
                                        keyName="separator"
                                        color={this.props.colors.primary}
                                        animation="transition.bounceLeftIn,500,500"
                                    />
                                    <HandleButtonGroup
                                        keyName="options"
                                        colors={this.props.colors}
                                        bullets={bullets}
                                        animation="transition.slideDownIn,500,500"
                                        callback={this.handleClick}
                                        purpose={this.props.data.purpose}
                                        mobileView={this.props.mobileView}
                                    />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
            </div>
        );
    }
}
