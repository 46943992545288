import { REHYDRATE } from "redux-persist/constants";
import { ACCEPT_TERMS, VIEWED_PROMO, FETCH_CONFIG } from "appRedux/helpers/reduxConstants";

const initialState = {
    promoViewed: false,
    termsAccepted: false,
    config: {},
};

const AppConfigReducer = (state = initialState, action) => {
    switch (action.type) {
        case REHYDRATE:
            let appConfig = action.payload.appConfig;
            if (appConfig) {
                return { ...state, ...appConfig };
            } else {
                return state;
            }
        case ACCEPT_TERMS:
            return { ...state, termsAccepted: true };
        case VIEWED_PROMO:
            return { ...state, promoViewed: true };
        case FETCH_CONFIG:
            return { ...state, config: action.data };
        default:
            return state;
    }
};

export { AppConfigReducer };
