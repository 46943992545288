import React from "react";
import PropTypes from "prop-types";
import ImageGrid from "../../contentBlocks/ImageGrid.react";
import Caption from "../../contentBlocks/Caption.react";
import SlideHeader from "../../contentBlocks/SlideHeader.react";
import SlideHeaderSidebar from "../../contentBlocks/SlideHeaderSidebar.react";
import { Grid as GridSystem } from "semantic-ui-react";

export default class Grid extends React.Component {
    static propTypes = {
        colors: PropTypes.shape({
            base: PropTypes.string.isRequired,
            primary: PropTypes.string.isRequired,
            primaryVariation: PropTypes.string.isRequired,
            secondary: PropTypes.string.isRequired,
            baseText: PropTypes.string.isRequired,
            primaryText: PropTypes.string.isRequired,
            secondaryText: PropTypes.string.isRequired,
        }).isRequired,
        data: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            caption: PropTypes.string,
            grid: PropTypes.array,
            image: PropTypes.string,
        }).isRequired,
        slideId: PropTypes.number,
        callback: PropTypes.func,
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let defaultStyle = {
            margin: "-20px auto",
            width: "100%",
            textAlign: "center",
            height: "100%",
            position: "relative",
        };

        let captionStyle = {
            position: "relative",
            textAlign: "center",
            width: "90%",
            margin: isPortrait ? "-10px auto" : "20px auto",
        };
        let defaultLandscapeStyle = {
            margin: "auto",
            width: "100%",
            textAlign: "center",
            //height: "100%",
            //paddingBottom: "70%",
            position: "relative",
            float: "right",
        };
        let title =
            this.props.data.header !== undefined
                ? this.props.data.header
                : this.props.data.title;
        let subtitle =
            this.props.data.subheader !== undefined
                ? this.props.data.subheader
                : this.props.data.subtitle;
        let grid =
            this.props.data.images !== undefined
                ? this.props.data.images
                : this.props.data.grid;
        return (
            <div className="full-height">
                {isPortrait ? (
                    <div>
                        <SlideHeader
                            {...this.props}
                            title={title}
                            subtitle={subtitle}
                        />
                        <div style={defaultStyle}>
                            <ImageGrid
                                {...this.props}
                                keyName={"image-group-" + this.props.slideId}
                                size="medium"
                                images={grid}
                                animation="transition.expandIn,500,500"
                                callback={this.props.callback}
                            />
                            <div style={captionStyle}>
                                <Caption
                                    keyName="caption"
                                    text={this.props.data.caption}
                                    colors={this.props.colors}
                                    animation="transition.bounceUpIn,400,750"
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <GridSystem style={{ minHeight: "100%" }}>
                        <GridSystem.Row style={{ paddingBottom: "0" }}>
                            <GridSystem.Column
                                width={8}
                                style={{
                                    paddingRight: "0",
                                    background: this.props.colors.primary,
                                }}
                            >
                                <SlideHeaderSidebar
                                    {...this.props}
                                    title={title}
                                    subtitle={subtitle}
                                />
                            </GridSystem.Column>
                            <GridSystem.Column
                                width={8}
                                style={{
                                    paddingLeft: "0",
                                    paddingBottom: "3%",
                                }}
                            >
                                <div style={defaultLandscapeStyle}>
                                    <ImageGrid
                                        {...this.props}
                                        keyName={
                                            "image-group-" + this.props.slideId
                                        }
                                        size="medium"
                                        images={grid}
                                        animation="transition.expandIn,500,500"
                                        callback={this.props.callback}
                                    />
                                    <div style={captionStyle}>
                                        <Caption
                                            keyName="caption"
                                            text={this.props.data.caption}
                                            colors={this.props.colors}
                                            animation="transition.bounceUpIn,400,750"
                                        />
                                    </div>
                                </div>
                            </GridSystem.Column>
                        </GridSystem.Row>
                    </GridSystem>
                )}
            </div>
        );
    }
}
