import React from "react";
import { Modal, Grid } from "semantic-ui-react";
import _ from "lodash";
import { getAppConfig } from "config/client.config";

class ProfileWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openModal: false,
            currentDeck: {}
        }
    }

    openModal = (deck) => {
        this.setState({
            openModal: true,
            currentDeck: deck
        })
    };
    toggleClose = () => {
        this.setState({ openModal: false })
    };

    render() {
        const isPortrait = window.innerHeight > window.innerWidth;

        let certDecks = _.filter(this.props.historyData, (data) => {
            if (data.readerType === "certdeck") {
                return _.find(this.props.courses, (course) => {
                    if (course._id === data.blob.course_id) {
                        return data['courseName'] = course.name
                    }
                })
            }
        });

        let badgeDecks = _.filter(this.props.historyData, (data) => {
            if (data.readerType === "badgedeck") {
                return _.find(this.props.courses, (course) => {
                    if (course._id === data.blob.course_id) {
                        return data['courseName'] = course.name
                    }
                })
            }
        });

        let medalDecks = _.filter(this.props.historyData, (data) => {
            if (data.readerType === "medaldeck") {
                return _.find(this.props.courses, (course) => {
                    if (course._id === data.blob.course_id) {
                        return data['courseName'] = course.name
                    }
                })
            }
        });

        let rewardDecks = _.filter(this.props.historyData, (data) => {
            if (data.readerType === "rewarddeck") {
                return _.find(this.props.courses, (course) => {
                    if (course._id === data.blob.course_id) {
                        return data['courseName'] = course.name
                    }
                })
            }
        });

        const segmentStyle = {
            marginBottom: 0,
            background: "#ffffff"
        };

        const containerStyle = {
            marginTop: isPortrait ? "50px" : "0%",
            width: "100%",
            height: isPortrait ? "100%" : "",
            marginLeft: "0",
        };

        let mainContainerStyle = {
            width: isPortrait ? "80%" : "100%",
            padding: isPortrait ? "0% 0% 0% 20%" : "",
        };
        let headerStyle = {
            fontSize: "1em",
            fontWeight: "700"
        };
        let rowSectionStyle = {
            overflowX: "auto",
            display: "flex",
            whiteSpace: "nowrap",
            gap: "2%",
            padding: "2%",
        };
        let sectionMain = {
            width: "100%",
            display: "inline-flex",
            gap: "1%",
            marginTop: "6px",
            color: "#AAAAAA",
        };
        let sectionDiv = {
            width: "50%",
            background: "#666666",
            borderRadius: "8px",
            overflow: "hidden auto",
            height: "45vh",
            textAlign: "center"
        };
        let modalContentStyle = {
            padding: isPortrait ? "8% 2%" : "2%",
            background: getAppConfig().colors.master,
            borderRadius: "8px"
        };
        let pointStyle = {
            float: "right",
            marginRight: "1%"
        }
        let pointImgStyle = {
            width: "38px",
            float: "right",
            marginTop: "-16px"
        };
        let badgeDivStyle = {
            background: "#666666",
            height: isPortrait ? "80px" : "12vh",
            marginTop: "2%",
            borderRadius: "8px",
            padding: "2%",
            color: "#AAAAAA"
        };
        let sectionHeader = {
            position: "fixed",
            width: isPortrait ? "47%" : "43%",
            background: "#666666",
            padding: "2% 1%",
            marginBottom: "0",
            zIndex: "1",
            textAlign: "left"
        };
        let gridStyle = {
            padding: "2% 13%",
            marginTop: "10%"
        };
        let dividerStyle = {
            width: "5em",
            height: "0.1em",
            backgroundColor: `${getAppConfig().colors.accents.primary}`,
            border: "none",
            margin: "0 auto"
        };
        let noContentCol = {
            textAlign: "center",
            marginTop: "23%"
        };
        let nowContentRow = {
            textAlign: "center",
            marginTop: "3%"
        };
        let defaultImgCol = {
            width: isPortrait ? "75px" : "95px",
            height: "62px"
        };
        let badgeStyle = {
            float: "right",
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            textAlign: "center",
            background: getAppConfig().colors.accents.primary,
            color: getAppConfig().colors.accents.primaryText,
        }

        const getProfileData = () => {
            return (
                <div style={modalContentStyle}>
                    <h3 style={{
                        textAlign: "center",
                        color: "#fff",
                    }}>
                        My Profile
                        <hr style={dividerStyle} />
                    </h3>
                    <div style={{ ...headerStyle, color: "#fff" }}>
                        <span style={{ color: getAppConfig().colors.accents.primary }}>
                            {`${this.props.auth.user.first_name} ${this.props.auth.user.last_name}`}
                        </span>
                        <img
                            src="assets/images/theme/points.png"
                            alt="points"
                            style={pointImgStyle}
                        />
                        <span style={pointStyle}>Points</span>
                    </div>

                    <div style={badgeDivStyle}>
                        <h5 style={{ marginBottom: "0" }}>My Badges
                            <div style={badgeStyle}>
                                {badgeDecks.length}
                            </div>
                        </h5>
                        {badgeDecks.length !== 0 ? <div
                            style={rowSectionStyle}>
                            {_.map(badgeDecks, (badge, i) => {
                                return (
                                    <div key={`cert-${i}`}>
                                        <img src={badge.blob.content.image
                                            ? getAppConfig().apiUrls.assetUrl + badge.blob.content.image
                                            : "assets/images/theme/badge.png"}
                                            style={{
                                                width: "40px",
                                                height: "40px",
                                            }}
                                            alt="badge" />
                                    </div>
                                )
                            })}
                        </div>
                            :
                            <div style={nowContentRow}>No Badges Available!</div>
                        }

                    </div>
                    <div style={badgeDivStyle}>
                        <h5 style={{ marginBottom: "0" }}>My Medals</h5>
                        {medalDecks.length !== 0 ?
                            <div
                                style={rowSectionStyle}>
                                {_.map(medalDecks, (medal, i) => {
                                    return (
                                        <div key={`cert-${i}`}>
                                            <img src={medal.blob.content.image
                                                ? getAppConfig().apiUrls.assetUrl + medal.blob.content.image
                                                : "assets/images/theme/badge.png"}
                                                style={{
                                                    width: "40px",
                                                    height: "40px",
                                                }}
                                                alt="medal" />
                                        </div>
                                    )
                                })}
                            </div>
                            :
                            <div style={nowContentRow}>
                                No Medals Available!
                            </div>
                        }
                    </div>
                    <div style={sectionMain}>
                        <div style={sectionDiv}>
                            <h5 style={sectionHeader}>
                                My Rewards
                            </h5>
                            {rewardDecks.length !== 0 ?
                                <Grid columns={2} style={gridStyle}>
                                    <Grid.Row>
                                        {_.map(rewardDecks, (reward, idx) => {
                                            return (
                                                <Grid.Column key={`cert-${idx}`} style={{ padding: "2px" }}>
                                                    <img
                                                        src={
                                                            reward.blob.content.image
                                                                ? getAppConfig().apiUrls.assetUrl + reward.blob.content.image
                                                                : "assets/images/theme/rewardcard.png"
                                                        }
                                                        alt="cert"
                                                        style={defaultImgCol}
                                                    />
                                                </Grid.Column>
                                            )
                                        })}
                                    </Grid.Row>
                                </Grid>
                                :
                                <div style={noContentCol}>
                                    No Rewards Available!
                                </div>
                            }
                        </div>
                        <div style={sectionDiv}>
                            <h5 style={sectionHeader}>My Certificates
                                <div style={badgeStyle}>{certDecks.length}</div>
                            </h5>
                            {certDecks.length !== 0 ?
                                <Grid columns={2} style={gridStyle}>
                                    <Grid.Row>
                                        {_.map(certDecks, (deck, idx) => {
                                            return (
                                                <Grid.Column key={`cert-${idx}`} style={{ padding: "2px" }}>
                                                    <img
                                                        src="assets/images/theme/cerdeck.png"
                                                        alt="cert"
                                                        style={{ ...defaultImgCol, cursor: "pointer" }}
                                                        onClick={() => {
                                                            this.openModal(deck)
                                                        }}
                                                    />
                                                </Grid.Column>
                                            )
                                        })}
                                    </Grid.Row>
                                </Grid>
                                : <div style={noContentCol}>
                                    No Certificates Available!
                                </div>
                            }
                        </div>
                    </div>
                </div>
            )
        };
        return (
            <div style={containerStyle}>
                {isPortrait && (
                    <div
                        style={segmentStyle}
                    >
                        {getProfileData()}
                    </div>
                )}
                {!isPortrait && (
                    <div style={mainContainerStyle}>
                        <Modal
                            closeIcon
                            open={this.props.openModal}
                            onClose={this.props.toggleDisplayProfile}
                            size="tiny"
                            style={{ borderRadius: "5px" }}
                        >
                            <Modal.Content
                                style={{ background: getAppConfig().colors.master }}
                            >
                                {getProfileData()}
                            </Modal.Content>
                        </Modal>
                    </div>
                )}
                {this.state.openModal &&
                    <Modal
                        closeIcon
                        open={this.state.openModal}
                        onClose={this.toggleClose}
                        size="mini"
                        style={{ borderRadius: "5px", top: "40%" }}
                    >
                        <Modal.Content style={{ width: isPortrait ? "90%" : "100%" }}>
                            <div>
                                <b>Course Name: </b>
                                {this.state.currentDeck.courseName}
                            </div>
                            <br />
                            <div>
                                <b>Deck Name: </b>
                                {this.state.currentDeck.name}
                            </div>
                        </Modal.Content>
                    </Modal>
                }
            </div>
        );
    }
}

export default ProfileWrapper;
