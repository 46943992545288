import React, { Component } from "react";
import _ from "lodash";
import { List } from "semantic-ui-react";
export class SuggestedArticles extends Component {
    render() {
        let listItem = {
            lineHeight: "1.5",
            padding: "0 10px",
        };
        return (
            <List divided relaxed style={{ cursor: "pointer" }} >
                {
                    _.map(this.props.suggestedArticles, (article) => {
                        return (
                            <List.Item
                                key={Math.random()}
                            >
                                <List.Content style={listItem}>
                                    <span
                                        onClick={() => this.props.selectSuggestedArticle(
                                            article
                                        )}
                                    >
                                        {article.name}
                                    </span>
                                </List.Content>
                            </List.Item>
                        );
                    })
                }
            </List >
        );
    }
}