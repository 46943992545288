import React, { Component } from "react";
import { Icon, Image, Label } from "semantic-ui-react";
import { getAppConfig } from "config/client.config";
import _ from "lodash";

export class ProfileCard extends Component {
    render() {
        const isPortrait = window.innerHeight > window.innerWidth;
        let content = this.props.content;
        let user = this.props.auth.user;
        let hasBackground = content.background && content.background !== "" ? true : false;
        let keys = content.showDetails ? content.showDetails.slice() : [];
        let userDetails = this.props.auth.user.details ? this.props.auth.user.details : undefined;
        let infoFields = content.info ? Object.keys(content.info) : [];



        let truncate = {
            width: "280px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
        }

        let tempAvatarStyle = hasBackground ? {
            width: "120px",
            height: "120px",
            transform: isPortrait ? "translate(100%, 28%)" : "translate(84px, 33px)"
        } : {
            width: isPortrait ? "100px" : "90px",
            height: isPortrait ? "100px" : "90px",
            transform: isPortrait ? "translate(127%, 13%)" : "translate(118%, 13%)"
        }

        let profilecard = {
            main: {
                minHeight: "15vh",
                marginBottom: "10px",
                display: "flex",
                flexDirection: "column",
                borderRadius: "5px",
                boxShadow: "0 1px 2px 0 #22242626"
            },
            upperhalf: {
                minHeight: hasBackground ? "17vh" : "9vh",
                display: "flex",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
                background: getAppConfig().colors.menuColor,
                backgroundImage: `url(${content.background})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover"
            },
            lowerhalf: {
                minHeight: "6vh",
                display: "flex",
                flexWrap: "wrap",
                borderBottomLeftRadius: "5px",
                borderBottomRightRadius: "5px",
            },
            avatar: {
                ...tempAvatarStyle,
                position: "absolute",
                display: "flex",
                borderRadius: "50%",
                background: user.image ? "none" : getAppConfig().colors.iconColor,
                boxShadow: "1px 1px 5px 0px #696969"
            },
            avatartext: {
                margin: "auto",
                fontSize: "45px",
                color: getAppConfig().colors.menuColor
            },
            infocard: {
                margin: "5% auto",
                textAlign: "center"
            },
            name: {
                padding: "15% 0 2% 0",
                fontSize: "25px",
                fontWeight: "bolder",
                color: getAppConfig().colors.accents.primary,
                lineHeight: "1.2"
            },
            userdetails: {
                color: getAppConfig().colors.accents.secondary,
                textAlign: content.alignment || "left",
                padding: "0 5%",
                ...truncate
            },
            detailsSection: {
                textAlign: "center",
                margin: "0px auto 11px auto"
            },
            tagStyle: {
                marginBottom: "5px",
                fontSize: "1em",
            }
        }

        const showDetails =
            _.map(keys, (key) => {
                let value = userDetails[key];
                if (value) {
                    return <Label
                        key={`detail-${key}`}
                        style={profilecard.tagStyle}
                        color="grey"
                        image
                    >
                        {_.startCase(key)}<Label.Detail>{value}</Label.Detail>
                    </Label>
                }
            })

        const showInfo =
            _.map(infoFields, (key) => {
                if (user[key]) {
                    return <span key={'field-' + key}><Icon name={content.info[key].icon}></Icon>{user[key]}<br /></span>;
                }
            })

        return (
            <div style={profilecard.main} >
                <div style={profilecard.upperhalf}></div>
                {user.image && (
                    <Image
                        src={user.image}
                        avatar
                        style={profilecard.avatar}
                    />
                )}
                {
                    !user.image && (
                        <div id="profile-avtar" style={profilecard.avatar}>
                            <b style={profilecard.avatartext}>
                                {`${user.first_name && typeof user.first_name === "string" ? user.first_name.charAt(0) : ""}${user.last_name && typeof user.last_name === "string" ? user.last_name.charAt(0) : ""}`}
                            </b>
                        </div>
                    )
                }
                <div style={profilecard.lowerhalf}>
                    <div style={profilecard.infocard}>
                        <div style={profilecard.name}>{`${user.first_name} ${user.last_name}`}</div>
                        <div style={profilecard.userdetails}>
                            {showInfo}
                        </div>
                    </div>
                    {userDetails && keys.length > 0 && (
                        <div style={profilecard.detailsSection}>
                            {showDetails}
                        </div>
                    )}
                </div>
            </div >
        );
    }
}
