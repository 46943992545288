import _ from "lodash";
import { FETCH_BLOG, FETCH_TAGS, FETCH_ADS, FETCH_RECENT_VIEWED } from "appRedux/helpers/reduxConstants";
import { REHYDRATE } from "redux-persist/constants";

const initialBlogState = {
    articles: [],
    tags: [],
    ads: [],
};

const BlogReducer = (state = initialBlogState, action) => {
    switch (action.type) {
        case REHYDRATE:
            let blog = action.payload.blog;
            if (blog) {
                return { ...state, ...blog };
            } else {
                return state;
            }
        case FETCH_BLOG:
            if (action.status === "fetching") {
                return { ...state, fetchCompleted: false, error: null };
            } else if (action.status === "success") {
                return { articles: _.reverse(action.data.articles) };
            } else {
                return { ...state, error: action.err, fetchCompleted: true };
            }
        case FETCH_TAGS:
            if (action.status === "fetching") {
                return { ...state, fetchCompleted: false, error: null };
            } else if (action.status === "success") {
                return { ...state, tags: action.data.tags };
            } else {
                return { ...state, error: action.err, fetchCompleted: true };
            }
        case FETCH_ADS:
            if (action.status === "fetching") {
                return { ...state, fetchCompleted: false, error: null };
            } else if (action.status === "success") {
                return { ...state, ads: action.data.ads };
            } else {
                return { ...state, error: action.err, fetchCompleted: true };
            }
        case FETCH_RECENT_VIEWED:
            if (action.status === "fetching") {
                return { ...state, fetchCompleted: false, error: null };
            } else if (action.status === "success") {
                return { ...state, recentViewed: action.data.recentViewed };
            } else {
                return { ...state, error: action.err, fetchCompleted: true };
            }
        default:
            return state;
    }
};

export { BlogReducer };
