// Import from NPM
// -------------------------------------
import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Grid, Icon, Image, Dropdown, Input } from "semantic-ui-react";
import { hashHistory, Link } from "react-router";

// Import from Config
// -------------------------------------
import { getAppConfig } from "config/client.config";
import AnalyticsContainer from "../../analytics/AnalyticsContainer.react";

// Import Components
// -------------------------------------
import NotificationContainer from "../../notification/NotificationContainer.react";
import { MenuNavigator } from "../../blog/components/listingMenuPanels/MenuNavigator.react";
import ProfileContainer from "../../profile/ProfileContainer.react";
import NotificationBadge from "./NotificationBadge.react";
import { Button } from "config/initializers/styles.app";
import ContactUsModal from "./ContactUsModal.react";
import HelpDeskAction from "appRedux/HelpDesk.action.react";

export class DesktopNavbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            displayNotification: false,
            slideMenuVisible: false,
            enableTagsList: false,
            selectedTagParent: null,
            displayProfile: false,
            currentTab: 0,
            openContactUs: false
        };
    }
    toggleSlideMenu = () => {
        this.setState({
            slideMenuVisible: true,
        });
    };
    handleClose = () => {
        this.setState({
            slideMenuVisible: false,
            currentTab: 0
        });
    };
    goToPage = (a) => {
        if (a.indexOf("/profile") !== -1)
            this.toggleDisplayProfile();
        else if (a.indexOf("/notifications") !== -1)
            this.toggleDisplayNotification();
        else if (a.indexOf("http") === -1 && a.indexOf("mailto:") === -1)
            hashHistory.push(a);
        else if (a.indexOf("mailto:") !== -1) window.open(a, "_blank");
        else window.location = a;
        this.setState({ enableTagsList: false });
    };
    goToHome = () => {
        window.localforage
            .setItem("lastLocation", "home")
            .then(() => hashHistory.push("/session/home"));
        this.setState({ enableTagsList: false });
    };
    handleSearch = (e) => {
        if (e.key === "Enter") {
            if (this.state.searchText.length > 2)
                hashHistory.push(`/articles?search=${this.state.searchText}`);
        } else {
            this.setState({ searchText: e.target.value.trim() });
        }
    };

    toggleDisplayNotification = () =>
        this.setState({ displayNotification: !this.state.displayNotification });

    toggleDisplayProfile = () =>
        this.setState({ displayProfile: !this.state.displayProfile });

    handleContactsButtonClick = () =>
        this.setState({ openContactUs: !this.state.openContactUs })

    selectTags = (tags) => {
        if (tags.indexOf(",") !== -1) {
            hashHistory.push("/articles?tags=" + tags.join(","));
        } else {
            hashHistory.push("/articles?tags=" + tags);
        }
        this.setState({ enableTagsList: false });
    };

    closeTagsList = () => {
        this.setState({ enableTagsList: false });
    };
    render() {
        let barStyle = {
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            background: getAppConfig().colors.menuColor,
            zIndex: "1000",
            height: "55px",
            display: window.innerWidth > 768 ? "block" : "none",
        };
        let navbarStyle = {
            maxWidth: "1280px",
            margin: "0 auto",
        };
        let logoBlockStyle = {
            height: "66px",
            width: "300px",
            display: "inline-block",
            cursor: "pointer",
        };
        let logoStyle = {
            height: "33px",
            marginTop: "11px",
            marginLeft: "22px",
        };

        let navIconStyle = {
            menu: {
                paddingLeft: "15px",
                display: "inline-block",
                width: "100%",
            },
            block: {
                display: "inline-block",
                marginTop: "11px",
                textAlign: "center",
                color: getAppConfig().colors.iconColor,
                marginRight: "25px",
                cursor: "pointer",
            },
            activeBlock: {
                display: "inline-block",
                textAlign: "center",
                background: "rgba(0,0,0,0.5)",
                color: getAppConfig().colors.iconColor,
                padding: "11px 12.5px 4px 12.5px",
                margin: "0px 12.5px 0 -12.5px",
                cursor: "pointer",
            },
            dropDownblock: {
                display: "inline-block",
                marginTop: "11px",
                textAlign: "center",
                color: getAppConfig().colors.iconColor,
                marginRight: "7px",
                cursor: "pointer",
            },
            activeDropDownBlock: {
                display: "inline-block",
                textAlign: "center",
                background: "rgba(0,0,0,0.5)",
                color: getAppConfig().colors.iconColor,
                padding: "11px 12.5px 4px 12.5px",
                margin: "0px 0px 0 -12.5px",
                cursor: "pointer",
            },
            icon: { fontSize: "20px" },
            name: { fontSize: "10px" },
            avatar: { marginTop: "13.5px" },
        };
        let loginBtn = {
            float: "right",
            margin: "13px 10px",
            fontSize: "1.2em",
            color: getAppConfig().colors.iconColor,
        };
        let learnBlock = {
            display: "inline-block",
            textAlign: "center",
            color: getAppConfig().colors.iconColor,
            padding: "11px 12.5px 4px 12.5px",
            margin: "0px 12.5px 0 -12.5px",
            cursor: "pointer",
        };
        let userProfileStyle = {
            right: "1%",
            textAlign: "right"
        }
        let profileSettings = getAppConfig().learningCenter.profileList
            ? getAppConfig().learningCenter.profileList
            : {};

        if (this.props.auth.user && this.props.auth.isUserLoggedIn)
            return (
                <div style={barStyle}>
                    <div style={navbarStyle}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={3}>
                                    {getAppConfig().inductor && this.props.auth.user.courses.length === 1 ?
                                        <img
                                            src={getAppConfig().ui.logo}
                                            alt="Brand Logo"
                                            style={logoStyle}
                                        />
                                        :
                                        <a
                                            style={logoBlockStyle}
                                            onClick={this.goToHome}
                                        >
                                            <img
                                                src={getAppConfig().ui.logo}
                                                alt="Brand Logo"
                                                style={logoStyle}
                                            />
                                        </a>
                                    }
                                </Grid.Column>
                                {this.props.blog.articles.length > 0 && (
                                    <Grid.Column width={4}>
                                        {this.props.blog.articles.length >
                                            0 && (
                                                <Input
                                                    fluid
                                                    icon="search"
                                                    placeholder="Search..."
                                                    onKeyUp={this.handleSearch}
                                                    style={{ marginTop: "9px" }}
                                                />
                                            )}
                                    </Grid.Column>
                                )}
                                <Grid.Column
                                    width={
                                        this.props.blog.articles.length !== 0
                                            ? Object.keys(getAppConfig().learningCenter.menuIcons[0]).length === 0
                                                && getAppConfig().inductor ? 6 : 8
                                            : getAppConfig().inductor ? 8 : 12
                                    }
                                >
                                    <div style={navIconStyle.menu}>
                                        {getAppConfig().learningCenter
                                            .profileList.enabledLearn
                                            .enabled && (
                                                <span>
                                                    <div style={learnBlock}>
                                                        <Icon
                                                            name={
                                                                getAppConfig()
                                                                    .learningCenter
                                                                    .profileList
                                                                    .enabledLearn
                                                                    .icon
                                                            }
                                                            style={
                                                                navIconStyle.icon
                                                            }
                                                        />
                                                        <div
                                                            style={
                                                                navIconStyle.name
                                                            }
                                                        >
                                                            {
                                                                getAppConfig()
                                                                    .learningCenter
                                                                    .profileList
                                                                    .enabledLearn
                                                                    .name
                                                            }
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            marginTop: "-40px",
                                                            fontSize: "40px",
                                                        }}
                                                    >
                                                        <MenuNavigator
                                                            tags={
                                                                this.props.blog.tags
                                                            }
                                                            blog={this.props.blog}
                                                            {...this.props}
                                                            queryParams={
                                                                getAppConfig()
                                                                    .learningCenter
                                                                    .profileList
                                                                    .enabledLearn
                                                            }
                                                            clearTags={
                                                                this.clearTags
                                                            }
                                                            selectTags={
                                                                this.selectTags
                                                            }
                                                            closeTagsList={
                                                                this.closeTagsList
                                                            }
                                                            enableTagsList={
                                                                this.state
                                                                    .enableTagsList
                                                            }
                                                            menuNavigation={true}
                                                            selectTag={true}
                                                            articles={
                                                                this.props.blog
                                                                    .articles
                                                            }
                                                        />
                                                    </div>
                                                </span>
                                            )}{" "}
                                        {_.map(
                                            getAppConfig().learningCenter
                                                ? getAppConfig().learningCenter
                                                    .menuIcons
                                                : {},
                                            (icon, idx) => {
                                                let href =
                                                    this.props.location
                                                        .pathname +
                                                    "?" +
                                                    _.map(
                                                        _.toPairs(
                                                            this.props.location
                                                                .query
                                                        ),
                                                        (pair) =>
                                                            `${pair[0]}=${pair[1]}`
                                                    ).join("&");
                                                if (icon.children == null) {
                                                    return (
                                                        <div
                                                            key={`desktop-navbar-icon-${idx}`}
                                                            onClick={() =>
                                                                this.goToPage(
                                                                    icon.link
                                                                )
                                                            }
                                                            style={
                                                                href.includes(
                                                                    icon.link
                                                                )
                                                                    ? navIconStyle.activeBlock
                                                                    : navIconStyle.block
                                                            }
                                                        >
                                                            <Icon
                                                                name={icon.icon}
                                                                style={
                                                                    navIconStyle.icon
                                                                }
                                                            />
                                                            <div
                                                                style={
                                                                    navIconStyle.name
                                                                }
                                                            >
                                                                {icon.name}
                                                            </div>
                                                        </div>
                                                    );
                                                } else {
                                                    let iconLinks = _.filter(
                                                        icon.children,
                                                        (subicon) =>
                                                            href.includes(
                                                                subicon.link
                                                            )
                                                    );
                                                    return (
                                                        <Dropdown
                                                            key={`desktop-navbar-icon-${idx}`}
                                                            icon=""
                                                            trigger={
                                                                <div
                                                                    key={`desktop-navbar-icon-${idx}`}
                                                                    style={
                                                                        iconLinks.length >
                                                                            0
                                                                            ? navIconStyle.activeDropDownBlock
                                                                            : navIconStyle.dropDownblock
                                                                    }
                                                                >
                                                                    <Icon
                                                                        name={
                                                                            icon.icon
                                                                        }
                                                                        style={
                                                                            navIconStyle.icon
                                                                        }
                                                                    />
                                                                    <div
                                                                        style={
                                                                            navIconStyle.name
                                                                        }
                                                                    >
                                                                        {
                                                                            icon.name
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                        >
                                                            <Dropdown.Menu>
                                                                {_.map(
                                                                    icon.children,
                                                                    (
                                                                        subicon,
                                                                        idy
                                                                    ) => {
                                                                        return (
                                                                            <Dropdown.Item
                                                                                key={`desktop-navbar-icon-${idx}-${idy}`}
                                                                                icon={
                                                                                    subicon.icon
                                                                                }
                                                                                text={
                                                                                    subicon.name
                                                                                }
                                                                                onClick={() =>
                                                                                    this.goToPage(
                                                                                        subicon.link
                                                                                    )
                                                                                }
                                                                            />
                                                                        );
                                                                    }
                                                                )}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    );
                                                }
                                            }
                                        )}
                                        {profileSettings.notifications && profileSettings.enableContactUs && (
                                            <NotificationBadge
                                                toggleDisplayNotification={this.toggleDisplayNotification}
                                                unreadNotification={this.props.notifications.unreadNotification}
                                            />
                                        )}

                                    </div>
                                </Grid.Column>
                                <Grid.Column
                                    width={getAppConfig().inductor ? 5 : 1}
                                    style={getAppConfig().inductor ? userProfileStyle : {}}
                                >
                                    {profileSettings.enableContactUs && (
                                        <Button
                                            grouped
                                            content={"Contact us for Free Trial"}
                                            onClick={this.handleContactsButtonClick}
                                            style={{ margin: "13px 4px", color: getAppConfig().colors.iconColor }}
                                        />
                                    )}
                                    {/* {profileSettings.notifications && profileSettings.enableContactUs === false && (
                                        <NotificationBadge
                                            toggleDisplayNotification={this.toggleDisplayNotification}
                                            unreadNotification={this.props.notifications.unreadNotification}
                                            right="45%"
                                        />
                                    )} */}
                                    {getAppConfig().inductor ? <div style={{
                                        display: "flex",
                                        float: "right",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "55px"
                                    }}>
                                        <span style={{
                                            color: getAppConfig().colors.iconColor,
                                            paddingRight: "8px",
                                            fontSize: "1em",
                                            fontWeight: "bold"
                                        }}>
                                            {text_truncate(`${this.props.user.first_name} ${this.props.user.last_name}`, 18)}
                                        </span>
                                        <Image
                                            src="assets/images/configurable/avatar.jpg"
                                            avatar
                                        />
                                    </div>
                                        :
                                        <Dropdown
                                            icon=""
                                            trigger={
                                                <Image
                                                    src="assets/images/configurable/avatar.jpg"
                                                    avatar
                                                    style={navIconStyle.avatar}
                                                />
                                            }
                                        >
                                            <Dropdown.Menu
                                                style={{
                                                    marginLeft: "-120px",
                                                    marginTop: "15px",
                                                    minWidth: "12vw"
                                                }}
                                            >
                                                <Dropdown.Header
                                                    content={`${this.props.user.first_name} ${this.props.user.last_name}`}
                                                />
                                                <Dropdown.Divider />
                                                {profileSettings.notifications && (
                                                    <Dropdown.Item
                                                        icon={"bell"}
                                                        text={"Notifications"}
                                                        onClick={
                                                            this
                                                                .toggleDisplayNotification
                                                        }
                                                    />
                                                )}
                                                {profileSettings.editProfile && (
                                                    <Dropdown.Item
                                                        icon={"edit outline"}
                                                        text={"Edit Profile"}
                                                        onClick={() =>
                                                            this.goToPage(
                                                                "/editProfile"
                                                            )
                                                        }
                                                    />
                                                )}
                                                {profileSettings.myperformance && getAppConfig().apiUrls.gamifixURL && (
                                                    <Dropdown.Item
                                                        icon={"chart pie"}
                                                        text={"My Performance"}
                                                        onClick={() => {
                                                            this.toggleSlideMenu()
                                                            this.setState({ currentTab: 5 })
                                                        }
                                                        }
                                                    />
                                                )}
                                                {profileSettings.reports &&
                                                    this.props.user.username !==
                                                    "sysadmin" && (
                                                        <Dropdown.Item
                                                            icon={"chart area"}
                                                            text={
                                                                "Progress Reports"
                                                            }
                                                            onClick={() =>
                                                                this.toggleSlideMenu()
                                                            }
                                                        />
                                                    )}
                                                {profileSettings.logout && (
                                                    <Dropdown.Item
                                                        icon={"log out"}
                                                        text={"Exit"}
                                                        onClick={
                                                            () =>
                                                                this.goToPage(
                                                                    "/logout"
                                                                )
                                                            // this.goToPage(
                                                            //     getAppConfig().auth
                                                            //         .routes
                                                            //         .unauthenticatedRoot
                                                            // )
                                                        }
                                                    />
                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    }
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        {this.state.slideMenuVisible && (
                            <AnalyticsContainer
                                currentTab={this.state.currentTab}
                                slideMenuVisible={this.state.slideMenuVisible}
                                handleClose={this.handleClose}
                            />
                        )}
                    </div>
                    {
                        this.state.displayNotification && (
                            <NotificationContainer
                                openModal={this.state.displayNotification}
                                toggleNotification={this.toggleDisplayNotification}
                            />
                        )
                    }
                    {
                        this.state.displayProfile && (
                            <ProfileContainer
                                openModal={this.state.displayProfile}
                                toggleDisplayProfile={this.toggleDisplayProfile}
                            />
                        )
                    }
                    {
                        this.state.openContactUs && (
                            <ContactUsModal
                                openModal={this.state.openContactUs}
                                handleModalClose={this.handleContactsButtonClick}
                                sendContactRequst={this.props.actions.sendContactRequst}
                            />
                        )
                    }
                </div >
            );
        else
            return (
                <div style={barStyle}>
                    <a href="#/session/home" style={logoBlockStyle}>
                        <img
                            src={getAppConfig().ui.logo}
                            alt="Brand Logo"
                            style={logoStyle}
                        />
                    </a>
                    <Link to="/login" style={loginBtn}>
                        Login
                    </Link>
                </div>
            );
    }
}
const mapStateToProps = (state) => {
    return {
        courses: state.courses,
        auth: state.auth,
        blog: state.blog,
        notifications: state.notification
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            sendContactRequst: (name,
                phonenumber,
                workemail,
                organisation,
                designation,
                message) => {
                return dispatch(HelpDeskAction.sendContactRequst(
                    name,
                    phonenumber,
                    workemail,
                    organisation,
                    designation,
                    message));
            }
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DesktopNavbar);

const text_truncate = function (str, length, ending) {
    if (str == null) {
        str = "";
    }
    if (length == null) {
        length = 100;
    }
    if (ending == null) {
        ending = "...";
    }
    if (str.length > length) {
        return str.substring(0, length - ending.length) + ending;
    } else {
        return str;
    }
};