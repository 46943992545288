import _ from "lodash";
import { REHYDRATE } from "redux-persist/constants";
import {
    FETCH_LIBRARY,
    AGGREGATE_PROGRAM,
    SET_LAST_NODE
} from "appRedux/helpers/reduxConstants";
import { program_aggregation } from "appRedux/helpers/aggregation.helpers";

const initialState = {};
const defaultProgramState = {
    completion: false,
    score: 0,
    viewedPercentage: 0,
    completionDate: null,
    timeSpent: 0,
    viewAttempts: 0,
    lastPosition: 0,
    subStates: {}
};
const defaultProgramHistory = {
    f: {},
    y: {},
    q: {},
    m: {},
};

const ProgramsReducer = (state = initialState, action) => {
    switch (action.type) {
        /* istanbul ignore next - react-persist function */
        case REHYDRATE:
            let programs = action.payload.programs;
            if (programs) {
                return { ...state, ...programs };
            } else {
                return state;
            }

        case FETCH_LIBRARY:
            return Object.assign(
                {},
                _.mapValues(action.data.programs, program => {
                    let newCoursesState = {};
                    let newProgramsState = {};
                    _.mapValues(program.node_list, node => {
                        if (program.listType === "courses")
                            newCoursesState[node.id] = false;
                        else if (program.listType === "programs")
                            newProgramsState[node.id] = false;
                    });
                    return Object.assign({}, program, {
                        lastNode: program.currentState
                            ? program.currentState.lastNode
                            : 0,
                        currentState:
                            program.currentState === undefined
                                ? Object.assign({}, defaultProgramState, {
                                      coursesState: newCoursesState,
                                      programsState: newProgramsState
                                  })
                                : Object.assign({}, program.currentState, {
                                      coursesState: {
                                          ...newCoursesState,
                                          ...program.currentState.coursesState
                                      },
                                      programsState: {
                                          ...newProgramsState,
                                          ...program.currentState.programsState
                                      }
                                  }),
                        history:
                            program.history === undefined
                                ? defaultProgramHistory
                                : program.history
                    });
                })
            );
        case AGGREGATE_PROGRAM:
            return program_aggregation(state, action);
        case SET_LAST_NODE:
            let programState = Object.assign({}, state);
            let currentProgram = Object.assign(
                {},
                state[action.data.programId]
            );
            currentProgram.lastNode = action.data.lastNode;
            currentProgram.currentState.lastNode = currentProgram.lastNode;
            programState[action.data.programId] = currentProgram;
            return Object.assign({}, state, programState);
        default:
            return state;
    }
};

export { ProgramsReducer };
