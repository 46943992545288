import React from "react";
import PropTypes from "prop-types";
import InteractiveIconBullets from "../../contentBlocks/InteractiveIconBullets.react";
import SlideHeader from "../../contentBlocks/SlideHeader.react";
import SlideHeaderSidebar from "../../contentBlocks/SlideHeaderSidebar.react";
import { Grid } from "semantic-ui-react";

export default class IconListTimeline extends React.Component {
    static propTypes = {
        data: PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            iconlist: PropTypes.array,
        }).isRequired,
        colors: PropTypes.shape({
            baseText: PropTypes.string,
            primary: PropTypes.string,
        }).isRequired,
        slideId: PropTypes.number,
        mobileView: PropTypes.bool,
    };

    static defaultProps = {
        mobileView: false,
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let defaultStyle = {
            margin: isPortrait ? "50px auto" : "30px",
            width: "90%",
            textAlign: "center",
            padding: "0",
            height: isPortrait ? "100%" : "86%",
            overflowY: "auto",
        };
        let defaultLandscapeStyle = {
            margin: "auto",
            width: "100%",
            textAlign: "center",
            height: "100%",
            position: "relative",
            float: "right",
        };
        let timelineStyle = {
            position: "absolute",
            top: "-30px",
            left: isPortrait ? "3.6em" : "4.5em",
            height: "100%",
            width: "6px",
            background: "#666",
        };
        return (
            <div className="full-height">
                {isPortrait ? (
                    <div>
                        <SlideHeader
                            {...this.props}
                            title={this.props.data.title}
                            subtitle={this.props.data.subtitle}
                        />
                        <div className="full-height relativeDiv">
                            <div style={timelineStyle} />
                            <div style={defaultStyle}>
                                <InteractiveIconBullets
                                    {...this.props}
                                    keyName={
                                        "icon-bullets-" + this.props.slideId
                                    }
                                    list={this.props.data.iconlist}
                                    color={this.props.colors.baseText}
                                    animation="transition.slideDownIn,600,600"
                                    mobileView={this.props.mobileView}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <Grid style={{ minHeight: "100%" }}>
                        <Grid.Row style={{ paddingBottom: "0" }}>
                            <Grid.Column
                                width={8}
                                style={{
                                    paddingRight: "0",
                                    background: this.props.colors.primary,
                                }}
                            >
                                <SlideHeaderSidebar
                                    {...this.props}
                                    title={this.props.data.title}
                                    subtitle={this.props.data.subtitle}
                                />
                            </Grid.Column>
                            <Grid.Column width={8} style={{ paddingLeft: "0" }}>
                                <div style={defaultLandscapeStyle}>
                                    <div className="full-height relativeDiv">
                                        <div style={timelineStyle} />
                                        <div style={defaultStyle}>
                                            <InteractiveIconBullets
                                                {...this.props}
                                                keyName={
                                                    "icon-bullets-" +
                                                    this.props.slideId
                                                }
                                                list={this.props.data.iconlist}
                                                color={
                                                    this.props.colors.baseText
                                                }
                                                animation="transition.slideDownIn,600,600"
                                                mobileView={
                                                    this.props.mobileView
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
            </div>
        );
    }
}
