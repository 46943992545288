import React from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";
//import _ from 'lodash';

import Text from "../contentBlocks/Text.react";

export default class HandleButton extends React.Component {
    static propTypes = {
        sequence: PropTypes.number,
        text: PropTypes.string,
        colors: PropTypes.shape({
            base: PropTypes.string.isRequired,
            primary: PropTypes.string.isRequired,
            primaryVariation: PropTypes.string.isRequired,
            secondary: PropTypes.string.isRequired,
            baseText: PropTypes.string.isRequired,
            primaryText: PropTypes.string.isRequired,
            secondaryText: PropTypes.string.isRequired
        }).isRequired
    };
    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;
        let divWrapper = {
                margin: "10px 0",
                width: "100%"
            },
            labelBlock = {
                float: "left",
                width: "36px",
                height: "24px",
                padding: "12px 10px",
                background: this.props.colors.primary,
                color: this.props.colors.primaryText,
                marginRight: "6px",
                fontSize: "1.2em"
            },
            contentBlock = {
                position: "relative",
                float: "left",
                display: "inline",
                textAlign: "center",
                width: isPortrait ? "calc(100% - 48px)" : "calc(100% - 70px)",
                marginBottom: "3px",
                background: this.props.colors.primary
            },
            moveUp = {
                float: "left",
                width: "25px",
                height: "24px",
                background: this.props.colors.secondary,
                color: this.props.colors.secondaryText
            },
            textBlockStyle = {
                float: "left",
                display: "inline-block",
                width: "calc(100% - 60px)",
                verticalAlign: "middle",
                textAlign: "center",
                padding: "5px",
                background: this.props.colors.primary
            },
            moveDown = {
                float: "right",
                height: "24px",
                width: "25px",
                background: this.props.colors.secondary,
                color: this.props.colors.secondaryText,
                margin: "0",
                paddingTop: "28px"
            },
            yearStyle = {
                position: "absolute",
                right: "5%"
            };

        return (
            <div style={divWrapper}>
                <div style={labelBlock}>{this.props.sequence + 1}</div>
                <div style={contentBlock}>
                    <Icon
                        name="chevron up"
                        style={moveUp}
                        onClick={
                            /*istanbul ignore next - Initialization function only */
                            () => this.props.upClick(this.props.sequence)
                        }
                    />

                    {this.props.text && (
                        <Text
                            keyName={"speech-bubble-" + this.props.sequence}
                            text={this.props.text}
                            style={textBlockStyle}
                            animation="fadeIn,100"
                        />
                    )}
                    <div style={yearStyle}>
                        {this.props.year && (
                            <Text
                                keyName={"speech-bubble-" + this.props.sequence}
                                text={this.props.year}
                                style={textBlockStyle}
                                fontSize="normal"
                                animation="fadeIn,100"
                            />
                        )}
                    </div>
                    <Icon
                        name="chevron down"
                        style={moveDown}
                        onClick={
                            /*istanbul ignore next - Initialization function only */
                            () => this.props.downClick(this.props.sequence)
                        }
                    />
                </div>
            </div>
        );
    }
}
