import { getAppConfig } from "config/client.config";
import _ from "lodash";
import {
    FETCH_NOTIFICATIONS,
    FETCH_NOTIFICATION_COUNT,
    FETCH_UNREAD_NOTIFICATION_COUNT
} from "appRedux/helpers/reduxConstants";

const _fetchNotifications = (status, data, err) => {
    return {
        type: FETCH_NOTIFICATIONS,
        data,
        err,
        status
    };
};

const _fetchNotificationCount = (status, data, err) => {
    return {
        type: FETCH_NOTIFICATION_COUNT,
        data,
        err,
        status
    };
};

const _unreadNotifications = (status, data, err) => {
    return {
        type: FETCH_UNREAD_NOTIFICATION_COUNT,
        data,
        err,
        status
    };
};

const fetchNotifications = (learnerId, count) => {
    return (dispatch, getState, Request) => {
        let fetchNotificationPath = getAppConfig().endpoints
            .learnerManagerPaths["fetchLearnerNotifications"].replace(":count", count ? count : "all");
        fetchNotificationPath = _.replace(fetchNotificationPath, ":id", learnerId);
        return Request.fetch(fetchNotificationPath)
            .then(response => {
                return response.json().then(data => {
                    return dispatch(
                        _fetchNotifications("success", data, null)
                    );
                });
            })
            .catch(err => {
                dispatch(_fetchNotifications("error", err, null));
                return Promise.reject(err);
            });
    };
};

const fetchNotificationCount = () => {
    return (dispatch, getState, Request) => {
        const notification = getState().notification;
        const minTimeGap =
            Date.now() -
            getAppConfig().notifications.notificatioCountFetchTimeGap *
            60 *
            1000;
        dispatch(_fetchNotificationCount("fetching", null, null));
        if (notification.lastNotificationCountFetched < minTimeGap) {
            // Make API Call
            return Request.fetch("/api/v1/notifications/unread_count")
                .then(response => {
                    return response.json().then(data => {
                        return dispatch(
                            _fetchNotifications("success", data.count, null)
                        );
                    });
                })
                .catch(err => {
                    dispatch(_fetchNotifications("error", err, null));
                    return Promise.resolve(err);
                });
        } else {
            return Promise.resolve(
                dispatch(
                    _fetchNotificationCount(
                        "success",
                        notification.unreadNotificationCount,
                        null
                    )
                )
            );
        }
    };
};

const fetchUnreadNotification = (learnerId) => {
    return (dispatch, getState, Request) => {
        let fetchUnreadNotifications = getAppConfig().endpoints
            .learnerManagerPaths["fetchUnreadNotifications"].replace(":id", learnerId);
        // Make API Call
        return Request.fetch(fetchUnreadNotifications)
            .then(response => {
                return response.json().then(data => {
                    return dispatch(
                        dispatch(
                            _unreadNotifications(
                                "success",
                                data.count,
                                null
                            )
                        )
                    );
                });
            })
            .catch(err => {
                dispatch(_fetchNotifications("error", err, null));
                return Promise.resolve(err);
            });
    };
};

const markedReadNotifications = (learnerId) => {
    return (dispatch, getState, Request) => {
        let markReadNotifications = getAppConfig().endpoints
            .learnerManagerPaths["markReadNotifications"].replace(":id", learnerId);
        return Request.fetch(markReadNotifications, {
            method: "POST",
        }).then(() => {
            let fetchUnreadNotifications = getAppConfig().endpoints
                .learnerManagerPaths["fetchUnreadNotifications"].replace(":id", learnerId);
            // Make API Call
            return Request.fetch(fetchUnreadNotifications)
                .then(response => {
                    return response.json().then(data => {
                        return dispatch(
                            dispatch(
                                _unreadNotifications(
                                    "success",
                                    data.count,
                                    null
                                )
                            )
                        );
                    });
                })
                .catch(err => {
                    dispatch(_fetchNotifications("error", err, null));
                    return Promise.resolve(err);
                });
        })
            .catch(err => {
                return dispatch(
                    _fetchNotifications("error", err, null)
                );
            });
    };
};

class NotificationAction {
    static fetchNotifications = fetchNotifications;
    static fetchNotificationCount = fetchNotificationCount;
    static markedReadNotifications = markedReadNotifications;
    static fetchUnreadNotification = fetchUnreadNotification;
}

export default NotificationAction;
