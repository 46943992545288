import styled from "styled-components";
import * as RawLibrary from "semantic-ui-react";
import { getAppConfig } from "config/client.config";

// #region Wrapper
export const Wrapper = styled.div`
    &&& {
        width: 100%;
        min-height: ${(props) => (props.fullHeight ? "100vh" : "auto")};
        padding: ${(props) => (props.padded ? "10%" : undefined)};
        overflow: ${(props) => (props.noOverflow ? "hidden" : "auto")};
        text-align: ${(props) =>
            props.centered ? "center" : props.rightAligned ? "right" : "left"};
        background-image: ${(props) =>
            props.backgroundImage
                ? "url(" + props.backgroundImage + ")"
                : "none"};
        background-size: ${(props) => (props.cover ? "cover" : "contain")};
        background-repeat: no-repeat;
        background: ${(props) =>
            props.backgroundColor ? props.backgroundColor : undefined};
    }
`;
// #endregion

// #region Section
export const Section = styled.div`
    &&& {
        width: 100%;
        height: ${(props) => (props.fullHeight ? "100%" : "auto")};
        padding: ${(props) => (props.padded ? "10%" : "auto")};
        border: none;
        text-align: ${(props) =>
            props.centered ? "center" : props.rightAligned ? "right" : "left"};
    }
`;
// #endregion

// #region Background
export const Background = styled.div`
    &&& {
        position: ${(props) => (props.fullPage ? "fixed" : "absolute")};
        top: ${(props) => (props.fullPage ? "50px" : "0")};
        left: 0;
        width: ${(props) => (props.fullPage ? "100vw" : "100%")};
        height: ${(props) => (props.fullPage ? "100vh" : "100%")};
        padding: 0;
        overflow: hidden;
        background-image: ${(props) =>
            props.url ? "url(" + props.url + ")" : "none"};
        background-size: ${(props) => (props.cover ? "cover" : "contain")};
        background-repeat: no-repeat;
        background: ${(props) =>
            props.backgroundColor ? props.backgroundColor : undefined};
    }
`;
// #endregion

// #region H1
export const H1 = styled.h1`
    &&& {
        vertical-align: middle;
        width: ${(props) => (props.inline ? "auto" : "100%")};
        text-align: ${(props) =>
            props.centered ? "center" : props.rightAligned ? "right" : "left"};
        color: ${(props) => (props.color ? props.color : "#000000")};
        padding: ${(props) => (props.padded ? "5%" : "0")};
        margin-top: ${(props) =>
            props.alignTop || props.inline ? "0" : "1em"};
        margin-bottom: ${(props) =>
            props.alignBottom || props.inline ? "0" : "1em"};
        display: ${(props) => (props.inline ? "inline-block" : "block")};
    }
`;
// #endregion

// #region H3
export const H3 = styled.h3`
    &&& {
        width: 100%;
        text-align: ${(props) =>
            props.centered ? "center" : props.rightAligned ? "right" : "left"};
        color: ${(props) => (props.color ? props.color : "#000000")};
        padding: ${(props) => (props.padded ? "5%" : "0")};
        margin-top: ${(props) => (props.alignTop ? "0" : "1em")};
        margin-bottom: ${(props) => (props.alignBottom ? "0" : "1em")};
    }
`;
// #endregion

// #region Para
export const Para = styled.p`
    &&& {
        width: 100%;
        text-align: ${(props) =>
            props.centered ? "center" : props.rightAligned ? "right" : "left"};
        color: ${(props) => (props.color ? props.color : "#000000")};
        padding: ${(props) => (props.padded ? "5%" : "0")};
    }
`;
// #endregion

// #region Button
export const Button = styled(RawLibrary.Button)`
    &&& {
        position: relative;
        padding: ${(props) =>
            props.grouped ? "8px 15px" : "12px 24px"}!important;
        display: ${(props) => (props.grouped ? "inline-block" : "block")};
        overflow: hidden;
        border-style: solid !important;
        border-width: ${(props) =>
            (getAppConfig().ui.invertedButtons && !props.noeffect) ||
            props.invertedbuttons
                ? "2px"
                : 0}!important;
        border-color: ${(props) =>
            !(getAppConfig().ui.invertedButtons && !props.noeffect) &&
            !props.invertedbuttons
                ? "none"
                : props.background
                ? props.background
                : props.secondary
                ? getAppConfig().colors.accents.secondary
                : getAppConfig().colors.accents.primary}!important;
        outline: none;
        border-radius: ${(props) =>
            (getAppConfig().ui.roundedButtons && !props.noeffect) ||
            props.roundedbuttons
                ? "5px"
                : 0};
        box-shadow: ${(props) =>
            (getAppConfig().ui.invertedButtons && !props.noeffect) ||
            props.invertedbuttons
                ? "none"
                : "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2)"}important!;
        background-color: ${(props) =>
            (getAppConfig().ui.invertedButtons && !props.noeffect) ||
            props.invertedbuttons
                ? "transparent"
                : props.background
                ? props.background
                : props.secondary
                ? getAppConfig().colors.accents.secondary
                : props.negative
                ? "#cccccc"
                : getAppConfig().colors.accents.primary}!important;
        color: ${(props) =>
            (getAppConfig().ui.invertedButtons && !props.noeffect) ||
            props.invertedbuttons
                ? getAppConfig().colors.accents.primary
                : props.textcolor
                ? props.textcolor
                : props.secondary
                ? getAppConfig().colors.accents.secondaryText
                : props.negative
                ? "#666666"
                : getAppConfig().colors.accents.primaryText}!important;
        transition: background-color 0.3s;
        &:hover,
        &:focus {
            background-color: ${(props) =>
                props.background
                    ? modColor(props.background, -30)
                    : props.secondary
                    ? modColor(getAppConfig().colors.accents.secondary, -30)
                    : modColor(getAppConfig().colors.accents.primary, -30)};
            color: ${(props) =>
                props.textcolor
                    ? modColor(props.textcolor, 100)
                    : props.secondaryText
                    ? modColor(getAppConfig().colors.accents.secondaryText, 100)
                    : modColor(getAppConfig().colors.accents.primaryText, 100)};
            box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
                0 1px 10px 0 rgba(0, 0, 0, 0.12),
                0 2px 4px -1px rgba(0, 0, 0, 0.2);
        }
        & > * {
            position: relative;
        }
        &:before {
            content: "";

            position: absolute;
            top: 50%;
            left: 50%;

            display: block;
            width: 0;
            padding-top: 0;

            border-radius: 100%;

            background-color: rgba(236, 240, 241, 0.3);

            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }
        &:active:before {
            width: 120%;
            padding-top: 120%;

            transition: width 0.2s ease-out, padding-top 0.2s ease-out;
        }
    }
`;
// #endregion

// #region Input
export const Input = styled(RawLibrary.Input)`
    &&& {
        margin: ${(props) => (props.compact ? "0" : "40px 0")};
        width: 100%;
        display: block;
        border: none;
        padding: 10px 0;
        border-bottom: solid 1px
            ${(props) =>
                props.color
                    ? props.color
                    : props.inverted
                    ? "#ffffff"
                    : "#000000"};
        font-size: 1.25em;
        color: ${(props) =>
            props.color ? props.color : props.inverted ? "#ffffff" : "#000000"};

        &:focus-within,
        &:valid {
            box-shadow: none;
            outline: none;
            background-position: 0 0;
        }

        & .label {
            display: none;
        }

        &:focus-within .label {
            display: block;
            background: transparent;
            margin-left: -12px;
            margin-top: -10px;
            font-size: 0.8em;
            color: ${(props) => (props.inverted ? "#ffffff" : "#000000")};
        }
    }
`;
// #endregion

// #region Image
export const Image = styled(RawLibrary.Image)`
    &&& {
        width: ${(props) => (props.inline ? "auto" : "100%")};
        height: ${(props) => (props.fullheight ? "100%" : "auto")};
        border: none;
        vertical-align: middle;
    }
`;
// #endregion

// Used to lighten or darken a color
const modColor = (color, percent) => {
    var num = parseInt(color.replace("#", ""), 16),
        amt = Math.round(2.55 * percent),
        R = (num >> 16) + amt,
        B = ((num >> 8) & 0x00ff) + amt,
        G = (num & 0x0000ff) + amt;

    return (
        "#" +
        (
            0x1000000 +
            (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
            (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
            (G < 255 ? (G < 1 ? 0 : G) : 255)
        )
            .toString(16)
            .slice(1)
    );
};
