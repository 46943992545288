import React from "react";
import PropTypes from "prop-types";
import { Segment, Modal, Item, Icon, Header, Label } from "semantic-ui-react";
import _ from "lodash";
import NotificationItem from "./../components/NotificationItem.react";
import FlexBox from "globals/flexbox/FlexBox.react";

class NotificationWrapper extends React.Component {
    static propTypes = {
        notifications: PropTypes.array.isRequired,
        loading: PropTypes.bool.isRequired,
    };

    static defaultPropTypes = {
        notifications: [],
    };

    render() {
        const isPortrait = window.innerHeight > window.innerWidth;

        let _this = this;

        let notificationStyle = {
            page: {
                width: isPortrait ? "100%" : "360px",
                height: isPortrait ? "100vh" : "auto",
                background:
                    this.props.course && this.props.course.colors
                        ? this.props.course.colors.base
                        : "#666666",
                paddingTop: isPortrait ? "65px" : "15px",
            },
            headerStyle: {
                margin: "0 15px",
                background: "rgba(0,0,0,0.5)",
                color: "#ffffff",
                padding: "10px 15px",
                borderRadius: "10px",
            },
            count: {
                float: "right",
            },

            segmentStyle: {
                maxHeight: isPortrait ? "100vh" : "500px",
                overflow: "auto",
                margin: "0 5px",
            },
        };

        const centerDivForFlex = {
            height: "calc(100vh - 120px - 1.5em)",
            width: isPortrait ? "auto" : "100%",
        };

        let mainContainerStyle = {
            width: isPortrait ? "80%" : "100%",
            padding: isPortrait ? "0% 0% 0% 20%" : "",
        };
        let unreadCount = _.filter(this.props.notifications, { read: false })
            .length;
        return (
            <div style={notificationStyle.page}>
                <h3 style={notificationStyle.headerStyle}>
                    Notifications
                    <Label style={notificationStyle.count}>{unreadCount}</Label>
                </h3>
                <Segment
                    basic
                    style={notificationStyle.segmentStyle}
                    loading={this.props.loading}
                >
                    {!_.isEmpty(this.props.notifications) &&
                        _.map(
                            _.orderBy(
                                this.props.notifications,
                                "createdAt",
                                "desc"
                            ),
                            function(item, index) {
                                return (
                                    <NotificationItem
                                        key={"notification" + index}
                                        item={item}
                                        course={_this.props.course}
                                    />
                                );
                            }
                        )}
                    {_.isEmpty(this.props.notifications) && (
                        <div style={centerDivForFlex}>
                            <FlexBox>
                                <Header as="h2" icon textAlign="center">
                                    <Icon name="inbox" circular />
                                    <Header.Content>
                                        You have no notifications right now.
                                        Check after sometime.
                                    </Header.Content>
                                </Header>
                            </FlexBox>
                        </div>
                    )}
                </Segment>

                <div style={mainContainerStyle}>
                    <Modal
                        closeIcon
                        open={this.props.openModal}
                        onClose={this.props.toggleNotification}
                    >
                        <Modal.Content image scrolling>
                            <Item.Group divided unstackable={true}>
                                {!_.isEmpty(this.props.notifications) &&
                                    _.map(
                                        _.orderBy(
                                            this.props.notifications,
                                            "createdAt",
                                            "desc"
                                        ),
                                        function(item, index) {
                                            return (
                                                <NotificationItem
                                                    key={"noification" + index}
                                                    item={item}
                                                    openModal={
                                                        _this.props.openModal
                                                    }
                                                    toggleNotification={
                                                        _this.props
                                                            .toggleNotification
                                                    }
                                                />
                                            );
                                        }
                                    )}
                            </Item.Group>

                            {_.isEmpty(this.props.notifications) && (
                                <div style={centerDivForFlex}>
                                    <FlexBox>
                                        <Header as="h2" icon textAlign="center">
                                            <Icon name="inbox" circular />
                                            <Header.Content>
                                                You have no notifications right
                                                now. Check after sometime.
                                            </Header.Content>
                                        </Header>
                                    </FlexBox>
                                </div>
                            )}
                        </Modal.Content>
                    </Modal>
                </div>
            </div>
        );
    }
}

export default NotificationWrapper;
