import React from "react";
import PropTypes from "prop-types";
import Text from "../../contentBlocks/Text.react";
import SlideHeader from "../../contentBlocks/SlideHeader.react";
import FlexBox from "mobile/appComponents/flexbox/FlexBox.react";
import { ComponentInputs } from "schema/FormSchema.react";
export default class Splash extends React.Component {
    static propTypes = {
        colors: PropTypes.shape({
            base: PropTypes.string.isRequired,
            primary: PropTypes.string.isRequired,
            primaryVariation: PropTypes.string.isRequired,
            secondary: PropTypes.string.isRequired,
            baseText: PropTypes.string.isRequired,
            primaryText: PropTypes.string.isRequired,
            secondaryText: PropTypes.string.isRequired,
        }).isRequired,
        data: PropTypes.shape({
            splash: PropTypes.string,
        }).isRequired,
        slideId: PropTypes.number,
        mobileView: PropTypes.bool,
    };

    static defaultProps = {
        mobileView: false,
    };

    render() {
        let isPortrait =
            this.props.mobileView || window.innerHeight > window.innerWidth;

        let defaultStyle = {
            margin: "auto",
            width: isPortrait ? "85%" : "75%",
            textAlign: "center",
        };
        return (
            <div style={{ height: isPortrait ? "100%" : "calc(100% - 65px)" }}>
                <SlideHeader {...this.props} title={this.props.data.title} />
                <FlexBox>
                    <div style={defaultStyle}>
                        <Text
                            keyName={"splash-" + this.props.slideId}
                            text={this.props.data.splash}
                            color={this.props.colors.baseText}
                            animation="transition.slideDownIn,1000,500"
                            fontSize={
                                isPortrait
                                    ? this.props.data.splash.length >
                                      ComponentInputs.splash.idealLength
                                        ? "normal"
                                        : "large"
                                    : "large"
                            }
                        />
                    </div>
                </FlexBox>
            </div>
        );
    }
}
