import React, { Component } from "react";
import { Segment } from "semantic-ui-react";
import {Button} from "config/initializers/styles.app";
import PropTypes from "prop-types";

export default class FullScreenComponent extends Component {
    static propTypes = {
        type: PropTypes.string
    };
    render() {
        let containerStyle = {
            width: "100%",
            height: "100%",
            position: "fixed",
            zIndex: "9999",
            top: "0",
            left: "0",
            backgroundColor: "#000",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column"
        };
        let segmentStyle = {
            padding: "0",
            height: "100%",
            width: "100%"

        };
        let mainContainerStyle = {
            height: "100%",
            width: "100%",
            backgroundColor: "#000",
            transform: this.props.type === "video" ? "scale(1.5)" : "scale(1.2)"
        };
        let btnWrapperStyle = {
            position: "fixed",
            left: "10px",
            top: "10px",
            zIndex: "1",
            fontSize: "1.5em"
        };
        let rotatedDiv = {
            width: "100%",
            height: this.props.type === "video" ? "100%" : "auto",
            transform: "rotate(-90deg)",
            margin: this.props.type === "video" ? "60% auto" : "50% auto"
        };
        return (
            <div style={containerStyle}>
                <div style={btnWrapperStyle}>
                    <Button
                        icon="close"
                        onClick={() => this.props.toggleFullscreen(false)}
                    />
                </div>
                <div style={rotatedDiv}>
                    <Segment style={segmentStyle} color="black" inverted>
                        <div style={mainContainerStyle}>
                            {this.props.children}
                        </div>
                    </Segment>
                </div>
            </div>
        );
    }
}
