/* istanbul ignore file */

// Import from NPM
// -------------------------------------
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import WebFont from "webfontloader";

// Import App Configuration and Setup
// -------------------------------------
import { getAppConfig } from "config/client.config";
import { Store } from "backend/storage/mobile/mobile.store";
import registerServiceWorker from "./registerServiceWorker";
import "./index.css";
import "./config/themedLXP.css";

// Import App
// -------------------------------------
import App from "./app";

WebFont.load(getAppConfig().fonts);

ReactDOM.render(
    <Provider store={Store} key="provider">
        <App />
    </Provider>,
    document.getElementById("root") || document.createElement("div")
);
registerServiceWorker();
