import React, { Component } from "react";
import { Image } from "config/initializers/styles.app";

export class MenuAd extends Component {
    render() {
        return (
            <div
                style={{
                    boxShadow: "0 1px 2px 0 rgba(34,36,38,.15)",
                    borderRadius: "5px",
                    margin: "10px 0"
                }}
            >
                <a href={this.props.content.link}>
                    <Image
                        fluid
                        src={this.props.content.src}
                        style={{ borderRadius: "5px" }}
                    />
                </a>
            </div>
        );
    }
}
